import { environment } from './../../../../environments/environment';
import { Component, Input, Output, EventEmitter} from '@angular/core';
import { AnalyticsService } from '../../../common/services/analytics.service';

declare var $: any;

/**
 * This class represents the information modal component
 */
@Component({
    selector: 'consider-tsa-modal',
    templateUrl: 'consider-tsa-modal.component.html',
    standalone: false
})


export class ConsiderTsaModalComponent {

  @Input()
  id: string;

  @Input()
  siteLeavingId: string;

  @Output() continue = new EventEmitter();

  public TSA_LOGIN_URL = environment.tsaLoginURL;

  constructor(private analyticsService: AnalyticsService) {}

  continueWithUP() {
    this.continue.emit();
  }

  public logGetStartedProgram(programName: string) {
    if (programName) {
      this.analyticsService.logGetStarted(programName).subscribe();
    }
  }

  // component should probably be included in the app level, but for now we will just
  // include it in the individual template and pass in the id.
  public showSiteLeavingToTSAModal() {
    if (this.siteLeavingId) {
      $('#' + this.siteLeavingId).modal('show');
    } else {
      $('#mdl-tsa-site-leaving').modal('show');
    }
  }
}
