<div id="{{id}}" class="modal modal-lg fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" [innerHTML]="title | translate"></h4>
        <button type="button" class="ttp-btn-close" (click)="reset()" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div *ngIf="option === 0">
            <p *ngIf="optionOneText">
                <span [innerHTML]="optionOneText | translate"></span>
                <span><a [innerHTML]="optionOneLink | translate" (click)="setOption(1)" tabindex="0" (keyup.enter)="setOption(1)" href="javascript:void(0)"></a></span>
            </p>
            <p *ngIf="optionTwoText">
                <span [innerHTML]="optionTwoText | translate"></span>
                <span><a [innerHTML]="optionTwoLink | translate" (click)="setOption(2)" href="javascript:void(0)" tabindex="0" (keyup.enter)="setOption(2)"></a></span>
            </p>
            <p *ngIf="optionThreeText">
                <span [innerHTML]="optionThreeText | translate"></span>
                <span><a [innerHTML]="optionThreeLink | translate" (click)="setOption(3)" href="javascript:void(0)" tabindex="0" (keyup.enter)="setOption(3)"></a></span>
            </p>
        </div> 
        <div *ngIf="option === 1">
            <span [innerHTML]="optionOneSource | translate"></span>
        </div>
        <div *ngIf="option === 2">
            <span [innerHTML]="optionTwoSource | translate"></span>
        </div>
        <div *ngIf="option === 3">
            <span [innerHTML]="optionThreeSource | translate"></span>
        </div>
      </div>
      <div class="modal-footer justify-content-evenly">
        <div class="col-12 col-lg-4 me-auto" *ngIf="option !== 0">
          <button type="button" class="btn d-block w-100 btn-default" (click)="reset()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i> {{'GENERAL_REUSABLE.BACK_BUTTON' | translate}}
          </button>
        </div>
  	  	<div class="col-12 col-lg-4">
        	<button type="button" class="btn d-block w-100 btn-primary" (click)="reset()" data-bs-dismiss="modal">{{buttonText | translate}}</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->