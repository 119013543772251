import { Document } from '../models/document.model';
import { Application } from '../models/application.model';
import { ApplicationData } from '../utils/application-data';
import { Phone } from '../models/phone';
import { Address } from '../models/address';
import { Country } from '../models/country.model';
import { DriversLicense } from '../models/drivers-license.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { GoesDate } from '../models/date.model';
import { TranslateReferenceService } from '../services/translate-reference.service';

export default class ApplicationMapper {

//    refData : ReferenceDataModel;
//    //private x: ReferenceDataModel;
//    constructor(
//    	private referenceService : TranslateReferenceService){}
//
//    ngOnInit(){
//        this.referenceService.pullData().subscribe( res => {
//            this.refData = res;
//        });
//    }

    /* ---------------------------------------------*/
    /* -- mappings from ui models to backend dtos -- */
    /* ---------------------------------------------*/

    static serialize(application: Application, errorHandler: ErrorHandlerService, referenceService: TranslateReferenceService): any{
        const data: ApplicationData = new ApplicationData();
        try {
            data.json.id = application.id;
            data.json.programCodes = new Array<string>();
            if (application.programCode) {
              data.json.programCodes.push(application.programCode);
            }
            if (application.addAbtc) {
              data.json.programCodes.push('AB');
            }
            if (application.originalMembership) {
                data.json.membershipProgramCode = application.originalMembership;
            }
            data.json.primaryCitizenshipCountryIsoCode = application.primaryCitizenshipCountryIsoCode;
            data.json.primaryCitizenshipLPRCountryIsoCode	= application.primaryCitizenshipLPRCountryIsoCode;
            
            this.serializePerson(application, data);
            data.json.driverLicenseResponse = application.driversLicense.haveLicense;
            this.serializeLicense(application, data);
            this.serializeQuestions(application, data);
            this.serializeVehicles(application, data);
            data.json.isMailingAddressResponse = application.isMailingAddressResponse;
            this.serializeAddressInfo(application, data);
            this.serializeEmploymentInfo(application, data);
            data.json.visitedCountriesResponse = application.travelHistory.haveYouTraveled;
            this.serializeTravelHistory(application, data);
            this.serializeDocuments(application, data);
            this.serializeUsContact(application, data);
            this.serializeRequestedInfo(application, data, referenceService);

            // These should be read only
            data.json.requestType = application.requestType;
            data.json.requestStatus = application.requestStatus;
            data.json.isModified = application.isModified;
            // console.log("application PRE SAVE: ", data.json);

            if (application.cardReplacementCode) {
                data.json.cardReplacementCode = application.cardReplacementCode;
            }
            data.json.tsaPreData = application.tsaPreData;

            return JSON.stringify(data.json);
        } catch (err) {
            errorHandler.handleError(err);
        }
    }

    private static serializeQuestions(application: Application, data: ApplicationData){
        const quesList = application.additionalInfo.questionList;
        const questions: any = [];
        for (const ques of quesList){
            const item = {
                code: ques.code || '',
                country: ques.countryCode || '',
                countryFlag: 0, // TODO: what is this?
                details: ques.details || '',
                response: ques.response || ''
            };
            questions.push(item);
        }
        data.json.additionalQuestions = questions;
    }

    private static serializePerson(application: Application, data: ApplicationData){
        const person = application.person;
        const firstAliases = person.aliasFirstNames.filter(name => name !== '');
        const lastAliases = person.aliasLastNames.filter(name => name !== '');
        data.json.personalInfo = {
            cityOfBirth: person.birthCity || '',
            countryOfBirth: person.birthCountry || '',
            dateOfBirth: person.dob.getSimpleString() || '',
            emailAddress: person.email || '',
            eyeColor: person.eyeColor || '',
            firstName: person.firstName || '',
            firstNameAliases: firstAliases,
            gender: person.gender || '',
            heightInInches: person.heightInches || 0,
            lastName: person.lastName || '',
            lastNameAliases: lastAliases,
            legalGuardian: {
                dateOfBirth: person.lgDateOfBirth.getSimpleString() || '',
                firstName: person.lgFirstName || '',
                gender: person.lgGender || '',
                lastName: person.lgLastName || '',
                membershipResponse: person.lgMembershipResponse || '',
                middleName: person.lgMiddleName || '',
                travellerProgramId: person.lgTravellerProgramId || '',
                passId: person.lgPassId || '',
                applicationId: person.lgApplicationId || '',
                idType: person.lgIdType || ''
            },
            middleName: person.middleName || '',
            notificationLanguagePreference: person.langPref || '',
            otherNameResponse: application.person.hasAliases,
            phoneNumbers: person.phoneNumbers,
            stateOfBirth: person.birthStateCode || '',
            suffix: ''
        };
    }

    private static serializeLicense(application: Application, data: ApplicationData){

        // serialize to empty license if user selected no license
        const temp = application.driversLicense.haveLicense;
        if (application.driversLicense.haveLicense !== 'Y'){
            application.driversLicense = new DriversLicense();
            application.driversLicense.haveLicense = temp;
        } else {
            // serialize EDL, CDL info appropriately based on country
            if (application.driversLicense.countryOfIssuance){
                switch (application.driversLicense.countryOfIssuance.isoCountryCode){
                    case 'CA' : application.driversLicense.isCDL = '';
                                application.driversLicense.isHazmat = '';
                                break;
                    case 'MX' : application.driversLicense.isEDL = '';
                                break;
                }
            }
        }

        const lic = application.driversLicense;
        data.json.hasLicense = lic.haveLicense === 'Y' ? true : false;
        data.json.license = {
            aNumber: '',
            admissibilityProofInd: false,
            cdlInd: lic.isCDL,
            citizenshipProofInd: false,
            countryOfIssuanceIsoCode: lic.countryOfIssuance.isoCountryCode || '',
            docId: 0,
            dateOfBirth: lic.dateOfBirth.getSimpleString() || '',
            dateOfIssuance: '',
            documentNumber: lic.licenseNumber || '',
            edlInd: lic.isEDL || '',
            expiration: lic.expirationDate.getSimpleString() || '',
            freeTextState: lic.stateOfIssuance ? '' : lic.freeTextState,
            givenName: lic.firstName || '',
            hazmatInd: lic.isHazmat || '',
            issuingAuthority: '',
            licenseNumber: lic.licenseNumber || '',
            lprProofInd: false,
            middleName: lic.middleName || '',
            registryGroup: '',
            stateOfIssuance: lic.stateOfIssuance || '',
            suffix: lic.suffix || '',
            surName: lic.lastName || '',
            type: 'DL',
            visaClass: ''
        };
    }

    private static serializeVehicles(application: Application, data: ApplicationData){
        const vehicles: any = [];
        const tempVehicleQuestions = [
            {
                code : 'VDR',
                response : application.driveBorder
            },
            {
                code : 'VRG',
                response : application.vehicleRegisterNow
            },
            {
                code : 'VAC',
                response : application.vehicleAlreadyRegistered
            }
        ];
        data.json.vehicleQuestions = tempVehicleQuestions;
        for (const vehicle of application.vehicles){
            const bAddress = vehicle.businessAddress ? vehicle.businessAddress : new Address();
            let ownerCode = '';
            let ownerTypeCode = '';

            // owner information should serialize to empty if owner is the applicant
            if (vehicle.owner === 'A'){
                vehicle.ownerPhoneCountryCode = '';
                vehicle.ownerDateOfBirth = new GoesDate();
                vehicle.ownerPhoneNumber = '';
                vehicle.ownerPhoneFormat = '';
                vehicle.ownerPhoneExt = '';
                vehicle.ownerMiddleName = '';
                vehicle.ownerLastName = '';
                vehicle.ownerFirstName = '';
                vehicle.ownerGender = '';
            }
            // business info should serialize to empty if owner is applicant or another individual
            if (vehicle.owner === 'I' || vehicle.owner === 'A'){
                vehicle.businessAddress = new Address();
                vehicle.businessName = '';
            }

            if (vehicle.owner) {
                ownerCode = (vehicle.owner === 'A') ? 'Y' : 'N';
            }
            if (ownerCode && ownerCode !== 'A') {
                if (vehicle.owner === 'I') {
                  ownerTypeCode = 'I';
                }
                else if (vehicle.owner === 'B') {
                  ownerTypeCode = 'C';
                }
            }
            if (bAddress.country.isoCountryCode !== 'MX') { bAddress.mxNeighborhood = ''; }
            const item = {
                color: vehicle.color || '',
                isGovernmentIssuedPlate: (vehicle.govtLicensePlate === 'Y') ? true : false,
                licenseCountryOfIssuance: vehicle.licenseCountryOfIssuance || '',
                licensePlateNumber: vehicle.licensePlateNumber || '',
                make: vehicle.make || '',
                model: vehicle.model || '',
                owner: {
                    apartmentOrSuiteNumber: bAddress.addressLine2 || '',
                    city: bAddress.city || '',
                    companyName: vehicle.businessName || '',
                    country: bAddress.country.isoCountryCode || '',
                    dateOfBirth: vehicle.ownerDateOfBirth.getSimpleString() || '',
                    firstName: vehicle.ownerFirstName || '',
                    gender: vehicle.ownerGender || '',
                    lastName: vehicle.ownerLastName || '',
                    middleName: vehicle.ownerMiddleName || '',
                    phoneCountryCode: vehicle.ownerPhoneFormat === 'N' ? '1' : (vehicle.ownerPhoneCountryCode || ''),
                    phoneExtension: vehicle.ownerPhoneExt || '',
                    phoneFormat: vehicle.ownerPhoneFormat || '',
                    phoneNumber: vehicle.ownerPhoneNumber || '',
                    postalCode: bAddress.postalCode || '',
                    state: bAddress.state || '',
                    street1Address: bAddress.addressLine1 || '',
                    street2Address: bAddress.addressLine2 || '',
                    coloniaNeighborhood : bAddress.mxNeighborhood || '',
                    streetName: '',
                    streetNumber: '',
                    suffix: '',
                    ownerTypeCode
                },
                ownerCode,
                stateOfIssuance: vehicle.stateProvinceOfIssuance || '',
                vin: vehicle.vin || '',
                year: vehicle.year || 0
            };
            vehicles.push(item);
        }
        data.json.vehicles = vehicles;
    }

    private static serializeDocuments(application: Application, data: ApplicationData): any {
        const citizenshipDocs: any = [];

        for ( const citDocs of application.citizenshipDocuments){
            this.mergeDuplicateDocuments(citDocs.documents);
            const item = {
                countryIsoCode: citDocs.country.isoCountryCode,
                documents: new Array<any>()
            };
            for ( const document of citDocs.documents){

                // eliminating issuingAuthority per review feedback
                const doc = {
                    type: document.type || '',
                    citizenshipProofInd: document.citizenshipProofInd,
                    admissibilityProofInd: document.admissibilityProofInd,
                    lprProofInd: document.lprProofInd,
                    docId: document.docId || null,
                    countryOfIssuanceIsoCode: document.countryOfIssuance.isoCountryCode,
                    dateOfBirth: document.dateOfBirth.getSimpleString(),
                    dateOfIssuance: document.dateOfIssuance.getSimpleString(),
                    documentNumber: document.documentNumber,
                    expiration: document.expiration.getSimpleString(),
                    freeTextState: document.freeTextState,
                    givenName: document.givenName,
                    // 'issuingAuthority': document.issuingAuthority,
                    middleName: document.middleName,
                    stateOfIssuance: document.stateOfIssuance,
                    suffix: '',
                    surName: document.surName,
                    registryGroup: document.registryGroup || '',
                    visaClass: document.class || '',
                    aNumber: document.aNumber || '',
                    abtcInd: document.abtcInd || '',
                    verifyStatusCode : document.verified || ''
                };
                item.documents.push(doc);
            }
            citizenshipDocs.push(item);
        }
        data.json.documentsByCitizenshipCountry = citizenshipDocs;
    }

    private static serializeTravelHistory(application: Application, data: ApplicationData){
        const visitedCountries: string[] = [];
        if (application.travelHistory.countries){
            // console.log(application.travelHistory.countries);
            for (const country of application.travelHistory.countries){
                visitedCountries.push(country.isoCountryCode);
            }
        }

        data.json.visitedCountries = visitedCountries;
    }

    private static serializeEmploymentInfo(application: Application, data: ApplicationData){
        const currentEmps: any = [];
        const pastEmps: any = [];
        for (const employment of application.employments){
            let address = employment.address;
            let phone = employment.phone;
            let startDate = '';
            let endDate = '';
            if (employment.startMonth && employment.startYear) {
                startDate = employment.startYear + '-' + employment.startMonth + '-01';
            }
            if (employment.endMonth && employment.endYear){
                endDate = employment.endYear + '-' + employment.endMonth + '-01';
            }
            if (!address) { address = new Address(); }
            if (!phone) { phone = new Phone(); }
            if (!address.country) { address.country = new Country(); }
            if (address.country.isoCountryCode !== 'MX') { address.mxNeighborhood = ''; }

            // employment information should serialize to empty
            // if employment status is retired, unemployed, or student
            if (employment.status === 'R' || employment.status === 'U' || employment.status === 'S') {
                employment.address = new Address();
                employment.occupation = '';
                employment.employer = '';
                employment.phone = new Phone();
            }

            // Phone number is collected for previous employment
            if (!employment.isCurrent) {
                employment.phone = new Phone();
                phone = new Phone();
            }

            const e = {
              city: address.city || '',
              country: address.country.isoCountryCode || '',
              employer: employment.employer || '',
              employmentStatus: employment.status || '',
              endDate,
              occupation: employment.occupation || '',
              phoneCountryCode: phone.format === 'N' ? '1' : (phone.countryCode || ''),
              phoneExtension: phone.extension || '',
              phoneFormat: phone.format || '',
              phoneNumber: phone.number || '',
              postalCode: address.postalCode || '',
              startDate,
              state: address.state || '',
              street1Address: address.addressLine1 || '',
              street2Address: address.addressLine2 || '',
              coloniaNeighborhood : address.mxNeighborhood || '',
              addressValidationCode : address.validatedIndicator || ''
            };
            if (employment.isCurrent){
                currentEmps.push(e);
            } else {
                pastEmps.push(e);
            }
        }
        data.json.currentEmployements = currentEmps;
        data.json.pastEmployements = pastEmps;
    }

    private static serializeRequestedInfo(application: Application, data: ApplicationData, referenceService: TranslateReferenceService){

        const attributes: any = [];
        let promoCode = '';
        let countryCode = '';
        let programCode = '';

        if (application.primaryCitizenshipLPRCountryIsoCode !== 'US'){
            switch (application.primaryCitizenshipCountryIsoCode){
                // COLOMBIA
                case 'CO':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'COLOMBIA NATIONAL ID',
                            value : application.coRequestedInfo.nationalID
                        });
                        attributes.push({
                           name : 'MATERNAL NAME',
                            value : application.coRequestedInfo.maternalName
                        });

                        promoCode = application.coRequestedInfo.promoCode;
                       // attributes.push({
                       //    "name" : "COLOMBIA DISCLAIMER DATE",
                       //     "value" : application.coRequestedInfo.timestamp.getSimpleString()
                       // });
                    }
                    break;

                // ISRAEL
                case 'IL':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'ISRAEL IDENTITY NUMBER',
                            value : application.ilRequestedInfo.citizenshipNumber
                        });
                        promoCode = application.ilRequestedInfo.promoCode;
                    }
                    break;

                // MEXICO
                case 'MX':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'RFC',
                            value : application.mxRequestedInfo.RFC
                        });
                        attributes.push({
                           name : 'OwnRFC',
                            value : application.mxRequestedInfo.RFCOwnedByApplicant
                        });
                        attributes.push({
                           name : 'CURP',
                            value : application.mxRequestedInfo.CURP
                        });
                        attributes.push({
                           name : 'MATERNAL NAME',
                            value : application.mxRequestedInfo.maternalName
                        });
                        promoCode = application.mxRequestedInfo.promoCode;

                    }
                    else if (application.programCode === 'NH'){
                        programCode = 'NH';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'PVC MEMBERSHIP NUMBER',
                           value : application.mxRequestedInfo.PVC
                        });
                        attributes.push({
                           name : 'MATERNAL NAME',
                            value : application.mxRequestedInfo.maternalName
                        });
                        promoCode = application.mxRequestedInfo.promoCode;
                    }
                    break;

                // QATAR
                case 'QA':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'QATAR NATIONAL ID',
                            value : application.qaRequestedInfo.nationalID
                        });
                        promoCode = application.qaRequestedInfo.promoCode;

                    }
                    break;

                // SAUDI ARABIA
                case 'SA':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'SAUDI ARABIA NATIONAL ID',
                            value : application.saRequestedInfo.nationalID
                        });
                        promoCode = application.saRequestedInfo.promoCode;
                    }
                    break;

                // SINGAPORE
                case 'SG':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'SINGAPORE NATIONAL REGISTRATION ID',
                            value : application.sgRequestedInfo.NRIC.toUpperCase()
                        });
                        promoCode = application.sgRequestedInfo.promoCode;

                    }
                    break;

                // UK
                case 'GB':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                            name : 'UK GET CODE',
                            value : application.ukRequestedInfo.UKAccessCode
                        });

                    }
                    break;

                // ARGENTINA
                case 'AR':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'ARGENTINA NATIONAL ID',
                            value : application.arRequestedInfo.nationalID
                        });
                        attributes.push({
                           name : 'MOTHER FIRST NAME',
                           value : application.arRequestedInfo.maternalGivenName
                        });
                        attributes.push({
                           name : 'MOTHER LAST NAME',
                            value : application.arRequestedInfo.maternalSurname
                        });
                        attributes.push({
                           name : 'FATHER FIRST NAME',
                           value : application.arRequestedInfo.paternalGivenName
                        });
                        attributes.push({
                           name : 'FATHER LAST NAME',
                           value : application.arRequestedInfo.paternalSurname
                        });
                        promoCode = application.arRequestedInfo.promoCode;

                    }
                    break;

                // TAIWAN
                case 'TW':
                    if (application.programCode === 'UP'){
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                            name : 'TAIWAN NATIONAL ID',
                            value : application.twRequestedInfo.nationalID
                        });
                        attributes.push({
                            name : 'PoliceCertNbr',
                            value : application.twRequestedInfo.criminalRecordNumber
                        });
                        promoCode = application.twRequestedInfo.promoCode;

                    }
                    break;
                // Brazil
                case 'BR':
                    if (application.programCode === 'UP') {
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'BRAZIL CPF NUMBER',
                            value : application.brRequestedInfo.cpfNumber
                        });
                        promoCode = application.brRequestedInfo.promoCode;
                    }
                    break;

                // check if pilot
                default:
                    const country = referenceService.getCountryByIsoCode(application.primaryCitizenshipCountryIsoCode);
                    if (country.geEligibility === 'PILOT' && application.programCode === 'UP'){
                        promoCode = application.pilotRequestedInfo.promoCode;
                        countryCode = country.isoCountryCode;
                        programCode = application.programCode;
                    }
                    break;

                // Dominican Republic
                case 'DO':
                    if (application.programCode === 'UP') {
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'DOMINICAN CEDULA NUMBER',
                            value : application.doRequestedInfo.cedNumber
                        });                        
                    }
                    break;

                // United Arab Emirates
                case 'AE':
                    if (application.programCode === 'UP') {
                        programCode = 'UP';
                        countryCode = application.primaryCitizenshipCountryIsoCode;
                        attributes.push({
                           name : 'EMIRATES ID NUMBER',
                            value : application.aeRequestedInfo.idNumber
                        });                        
                    }
                    break;

            }
        }

        const tempPartner = {
          attributes,
          countryCode,
          programCode,
          promotionCode: promoCode
        };

        data.json.partnerPropertiesDto = tempPartner;
    }

    private static serializeAddressInfo(application: Application, data: ApplicationData){
        const addressHistory: any = [];
        if (application.mailing){
            data.json.mailingAddress = this.serializeSingleAddress(application.mailing);
        }
        for (const address of application.addresses){
            if (address.isCurrent){
                data.json.currentAddress = this.serializeSingleAddress(address);
            } else {
                const a = this.serializeSingleAddress(address);
                addressHistory.push(a);
            }
        }
        data.json.addressHistory = addressHistory;
    }

    private static serializeUsContact(application: Application, data: ApplicationData){
        // note that address history component clears out the US contact information
        // when it is not displayed (at the time of save)
        const contact = application.contact;
        data.json.personUsContactDto = {
           addressLine1: contact.address.addressLine1 || '',
            addressLine2: contact.address.addressLine2 || '',
            city: contact.address.city || '',
            country: contact.address.countryCode || '',
            extension: contact.phoneExt || '',
            fullName: contact.fullName || '',
            phoneNumber: contact.phoneNumber || '',
            state: contact.address.state || '',
            zipCode: contact.address.postalCode || '',
            addressValidationCode : contact.address.validatedIndicator || ''
        };
    }

    private static serializeSingleAddress(address: Address): any{
        let endDate = '';
        let startDate = '';
        if (address.startMonth && address.startYear) {
            startDate = address.startYear + '-' + address.startMonth + '-01';
        }
        if (address.endMonth && address.endYear){
            endDate = address.endYear + '-' + address.endMonth + '-01';
        }
        if (!address.country) { address.country = new Country(); }
        if (address.countryCode !== 'MX') { address.mxNeighborhood = ''; }
        return {
            city: address.city || '',
            country: address.countryCode || '',
            endDate,
            postalCode: address.postalCode || '',
            startDate,
            state: address.state || '',
            coloniaNeighborhood : address.mxNeighborhood || '',
            street1Address: address.addressLine1 || '',
            street2Address: address.addressLine2 || '',
            addressValidationCode : address.validatedIndicator || ''
        };
    }


    static mergeDuplicateDocuments(docList: Array<Document>){

        // chcek if two items have the same : number, country of issuance, and type
        for (let i = 0; i < docList.length; i++){
            const doc1 = docList[i];
            for (let j = 0; j < docList.length; j++){
                const doc2 = docList[j];
                if ((i !== j) && this.areDuplicateDocuments(doc1, doc2)){
                    // if documentKey exists, merge indicators into that document.  delete other.
                    if (doc1.documentKey){
                        this.mergeIndicators(doc1, doc2);
                        docList.splice(j, 1);
                    } else if (doc2.documentKey){
                        this.mergeIndicators(doc2, doc1);
                        docList.splice(i, 1);
                    } else {
                        // if no documentKey exists, merge indcators into most recent, (highest docId)
                        doc1.docId > doc2.docId ? this.mergeIndicators(doc1, doc2) : this.mergeIndicators(doc2, doc1);
                        doc1.docId > doc2.docId ? docList.splice(j, 1) : docList.splice(i, 1);
                    }
                }
            }
        }
    }

    // takes indicators and puts them into doc1
    private static mergeIndicators(doc1: Document, doc2: Document): void{
        doc1.admissibilityProofInd = doc1.admissibilityProofInd || doc2.admissibilityProofInd;
        doc1.citizenshipProofInd = doc1.citizenshipProofInd || doc2.citizenshipProofInd;
        doc1.lprProofInd = doc1.lprProofInd || doc2.lprProofInd;
    }


    private static areDuplicateDocuments(doc1: Document, doc2: Document): boolean{
        const result = doc1.documentNumber === doc2.documentNumber &&
            doc1.countryOfIssuance.isoCountryCode === doc2.countryOfIssuance.isoCountryCode &&
            doc1.type === doc2.type;
        return result;
    }
    /* ---------------------------------------------*/
    /* -- mappings from backend dto to ui models -- */
    /* ---------------------------------------------*/

    // static deserialize(
    // 		json: any, application: Application,
    // 		errorHandler: ErrorHandlerService,
    // 		countries: Country[]){
    // 	try {
    // 		application.programCode = json['programCodes'];
    // 	    application.programConvertInd = json['programConvertInd'];
    // 		application.feeAmount = 0;
    // 		application.id = json['id'];
    // 	    // application.diffMailing = boolean;
    // 	    // application.driveBorder: string;
    // 	    // application.vehicleAlreadyRegistered: string;
    // 	    // application.vehicleRegisterNow: string;
    // 		// this.mapPerson(json, application);
    // 		// this.mapLicense(json, application);
    // 		// this.mapVehicles(json, application);
    // 		// this.mapQuestions(json, application);
    // 		// this.mapAddressInfo(json, application);
    // 		// this.mapEmploymentInfo(json, application);
    // 		// this.mapTravelHistory(json, application, countries);
    // 	} catch(err) {
    // 		errorHandler.handleError(err);
    // 	}
    // }

    // private static mapQuestions(json: any, application: Application){
    // 	var data = json['additionalQuestions'];
    // 	if(data){
    // 		for(let q of data){
    // 			var ques: AdditionalQuestion = {
    // 				questionDescription: '',
    // 				questionTypeCode: q['countryFlag'],
    // 			    code : q['code'],
    // 			    countryCode : q['country'],
    // 			    details : q['details'],
    // 			    response : q['response']
    // 			}
    // 			application.additionalInfo.questionList.push(ques);
    // 		}
    // 	}
    // }

    // private static mapPerson(json:any , application: Application){
    // 	var person = application.person;
    // 	var data = json['personalInfo'];
    // 	if(person && data){
    // 		var phones:Phone[] = [];
    // 		for(let phone of data['phoneNumbers']){
    // 			var p:Phone = {
    // 				format: phone['format'],
    // 				type: phone['type'],
    // 				number: phone['number'],
    // 				extension: phone['extension'],
    // 				countryCode : phone['countryCode']
    // 			};
    // 			phones.push(p);
    // 		}
    // 		person = {
    // 			id: 0,
    // 		    lastName: data['lastName'],
    // 		    firstName: data['firstName'],
    // 		    middleName: data['middleName'],
    // 		    dob: data['dateOfBirth'],
    // 		    birthCity: data['cityOfBirth'],
    // 		    birthCountryCode: data[''],
    // 		    birthCountry: data['countryOfBirth'],
    // 		    birthStateCode: data['stateOfBirth'],
    // 		    birthState: data['stateOfBirth'],
    // 		    email: data['emailAddress'],
    // 		 	gender: data['gender'],
    // 		 	eyeColor: data['eyeColor'],
    // 		 	heightType: 'E',
    // 		 	heightInches: data['heightInInches'],
    // 		 	aliasFirstNames: data['firstNameAliases'],
    // 		 	aliasLastNames: data['lastNameAliases'],
    // 		 	phoneNumbers: phones
    // 	 	}
    // 	 	application.person = person;
    //  	}
    // }

    // private mapLicense(json: any, application: Application){
    // 	var lic = application.driversLicense;
    // 	var data = json['license'];
    // 	lic = {
    // 		haveLicense : json['hasLicense']? 'Y':'N',
    // 	    licenseNumber : data['licenseNumber'],
    // 	    countryOfIssuance : this.refData.getCountryByIsoCode(data['countryOfIssuanceIsoCode']),
    // 	    stateOfIssuance : data['stateOfIssuance'],
    // 	    expirationDate : new GoesDate(data['expiration']),
    // 	    lastName : data['surName'],
    // 	    suffix : data['suffix'],
    // 	    firstName : data['givenName'],
    // 	    middleName : data['middleName'],
    // 	    dateOfBirth : new GoesDate(data['dateOfBirth']),
    // 	    isCDL : data['cdlInd'],
    // 	    isEDL : data['edlInd'],
    // 	    isHazmat : data['hazmatInd'],
    //       };
    //     application.driversLicense = lic;
    // }

    // private static mapVehicles(json: any, application: Application){
    // 	var vehicles = json['vehicles'];
    // 	for(let data of vehicles){
    // 		var owner = data['owner'];
    // 		var businessAddress = this.mapSingleAddress(owner);
    // 		var vehicle: Vehicle = {
    // 			make: data['make'],
    // 		    model: data['model'],
    // 		    year: data['year'],
    // 		    color: data['color'],
    // 		    vin: data['vin'],
    // 		    licensePlateNumber: data['licensePlateNumber'],
    // 		    licenseCountryOfIssuance: data['licenseCountryOfIssuance'],
    // 		    govtLicensePlate: data['isGovernmentIssuedPlate']? data['licensePlateNumber'] : '',
    // 		    stateProvinceOfIssuance: data['stateOfIssuance'],
    // 		    owner: owner['ownerType'],
    // 		    ownerLastName: owner['lastName'],
    // 		    ownerFirstName: owner['firstName'],
    // 		    ownerMiddleName: owner['middleName'],
    // 		    ownerDateOfBirth: owner['dateOfBirth'],
    // 		    ownerPhoneFormat: owner['phoneFormat'],
    // 		    ownerPhoneNumber: owner['phoneNumber'],
    // 		    ownerPhoneExt: owner['phoneExtension'],
    // 		    businessName: owner['companyName'],
    // 		    businessAddress: businessAddress,
    // 		    ownerGender: owner['gender'],
    // 		};
    // 		application.vehicles.push(vehicle);
    // 	}
    // }

    // private static mapSingleAddress(data: any): Address{
    // 	var address: Address = new Address();
    // 	if(data['startDate']){
    // 		var startDate:Date = new Date(data['startDate']);
    // 		address.startMonth = startDate.getMonth()+1;
    // 		address.startYear = startDate.getFullYear();
    // 	}
    // 	if(data['endDate']){
    // 		var endDate:Date = new Date(data['endDate']);
    // 		address.endMonth = endDate.getMonth()+1;
    // 		address.endYear = endDate.getFullYear();
    // 	}
    // 	address.addressLine1 = data['street1Address'];
    // 	address.addressLine2 = data['apartmentOrSuiteNumber'];
    // 	address.city = data['city'];
    // 	address.state = data['state'];
    // 	address.postalCode = data['postalCode'];
    // 	address.countryCode = data['country'];

    // 	return address;
    // }

    // private static mapSingleEmployment(data: any, isCurrent?: boolean): Employment {
    // 	var employment: Employment = new Employment();
    // 	if(data['startDate']){
    // 		var startDate:Date = new Date(data['startDate']);
    // 		employment.startMonth = startDate.getMonth()+1;
    // 		employment.startYear = startDate.getFullYear();
    // 	}
    // 	if(data['endDate']){
    // 		var endDate:Date = new Date(data['endDate']);
    // 		employment.endMonth = endDate.getMonth()+1;
    // 		employment.endYear = endDate.getFullYear();
    // 	}
    // 	employment.address = this.mapSingleAddress(data);
    // 	employment.employer = data['employer'];
    // 	employment.isCurrent = isCurrent;
    // 	employment.occupation = data['occupation'];
    // 	var p:Phone = {
    // 		format: data['phoneFormat'],
    // 		type: '',
    // 		number: data['phoneNumber'],
    // 		extension: data['phoneExtension'],
    // 		countryCode : data['phoneCountryCode']
    // 	};
    // 	employment.phone = p;
    // 	employment.status = data['employmentStatus'];
    // 	return employment;
    // }

    // private static mapTravelHistory(json: any, application: Application, countries: Country[]){
    // 	var visitedCountries:string[] = json['visitedCountries'];
    // 	if(visitedCountries && visitedCountries.length > 0){
    // 		//console.log('MAPPING CTRY', visitedCountries, countries);
    // 		application.travelHistory.haveYouTraveled = true;
    // 		application.travelHistory.countries = [];
    // 		for(let countryCode of visitedCountries){
    // 			var country = countries.find(item => item.isoCountryCode == countryCode.toUpperCase());
    // 			if(country) application.travelHistory.countries.push(country);
    // 		}
    // 	}
    // }

    // private static mapEmploymentInfo(json: any, application: Application){
    // 	var currentEmps = json['currentEmployements'];
    // 	var pastEmps = json['pastEmployements'];
    // 	if(currentEmps){
    // 		for(let emp of currentEmps){
    // 			var e:Employment = this.mapSingleEmployment(emp, true);
    // 			application.employments.push(e);
    // 		}
    // 	}
    // 	if(pastEmps){
    // 		for(let emp of pastEmps){
    // 			var e:Employment = this.mapSingleEmployment(emp, false);
    // 			application.employments.push(e);
    // 		}
    // 	}
    // }

    // private static mapAddressInfo(json: any, application: Application){
    // 	/* backend end point needs to add uscontact */
    // 	var addressHistory = json['addressHistory'];
    // 	var mailing = json['mailingAddress'];
    // 	var currentAddress = json['currentAddress'];
    // 	if(currentAddress){
    // 		var a1: Address = this.mapSingleAddress(currentAddress);
    // 		a1.isCurrent = true;
    // 		application.addresses.push(a1);
    // 	}
    // 	if(mailing){
    // 		var a2: Address = this.mapSingleAddress(mailing);
    // 		application.mailing = a2;
    // 		application.diffMailing = json['mailingAddress']? true : false;
    // 	}
    // 	if(addressHistory){
    // 		for(let address of addressHistory){
    // 			var a: Address = this.mapSingleAddress(address);
    // 			application.addresses.push(a);
    // 		}
    // 	}
    // }

}
