import { GoesDate } from '../date.model';

export class ColombiaRequestedInfo {
    nationalID: number = null;
    maternalName: string = '';
    promoCode: string = '';
    timestamp: GoesDate = new GoesDate();

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof ColombiaRequestedInfo) {
                this.promoCode = jsonObject.promoCode;
                this.nationalID = jsonObject.nationalID;
                this.maternalName = jsonObject.maternalName;
                this.timestamp = jsonObject.timestamp;
            }
             else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) =>{
                        if(property.name === "COLOMBIA NATIONAL ID"){
                            this.nationalID = property.value;
                        }
                        if(property.name === "MATERNAL NAME"){
                            this.maternalName = property.value;
                        }
                        if(property.name === "COLOMBIA DISCLAIMER DATE"){
                            this.timestamp = new GoesDate(property.value);
                        }
                    });
                }
                if(jsonObject.hasOwnProperty('promotionCode')){
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
