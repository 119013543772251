<div class="row">
	<div class="col-12 col-md-6">
		<input #textbox class="selectmore-text form-control" placeholder="{{'APPLICATION.TRAVEL_HISTORY.START_TYPING' | translate }}" type="text" (keyup)="filter($event)">
		<ul class="select-list">
			<div *ngFor="let item of collection">
				<li *ngIf="alphaSort && isNewGroup(item)" class="list-group-header">
					{{ getSortLetter() }}
				</li>
				<li *ngIf="!isSelected(item)">
					<button *ngIf="multiSelect" type="button" (click)="add(item)">
						{{ item.description }}
						<i class="fa fa-plus"></i>
					</button>
				</li>
				<li *ngIf="isSelected(item)" class="selected">
					{{ item.description }}
					<i class="fa fa-check"></i>
				</li>
			</div>
		</ul>
	</div>

	<div class="col-12 col-md-6" *ngIf="multiSelect && selectedItems.length > 0">
		<br class="d-md-none"/>
		<h4 class="select-list-added-title" translate>APPLICATION.TRAVEL_HISTORY.COUNTRIES_ADDED</h4>
		<hr>
		<ul class="select-list-added">
			<div *ngFor="let item of selectedItems">
				<li>
					{{ item.description }}
					<button *ngIf="multiSelect" type="button" (click)="remove(item)" title="{{'GENERAL_REUSABLE.DELETE' | translate}}">
						<i class="far fa-trash-alt"></i>
					</button>
				</li>
			</div>
		</ul>
	</div>
</div>