import { EnhancedModel } from './enhanced.model';

export class ApplicationMetadata extends EnhancedModel {
    applicationId = '';
    applicationType = '';
    programs: Array<string> = new Array<string>();
    source = '';
    hasBalanceDue = false;
    status = '';
    externalStatus = '';
    stepCompleted = 0;
    interviewScheduledCode = '';
    interviewStatus = ''; // see scheduler.model for states
    scheduleByDate = '';
    canConvertToGE = false;
    canCancel = false;
    cardReplacementCode = '';
    canDoRemoteInterview = false;
    hasCAInterviewResult = false;
    hasCapturedPrints = false;
    gecApptScheduled = false;

    constructor(obj: any) {
        super();
        if (obj instanceof ApplicationMetadata) {
            this.mapClone(obj);
        }
        else if (obj instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(obj);
        }
        else {
            throw new Error('You must specify the correct parameter');
        }
    }

    public clone() {
        const cloned: ApplicationMetadata = new ApplicationMetadata(this);
        return cloned;
    }

    protected mapClone(application: ApplicationMetadata) {
        this.applicationId = application.applicationId;
        this.applicationType = application.applicationType;
        this.programs = application.programs;
        this.source = application.source;
        this.status = application.status;
        this.externalStatus = application.externalStatus;
        this.stepCompleted = application.stepCompleted;
        this.interviewScheduledCode = application.interviewScheduledCode;
        this.interviewStatus = application.interviewStatus;
        this.scheduleByDate = application.scheduleByDate;
        this.hasBalanceDue = application.hasBalanceDue;

        this.canConvertToGE = application.canConvertToGE;
        this.canCancel = application.canCancel;
        this.cardReplacementCode = application.cardReplacementCode;
        this.canDoRemoteInterview = application.canDoRemoteInterview;
        this.hasCAInterviewResult = application.hasCAInterviewResult;
        this.hasCapturedPrints = application.hasCapturedPrints;
        this.gecApptScheduled = application.gecApptScheduled;
    }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('applicationId')) {
            this.applicationId = jsonObject.applicationId;
        }
        if (jsonObject.hasOwnProperty('applicationType')) {
            this.applicationType = this.mapAppType(jsonObject.applicationType);
        }
        if (jsonObject.hasOwnProperty('programs')) {
            this.programs = jsonObject.programs;
        }
        if (jsonObject.hasOwnProperty('source')) {
            this.source = jsonObject.source;
        }
        if (jsonObject.hasOwnProperty('status')) {
            this.status = this.mapStatus(jsonObject.status);
        }
        if (jsonObject.hasOwnProperty('externalStatus')) {
            this.externalStatus = jsonObject.externalStatus;
        }
        if (jsonObject.hasOwnProperty('stepCompleted')) {
            this.stepCompleted = jsonObject.stepCompleted;
        }
        if (jsonObject.hasOwnProperty('interviewScheduledCode')) {
            this.interviewScheduledCode = jsonObject.interviewScheduledCode;

            if (this.interviewScheduledCode === 'IS' || this.interviewScheduledCode === 'IC') {
              this.interviewStatus = this.interviewScheduledCode;
            }
            else if (this.interviewScheduledCode === 'S') {
              this.interviewStatus = 'IS';
            }
            else if (this.interviewScheduledCode === 'C') {
              this.interviewStatus = 'IC';
            }
        }
        if (jsonObject.hasOwnProperty('interviewStatus')) {
            this.interviewStatus = jsonObject.interviewStatus;
        }
        if (jsonObject.hasOwnProperty('scheduleByDate')) {
            this.scheduleByDate = jsonObject.scheduleByDate;
        }
        if (jsonObject.hasOwnProperty('hasBalanceDue')) {
            this.hasBalanceDue = jsonObject.hasBalanceDue;
        }
        if (jsonObject.hasOwnProperty('canCancel')) {
            this.canCancel = jsonObject.canCancel;
        }
        if (jsonObject.hasOwnProperty('canConvertToGE')) {
            this.canConvertToGE = jsonObject.canConvertToGE;
        }
        if (jsonObject.hasOwnProperty('cardReplacementCode')) {
            this.cardReplacementCode = jsonObject.cardReplacementCode;
        }
        if (jsonObject.hasOwnProperty('canDoRemoteInterview')) {
            this.canDoRemoteInterview = jsonObject.canDoRemoteInterview;
        }
        if (jsonObject.hasOwnProperty('hasCAInterviewResult')) {
            this.hasCAInterviewResult = jsonObject.hasCAInterviewResult;
        }
        if (jsonObject.hasOwnProperty('hasCapturedPrints')) {
            this.hasCapturedPrints = jsonObject.hasCapturedPrints;
        }
        if (jsonObject.hasOwnProperty('gecApptScheduled')) {
            this.gecApptScheduled = jsonObject.gecApptScheduled;
        }
    }

    private mapAppType(appType: string): string {
        switch (appType) {
            case 'IV' :
                return 'IE';
        }
        return appType;
    }

    private mapStatus(status: string): string {
      switch (status) {
        case 'PF' :
          return 'PR';
      }
      return status;
    }

}
