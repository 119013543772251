import DateUtils from '../utils/dateUtils';

export class GoesDate {


    month : any;
    day : any;
    year : any;
    ISOString : string;
    date : Date;

    constructor( m?: any, d?: any, y?: any, stringDate?: string){
        if(m && d && y){
            let tempDate : Date = new Date(y, m, d);
            //should probably use momentJs to ensure valid Date format etc
            if(this.isDate(tempDate.toDateString())){
                this.date = new Date(y, m,d);
                this.month = m;
                this.day = d;
                this.year = y;
                this.ISOString = this.date.toISOString();
            }
        }
        else if(stringDate){
            stringDate = stringDate.split("-").join("/");
            let tempDate : Date = new Date(stringDate);
            if(this.isDate(tempDate.toDateString())){
                this.parseISO(stringDate);
                this.ISOString = this.date.toISOString();
            }
        }
        else{

            this.month = null;
            this.year = null;
            this.day = null;
            this.date = null;
            this.ISOString = "";
        }

    }


    getISOString() : string {
        if(this.year && this.month && this.day){
            this.date = new Date(this.year, (this.month-1),this.day);
            return this.ISOString = this.date.toISOString();
        }
        return null;
    }

    getSimpleString(): string {
        //TODO make function flexible to return various formats
        //return (this.month + '-' + this.day + '-' + this.year);
        if(this.year && this.month && this.day){
            return (this.year + '-' + this.month + '-' + this.day);
        }
        return "";
    }

    getFormattedString(): string {
        if(this.month && this.day && this.year){
            return DateUtils.getMonthAbrvStr(this.month) + " " + this.day + ", " + this.year;
        } else {
            return "";
        }
    }

    parseISO(isoString : string){
        if(this.isDate(isoString)){
            this.date = new Date(isoString);
            this.ISOString = this.date.toISOString();
            this.mapMonth(this.date.getMonth());
            this.mapDay(this.date.getDate());
            this.year = this.date.getFullYear();
        }
    }

    isPopulated() : boolean {
        return (this.year && this.month && this.day);
    }

    mapMonth(month : any) : void {
        switch (month) {
            case 0 :
                this.month = "01";
                break;
            case 1 :
                this.month = "02";
                break;
            case 2 :
                this.month = "03";
                break;
            case 3 :
                this.month = "04";
                break;
            case 4 :
                this.month = "05";
                break;
            case 5 :
                this.month = "06";
                break;
            case 6 :
                this.month = "07";
                break;
            case 7 :
                this.month = "08";
                break;
            case 8 :
                this.month = "09";
                break;
            case 9 :
                this.month = "10";
                break;
            case 10 :
                this.month = "11";
                break;
            case 11 :
                this.month = "12";
                break;
        }
    }

    mapDay(day : any) : void {
        if(day <= 9){
            switch (day) {
                case 1 :
                    this.day = "01";
                    break;
                case 2 :
                    this.day = "02";
                    break;
                case 3 :
                    this.day = "03";
                    break;
                case 4 :
                    this.day = "04";
                    break;
                case 5 :
                    this.day = "05";
                    break;
                case 6 :
                    this.day = "06";
                    break;
                case 7:
                    this.day = "07";
                    break;
                case 8:
                    this.day = "08";
                    break;
                case 9 :
                    this.day = "09";
                    break;
            }

        }
        else{
            this.day = day;
        }
    }

    isDate(date: any) : boolean{
        let value:any = new Date(date);
        return (value != 'Invalid Date') && !isNaN(value);
    }


}
