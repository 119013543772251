import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { ModalService } from '../../shared/modals/modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorHandlerService {

    serverErrorLabelKey = 'ERROR_MESSAGES.GENERAL.ERROR_CODES';

    constructor(
      private modalService: ModalService,
      private translate: TranslateService){}

     /*
      * Handles errors
      */
     handleError(error: any): Observable<any> {
       if (typeof error === 'string'){
         this.modalService.alert(error);
       }
       if (error instanceof ErrorEvent || HttpErrorResponse) {
         const codes = this._getErrorCodes(error);
         const keys = this._generateKeysForTranslation(codes);

         keys.push(this.serverErrorLabelKey);
         this._translateAndDisplayError(keys, codes, error);
       }
       return of(false);
     }

     /*
      * Translate all the keys, and displays the approriate message
      */
     private _translateAndDisplayError(keys: string[], codes: string, error: any) {
       this.translate.get(keys).subscribe(res => {
         let fullMsg: string;
         const genericMsg = res[keys[0]];
         const specificMsg = this._getSpecificMsg(res, keys);
         const apiMsg = this._getAPIMsg(res, keys, error);
         const serverErrorLabel = this.getServerErrorTranslation(res);
         fullMsg = '<p>' + (!specificMsg ? genericMsg : specificMsg) + '</p>';
         if (environment.SHOW_API_ERRORS && apiMsg){
           fullMsg += '<p>' + '<i><b>API Message: </b>' + apiMsg + '</i></p>';
         }
         if (codes !== '0') {
          fullMsg += '<p><b>' + serverErrorLabel + '</b>' + codes + '</p>';
        }
         this.modalService.alert(fullMsg);
       });
     }

     /*
      * Gets the error codes from a response object
      * returns 0 if unable to parse the response
      */
     private _getErrorCodes(error: any): string {
       try {
         const errorBody = (error as HttpErrorResponse).error;

         if (errorBody.hasOwnProperty('errorCode')) {
           return errorBody.errorCode.toString();
         }

       } catch (e) {
         // console.log('Failed to parse response.');
       }
       return '0'; // general error code
     }

     /*
      * Generate keys to translate for specified codes
      * First one will always be a generic message just in case
      * none of the codes were translated.
      * Returns atleast 2 keys to translate (3 if show API errors flag is set)
      */
     private _generateKeysForTranslation(codes: string): string[] {
       const keys: string[] = new Array<string>();
       keys.push('SERVER_ERRORS.' + 0 + '_UI');
       codes.split(',').forEach(code => {
         keys.push('SERVER_ERRORS.' + code + '_UI');
         if (environment.SHOW_API_ERRORS){
           keys.push('SERVER_ERRORS.' + code + '_API');
         }
       });
       return keys;
     }

     /*
      * Returns unique translated error messages as a single HTML string
      * or returns an empty string if nothing was translated
      */
     private _getSpecificMsg(res: any, keys: string[]): string {
       const uiKeys = keys.filter(m => m.lastIndexOf('_UI') !== -1 && m.indexOf('.0_UI') === -1);
       return this._getUnique(res, uiKeys);
     }

     /*
      * Returns unique translated api error messages as a single HTML string
      * or returns an empty string if nothing was translated
      */
     private _getAPIMsg(res: any, keys: string[], error?: any): string {
       const apiKeys = keys.filter(m => m.lastIndexOf('_API') !== -1);
       const apiMsg = this._getUnique(res, apiKeys);
       if (!apiMsg) {
         // support for older messages
         // when we don't need older messages, we can use the following instead:
         // return error;
         try {
           const errorBody = (error as HttpErrorResponse).error;
           if (errorBody.hasOwnProperty('errorMessageIdentifier')) {
             return errorBody.errorMessageIdentifier;
           } else {
             return error;
           }
         } catch (e) {
           return error;
         }
       }
       return apiMsg;
     }

     /*
      * Takes an object with one or more string type properties
      * and returns the unique set of values of those properties
      * as as single HTML encoded string (either a list or span)
      */
     private _getUnique(obj: any, props: string[]): string {
       let msg = '';
       if (obj) {
         let count = 0;
         props.forEach(prop => {
           if (obj[prop] !== prop && msg.indexOf(obj[prop]) === -1){
             count++;
             msg += '<span>' + obj[prop] + '</span>';
           }
         });
         if (count > 1) {
           msg = msg.split('<span>').join('<li>');
           msg = msg.split('</span>').join('</li>');
           msg = '<ul>' + msg + '</ul>';
         }
       }
       return msg;
     }

    /**
     * Deprecated
     *
     * @param  response [description]
     * @return          [description]
     */
    checkErrorCode(response: any): any {
        if (response.status) {
            if (response.status.errorCode !== 0 && response.status.errorCode !== ''){
                this.handleError(response.status.errorMessageIdentifier);
                return false;
            }
        }
        else if (response.errorCode && response.errorCode !== 0 && response.errorCode !== '') {
            this.handleError(response.errorMessageIdentifier);
            return false;
        }

        return true;
    }

    private getServerErrorTranslation(obj: any) {

      let errorCodeTranslated = 'Error code(s):';

      if (obj && obj[this.serverErrorLabelKey]) {
        errorCodeTranslated = obj[this.serverErrorLabelKey] !== this.serverErrorLabelKey ? obj[this.serverErrorLabelKey] : 'Error code(s):';
      }

      return errorCodeTranslated;

    }
}
