import { Directive, forwardRef, ElementRef, HostListener, Renderer2} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';

const TextPatternProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextPattern),
  multi: true
};


@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'input[type=text]:not([allowSpecial]), textarea[type=text]',
    host: {
        type: 'text',
        '(input)': 'input($event.target.value)',
        '(blur)': 'onTouched()'
    },
    providers: [TextPatternProvider],
    standalone: false
})
// tslint:disable-next-line:directive-class-suffix
export class TextPattern implements ControlValueAccessor {

    onChange = (_: any) => {};
    onTouched = () => {};

    constructor(private renderer: Renderer2, private  elementRef: ElementRef) {

    }

    blur() {
        this.onTouched();
    }

    @HostListener('keydown', ['$event']) onKeyDown(event: Event) {
        const e = event as KeyboardEvent;
        const ch = (e.key || String.fromCharCode(e.keyCode));

        const regEx = /^(?:(?![\!\@\#\$\%\>\^\*\\\(\)\_\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0000-\u007f\u00a0-\u00ff])*$/;

        if (regEx.test(ch)){
            return;
        }
        else{
            e.preventDefault();
        }
    }

    @HostListener('keypress', ['$event']) onKeyPress(event: Event) {
        const e = event as KeyboardEvent;
        const ch = (e.key || String.fromCharCode(e.keyCode));

        const regEx = /^(?:(?![\!\@\#\$\%\>\^\*\\\(\)\_\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
        if (regEx.test(ch)){
            return;
        }
        else{
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) paste(e: Event) {
        // Get pasted data via clipboard API
        const clipboardData = (e as any).clipboardData || (window as any).clipboardData;
        const pastedData = clipboardData.getData('Text');
        const regEx = /^(?:(?![\!\@\#\$\%\>\^\*\\\(\)\_\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
        if (regEx.test(pastedData)){
            return;
        }
        else{
            e.preventDefault();
        }
    }

    input(value: string) {
        // Write back to model
        if (value) {
            this.writeValue(value);
        }
        this.onChange(value);
    }

    writeValue(value: any): void {
        // Write to view
        value == null ? this.renderer.removeAttribute(this.elementRef.nativeElement, 'value') :
            this.renderer.setAttribute(this.elementRef.nativeElement, 'value', value);
    }

    registerOnChange(fn: (value: any) => any): void { this.onChange = fn; }

    registerOnTouched(fn: () => any): void { this.onTouched = fn; }

}
