import { Component, Input } from '@angular/core';

@Component({
    selector: 'option-modal',
    templateUrl: 'option-modal.component.html',
    standalone: false
})

export class OptionModalComponent{

    option : number = 0;
    @Input() optionOneText : string;
    @Input() optionOneLink : string;
    @Input() optionOneSource : string;

    @Input() optionTwoText : string;
    @Input() optionTwoLink : string;
    @Input() optionTwoSource : string;

    @Input() optionThreeText : string;
    @Input() optionThreeLink : string;
    @Input() optionThreeSource : string;

    @Input() title : string;
    @Input() buttonText : string;
    @Input() id : string;


    reset() : void {
        this.option = 0;
    }

    setOption(optionNum : number) : void {
        this.option = optionNum;
    }
}


