  <div id="{{id}}" class="modal modal-lg fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title w-100" [innerHTML]="'MODAL.NEED_HELP_TITLE' | translate"></h4>
        <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <div *ngIf="!screenShotInfoShown">
          <p [innerHTML]="'MODAL.NEED_HELP' | translate"></p>
          <button type="button" class="btn-link" (click)="showScreenShotInfo()">
            {{ 'MODAL.HOW_SCREENSHOT' | translate }}
          </button>
        </div>

        <div *ngIf="screenShotInfoShown">
          <p [innerHTML]= "'MODAL.SCREENSHOT_HELP' | translate"></p>
        </div>
      </div>
      <div class="modal-footer justify-content-evenly">
        <div class="col-lg-4 col-12 me-lg-auto" *ngIf="screenShotInfoShown">
          <button type="button" class="btn d-block w-100 btn-default" (click)="showHelpInfo()">
            <i class="fa fa-chevron-left" aria-hidden="true"></i> Back
          </button>
        </div>
  	  	<div class="col-lg-4 col-12">
        	<button type="button" class="btn d-block w-100 btn-primary" data-bs-dismiss="modal" (click)="reset()">Close</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->