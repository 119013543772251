import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Utils from '../../common/utils/utils';
import { SystemMessagesService } from '../../common/services/system-messages.service';

declare var $: any;

@Component({
    selector: 'go-footer',
    templateUrl: 'footer.component.html',
    standalone: false
})

export class FooterComponent {

    paperWorkMsg: string;

    constructor(
        private systemMessagesService: SystemMessagesService,
        private translate: TranslateService) {

    }

    ngOnInit() {

        //if language selection is changed, fetch relevant system messages data.
        this.systemMessagesService.sysMessagesUpdatedSubj.subscribe(res => {
            this.systemMessagesService.getSysMessages().subscribe((res: any) => {
                let data = res[this.translate.currentLang];

                if (data ) {
                    this.paperWorkMsg = data['login.main'].PRA;
                    //console.log("paperwork message retrieved: " + this.paperWorkMsg);
                }
            });
        });
    }

    sc() {
        Utils.scrollUp();
    }

}
