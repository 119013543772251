import { EnhancedModel } from './enhanced.model';

export class SurveyDetail extends EnhancedModel {

    surveyAvailable : boolean = false;
    surveyUrl : string;

    constructor(obj?:any){
        super();
        if (obj instanceof SurveyDetail) {
            this.mapClone(obj);
        }
        else if(obj instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(obj);
        }
    }

    public clone(){
        let cloned:SurveyDetail = new SurveyDetail(this);
        return cloned;
    }

    protected mapClone(surveyDetail:SurveyDetail) {
        this.surveyAvailable = surveyDetail.surveyAvailable;
        this.surveyUrl = surveyDetail.surveyUrl;
    }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('surveyAvailable')) {
            this.surveyAvailable = jsonObject.surveyAvailable;
        }

        if (jsonObject.hasOwnProperty('surveyUrl')) {
            this.surveyUrl = jsonObject.surveyUrl;
        }
    }
}
