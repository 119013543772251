import {Document} from '../models/document.model';
import {GoesDate} from '../models/date.model';
import {User} from '../models/user';
import DateUtils from './dateUtils';
import { SETTINGS } from '../../app.constants';
declare var Flutter: any;
import moment from 'moment';

/* helper functions */
declare var $: any;

export default class Utils {

    static mapYesNo(value: string): string {
        switch (value) {
            case 'N':
                return 'GENERAL_REUSABLE.NO_BUTTON';
            case 'Y':
                return 'GENERAL_REUSABLE.YES_BUTTON';
        }
        return value;
    }

    static mapPhoneType(value: string): string {
        switch (value) {
            case 'C':
                return 'APPLICATION.PERSONAL_INFO.MOBILE';
            case 'W':
                return 'APPLICATION.PERSONAL_INFO.WORK';
            case 'H':
                return 'APPLICATION.PERSONAL_INFO.HOME';
        }
        // else return value
        return value;
    }

    static mapGender(value: string): string {
        switch (value) {
            case 'M':
                return 'APPLICATION.PERSONAL_INFO.MALE';
            case 'F':
                return 'APPLICATION.PERSONAL_INFO.FEMALE';
            case 'X':
                return 'APPLICATION.PERSONAL_INFO.XGENDER';
        }
        // else return value
        return value;
    }

    static mapEmploymentType(value: string): string {
        switch (value) {
            case 'E':
                return 'APPLICATION.EMPLOYMENT_CARD.TITLE_EMPLOYED';
            case 'F':
                return 'APPLICATION.EMPLOYMENT_CARD.TITLE_SELF_EMPLOYED';
            case 'U':
                return 'APPLICATION.EMPLOYMENT_CARD.TITLE_NOT_EMPLOYED';
            case 'R':
                return 'APPLICATION.EMPLOYMENT_CARD.TITLE_RETIRED';
            case 'S':
                return 'APPLICATION.EMPLOYMENT_CARD.TITLE_STUDENT';
        }
        // else return value
        return value;
    }

    static mapEyeColor(value: string): string {
        switch (value) {
            case 'BK':
                return 'APPLICATION.PERSONAL_INFO.BLACK';
            case 'BR':
                return 'APPLICATION.PERSONAL_INFO.BROWN';
            case 'GR':
                return 'APPLICATION.PERSONAL_INFO.GREEN';
            case 'BL':
                return 'APPLICATION.PERSONAL_INFO.BLUE';
            case 'HA':
                return 'APPLICATION.PERSONAL_INFO.HAZEL';
            case 'BG':
                return 'APPLICATION.PERSONAL_INFO.BLUISHGREEN';
            case 'RD':
                return 'APPLICATION.PERSONAL_INFO.RED';
            case 'GY':
                return 'APPLICATION.PERSONAL_INFO.GRAY';
        }
        return value;
    }

    static mapDocType(value: string): string {
        switch (value) {
            case 'BB':
                return 'APPLICATION.DOCUMENTS.TYPE.BB';
            case 'BCC':
                return 'APPLICATION.DOCUMENTS.TYPE.BCC';
            case 'CC':
                return 'APPLICATION.DOCUMENTS.TYPE.CC';
            case 'CI':
                return 'APPLICATION.DOCUMENTS.TYPE.CI';
            case 'CRCC':
                return 'APPLICATION.DOCUMENTS.TYPE.CRCC';
            case 'CSC':
                return 'APPLICATION.DOCUMENTS.TYPE.CSC';
            case 'NC':
                return 'APPLICATION.DOCUMENTS.TYPE.NC';
            case 'PR':
                return 'APPLICATION.DOCUMENTS.TYPE.PR';
            case 'PT':
                return 'APPLICATION.DOCUMENTS.TYPE.PT';
            case 'SP':
                return 'APPLICATION.DOCUMENTS.TYPE.SP';
            case 'VS':
                return 'APPLICATION.DOCUMENTS.TYPE.VS';
            case 'WP':
                return 'APPLICATION.DOCUMENTS.TYPE.WP';
        }
        return value;
    }

    static mapPhoneFormat(value: string): string {
        switch (value) {
            case 'N':
                return 'APPLICATION.PERSONAL_INFO.US_CAN_FORMAT';
            case 'M':
                return 'APPLICATION.PERSONAL_INFO.MX_FORMAT';
            case 'I':
                return 'APPLICATION.PERSONAL_INFO.OTHER_FORMAT';
        }
        return value;
    }

    static mapVehicleOwner(value: string): string {
        switch (value) {
            case 'A':
                return 'APPLICATION.VEHICLE_INFO.VEHICLE_CARD.LABEL_WHO_OWNS_THE_VEHICLE_APPLICANT';
            case 'I':
                return 'APPLICATION.VEHICLE_INFO.VEHICLE_CARD.LABEL_WHO_OWNS_THE_VEHICLE_INDIVIDUAL';
            case 'B':
                return 'APPLICATION.VEHICLE_INFO.VEHICLE_CARD.LABEL_WHO_OWNS_THE_VEHICLE_BUSINESS';
        }
        return value;
    }


    static getPhoneMinlength(phoneFormat: string): number {
        switch (phoneFormat) {
            case 'I':
                return 5;
            case 'N':
                return 10;
            case 'M':
                return 5;
            default:
                return 1;
        }
    }


    static getTodayStr(): string {

        const todayTemp = new Date();
        let month = '';
        let day = '';
        if (todayTemp.getMonth() < 9) {
            month = '0' + (todayTemp.getMonth() + 1);
        } else {
            month = (todayTemp.getMonth() + 1).toString();
        }
        if (todayTemp.getDate() < 10) {
            day = '0' + todayTemp.getDate();
        } else {
            day = todayTemp.getDate().toString();
        }
        const result: string = todayTemp.getFullYear() + '-' + month + '-' + day;
        return result;
    }

    static getMonthStr(month: number): string {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        if (month) {
            month = parseInt(month.toString());
            return months[month - 1];
        } else {
            return '';
        }
    }

    static getMonthAbrvStr(month: number): string {
        return DateUtils.getMonthAbrvStr(month);
    }


    static scrollUp(id ?: string) {
        let offset = 200;
        const body = $('html, body');
        let target = $('#main-content');

        if (target && target.length) {
            offset = target.offset().top;
        }

        if (id) {
            target = $('#' + id);
            if (target && target.length) {
                offset = target.offset().top;

                target.trigger('focus');
            } else {
                // if ID cannot be found in DOM, return
                return;
            }
        }

        target.attr('tabindex', -1).on('blur focusout', function() {

                // when focus leaves this element,
                // remove the tabindex attribute
                $(this).removeAttr('tabindex');

        }).trigger('focus');

        body.stop().animate({
                scrollTop: offset
            }, '500', 'swing');
    }

    /**
     * Same a scrollUp and sets focus to first input, link, select or textarea.
     * Note: Use when scrolling to container
     *
     * @param id - contianer id
     */
    static scrollUpFocusChildElem(id: string) {
        let offset = 200;
        const body = $('html, body');

        const target = $('#' + id);
        if (target && target.length) {
            offset = target.offset().top;
        } else {
            // if ID cannot be found in DOM, return
            return;
        }

        target.attr('tabindex', -1).on('blur focusout', function() {
            $(this).removeAttr('tabindex');
        });
        // Focus on the first input, link, textarea or select
        target.find('input, a, textarea, select').filter(':visible:first').trigger('focus');

        body.stop().animate({ scrollTop: offset }, 1000, 'swing', () => {
            // target.attr('tabindex', -1).on('blur focusout', function() {
            //     $(this).removeAttr('tabindex');
            // });
            // // Focus on the first input, link, textarea or select
            // target.find('input, a, textarea, select').filter(':visible:first').trigger('focus');
        });
    }

    /**
     * @deprecated use age from user object or application
     * @param dateString
     * @returns 
     */
    static getAge(dateString: string | GoesDate) {
        const today = new Date();
        if (typeof dateString === 'string' && dateString) {
            const dob = new Date(dateString);
            return moment().diff(dob, 'years');
        } else if (dateString instanceof GoesDate && dateString.year) {
            return today.getFullYear() - dateString.year;
        }
        return null;
    }

    /**
     * function to calculate if user is eligible based on year age limit ex : 18
     * 
     * @deprecated use age from user object or application
     * @param dateString 
     * @param ageReq 
     * @returns 
     */
    static isMinAge(dateString: string, ageReq: number): boolean {

        const today = new Date();
        if (dateString) {
            const birthDate = dateString.split('-');

            let age = today.getFullYear() - parseInt(birthDate[0]);
            const months = today.getMonth() - (parseInt(birthDate[1]) - 1);

            if (months < 0 || (months === 0 && today.getDate() < parseInt(birthDate[2]))) {
                age--;
            }


            if (age >= ageReq) {
                //// console.log(ageReq);
                return true;
            }
        }
        return false;
    }

    static isDateBefore(dateToValidate: string, dateToCompare: string): boolean {

        const dateA = new Date(dateToValidate);
        const dateB = new Date(dateToCompare);

        return dateA.getTime() <= dateB.getTime();
    }

    static isDateAfter(dateToValidate: string, dateToCompare: string): boolean {

        const dateA = new Date(dateToValidate);
        const dateB = new Date(dateToCompare);

        return dateA.getTime() >= dateB.getTime();
    }

    /**
     *
     * @param dateString (ISO format YYYY-MM-DDTHH:mm:ss.sssZ || Simple (ISO) String Format) YYYY-MM-DD)
     */
    static isValidDateInput(dateString: string): boolean {

        if (!dateString) {
            return false;
        }

        if (dateString.length < 10) {
            return false;
        }

        const tempDate = dateString.split('-');

        if (tempDate.length < 3) {
            return false;
        }

        let year = 0;
        let month = 0;
        let day = 0;

        // Check individual lengths
        if (tempDate[0].length === 4) {
            if (tempDate[1].length !== 2) {
                return false;
            }
            if (tempDate[2].length >= 2) {
                // cut off excess iso code data if present
                tempDate[2] = tempDate[2].substr(0, 2);
            } else {
                return false;
            }
            // iso format
            year = parseInt(tempDate[0]);
            month = parseInt(tempDate[1]);
            day = parseInt(tempDate[2]);
        }
        // Year str length for ISO or simple is not correct.
        else {
            return false;
        }

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return false;
        }

        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (this.isLeapYear(year)) {
            monthLength[1] = 29;
        }

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];

    }

    static extractYYYMMDDFromDate(date: Date): string {

        let str: string = date.getFullYear().toString();

        if (date.getMonth() < 10) {
            str += '-' + '0' + date.getMonth();
        } else {
            str += '-' + date.getMonth();
        }
        if (date.getDate() < 10) {
            str += '-' + '0' + date.getDate();
        } else {
            str += '-' + date.getDate();
        }

        return str;

    }

    static extractHHMMFromDate(date: Date, includeColon: boolean = true): string {

        let str = '';

        if (date.getHours() < 10) {
            str += '0' + date.getHours();
        } else {
            str += date.getHours();
        }

        if (includeColon) {
            str += ':';
        }

        if (date.getMinutes() < 10) {
            str += '0' + date.getMinutes();
        } else {
            str += date.getMinutes();
        }

        return str;

    }

    static extractNonMilTimeFromDate(date: Date): string {

        let str = '';

        if (date.getHours() < 13) {
            str += date.getHours();
        } else {
            str += date.getHours() - 12;
        }

        str += ':';


        if (date.getMinutes() < 10) {
            str += '0' + date.getMinutes();
        } else {
            str += date.getMinutes();
        }

        if (date.getHours() >= 12) {
            str += ' PM';
        } else {
            str += ' AM';
        }

        str += ' ' + this.extractTimeZoneFromDate(date);

        return str;

    }


    static extractTimeZoneFromDate(date: Date): string {

        let str: string = date.toString();
        str = str.substring(str.indexOf('(') + 1, str.length - 1);

        return str;
    }


    static isLeapYear(year: number): boolean {
        return (year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0));

    }

    /* not intended for primitives - only for getting objects from local storage */
    static getFromLocalStorage(key: string): any {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            } else {
                return null;
            }
        } catch (e) {}
    }

    /* not intended for primitives - only for putting objects in local storage */
    static putInLocalStorage(key: string, obj: any) {
        if (obj) {
            localStorage.setItem(key, JSON.stringify(obj));
        }
    }

    static openPrintView(content ?: string, applicationTitle ?: string, submitted ?: boolean): any {
        if (!content) {
            const printArea = window.document.getElementById('print-area');
            content = printArea.outerHTML;
            content = content.replace(/<a /g, '<span ');
            content = content.replace(/<\/a>/g, '</span>');
        }
        const todayTemp = new Date();
        const currentDate = '<b>Time of Printout:  ' + '</b>' + this.getTodayStr() + ' ' + this.extractNonMilTimeFromDate(todayTemp);

        const stylesheetURL = document.querySelector<HTMLLinkElement>('link[rel=stylesheet][href^=styles][href*=css]')?.href;

        const childWindow = window.open('print.html', '_blank');
        if (childWindow) {

            setTimeout(() => {
                // Add the stylesheet
                const link = childWindow.document.createElement('link');

                link.type = 'text/css';
                link.rel = 'stylesheet';
                link.href = stylesheetURL;
          
                childWindow.document.head.appendChild(link);
        
                if (applicationTitle) {
                    // create the title
                    const appTitle = childWindow.document.createElement('h2');
                    appTitle.className = 'print-header';
                    appTitle.innerHTML = '<b>Application for:  ' + '</b>' + applicationTitle;
                    // create the status
                    let appStatusStr = 'Draft';
                    if (submitted) {
                        appStatusStr = 'Submitted';
                    }
                    const appStatus = childWindow.document.createElement('h2');
                    appStatus.className = 'print-header';
                    appStatus.innerHTML = '<b>Version:  ' + '</b>' + appStatusStr;
                    // add app title and status
                    childWindow.document.body.appendChild(appTitle);
                    childWindow.document.body.appendChild(appStatus);

                }
                // create the current date line
                const datePrinted = childWindow.document.createElement('h2');
                datePrinted.className = 'print-header';
                datePrinted.innerHTML = currentDate;
                // create the print icon and link
                const printLink = childWindow.document.createElement('div');
                printLink.innerHTML = '<a href="javascript:print()" alt="print this"><i class="fa fa-print"></i> Print</a>';
                // set up main content
                const mainContent = childWindow.document.createElement('div');
                mainContent.innerHTML = content;
                // add everything to document body
                childWindow.document.body.appendChild(datePrinted);
                childWindow.document.body.appendChild(printLink);
                childWindow.document.body.appendChild(mainContent);
            }, 1000);
        }
        return childWindow;        
    }

    /*
     * gets route name for FAQ page identification.  ex : /application/101/personal-info returns 'personal-info'
     * An exception is: /account-profile/uuid, in this case we want account-profile returned
     */
    static getRouteName(urlRoute: string): string {

        if (urlRoute.indexOf('account-profile') > -1){
            return 'account-profile';
        }

        let closingIndex = urlRoute.length;
        const hasQuery = urlRoute.indexOf('?');
        const hasHash = urlRoute.indexOf('#');

        if (hasQuery !== -1 && hasHash !== -1) {
            hasQuery <= hasHash ? closingIndex = hasQuery : closingIndex = hasHash;
        } else if (hasQuery !== -1) {
            closingIndex = hasQuery;
        } else if (hasHash !== -1) {
            closingIndex = hasHash;
        }

        const result: string = urlRoute.substring(urlRoute.lastIndexOf('/') + 1, closingIndex);
        if (result) {
            return result;
        }
        return urlRoute;
    }

    /*
     * refresh / leaving TTP browser warning
     * when navigating away to apps like scheduler or payment.gov or login.gov
     * use Utils.gotoExternalUrl(). this provides a way to control when to show the
     * warning and when not to show it
     */
    static enableSiteLeaveWarning() {
        window.onbeforeunload = () => {
            return '';
        };
    }

    static enableDeleteOnClose(): void {
         window.onunload = () => {
            const tempToken = localStorage.getItem(SETTINGS.TOKEN_NAME);
            localStorage.removeItem(SETTINGS.TOKEN_NAME);
        };
    }

    /*
     * TODO add parameter to send in URLs
     *
     * turn off the 'leaving site' browser warning for
     * pay.gov, login.gov and scheduler
     * Turn off for cbp.dhs.gov 0 payments.
     * Localhost for dev
     */
    /*** SKIPPING FROM COVERAGE because of the use of window object ***/
    /* istanbul ignore next */
    static gotoExternalUrl(url: string, overrideWindow ?: any) {
        if (
            url.indexOf('pay.gov') > -1 ||
            url.indexOf('login.gov') > -1 ||
            url.indexOf('scheduler') > -1 ||
            url.indexOf('10.156.') > -1 ||
            url.indexOf('credential') > -1 ||
            url.indexOf('ttp-test.cbp.dhs.gov') > -1 ||
            url.indexOf('ttp.dhs.gov') > -1 ||
            url.indexOf('ttp.cbp.dhs.gov') > -1 ||
            // url.indexOf('localhost') > -1 ||
            url.indexOf('cbp.dhs.gov') > -1 ||
            url.indexOf('cbsa-asfc.cloud-nuage.canada.ca') > -1
        ) {
            window.onbeforeunload = undefined;
            window.onunload = undefined;
        }

        if (overrideWindow) {
            overrideWindow.location.href = url;
        } else {
            window.location.href = url;
        }
    }


    /**
     * detect IE
     * returns version of IE or false, if browser is not Internet Explorer
     */
    static detectIE() {
        const ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // IE 12 / Spartan
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge (IE 12+)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64)
        //       AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            const rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        const edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    /*
     * Tests two objects for equality, recursively tests nested objects
     * Only compares properties that exist in both objects in the comparison
     */
    static areEqual(base: any, x: any) {
        let p; // property
        let result = false; // default
        for (p in base) {
            if (base.hasOwnProperty(p) && x.hasOwnProperty(p)) {
                // property exists in both objects, so lets compare
                if (base[p] instanceof Array && x[p] instanceof Array){
                    // only checks arrays of primitive types
                    if (base[p].length !== x[p].length){
                        return false;
                    }
                    // loop through all values in base array and see if there is
                    // a match in the other array
                    // tslint:disable-next-line:prefer-for-of
                    for (let i = 0; i < base[p].length; i++){
                        const val = base[p][i];
                        if (typeof(val) !== 'object'){
                            if (x[p].filter((val2: any) => val2 === val).length === 0){
                                return false;
                            }
                        }
                    }
                } else {
                    switch (typeof(base[p])) {
                        case 'object':
                            if (base[p] && x[p]){
                                if (!Utils.areEqual(base[p], x[p])) {
                                  return false;
                                }
                            }
                            break;
                        case 'function':
                            break;
                        default:
                            base[p] = (base[p] === null ? '' : base[p]);
                            x[p] = (x[p] === null ? '' : x[p]);
                            //// console.log("comparing " + p + ": " + base[p] + " == " + x[p]);
                            if (base[p] !== x[p] && p !== 'validatedIndicator') {
                              return false;
                            }
                    }
                }
                // properties are equal, so far so good
                result = true;
            }
        }
        return result;
    }

    static parseISOLocal(date: string): Date {

        const splitDate = date.split(/\D/);

        return new Date(Number(splitDate[0]),
                        Number(splitDate[1]) - 1,
                        Number(splitDate[2]),
                        Number(splitDate[3]),
                        Number(splitDate[4]),
                        Number(splitDate[5] || '0'));
    }

    static generateSuffix() {
        const suffix = Math.floor(1000 + Math.random()  * 9000) + 'N';
        return suffix;
    }

    static getPreferredLanguage(languages: string[]): string {
        const storedLanguage = localStorage.getItem('ttp_language');
        if (storedLanguage) {
            return storedLanguage;
        }
        if (languages) {
            for (const lang of languages) {
                for (const validLang of ['es', 'fr', 'en']) {
                    if (lang.startsWith(validLang)) {
                        return validLang;
                    }
                }
            }
        }
        return 'en';
    }

    /**
     * Test name against pattern from GES.
     * If not required then and name is empty, return true.
     *
     * @param name - string
     * @param required - boolean
     */
    static isValidName(name: string, required?: boolean): boolean {

        // TODO Move regexp to app.constants
        const namePattern: RegExp = /^(?=.*[A-Za-z])(?:(?![\!\@\#\$\%\^\*\(\\)\_\+\=\{\}\|\[\]\:\;\<\>\?\/\~])[\u0000-\u00ff])*$/;

        if (!required && !name) {
            return true;
        }

        if (namePattern.test(name)) {
            return true;
        }
        return false;
    }

    /**
     * Compares the name/dob in User to the name/dob in the document.
     *
     * @param user The logged in user object
     * @param document The document for comparison
     */
    static doesDocNameDOBMatchUser(user: User, document: Document): boolean {

        const profileFirstInitial = user.firstName.length >= 1 ? user.firstName.trim().charAt(0).toLowerCase() : '';
        let profileLastName = user.lastName && user.lastName.length > 0 ? user.lastName.trim().toLowerCase() : '';
        profileLastName = Utils.normalizeLastName(profileLastName);

        const dobTrimmed = user.dateOfBirth && user.dateOfBirth.trim();

        if (document) {
            const documentGivenName =
                document.givenName && document.givenName.length > 0 ? document.givenName.trim().charAt(0).toLowerCase() : '';
            let documentSurName =
                document.surName && document.surName.length > 0 ? document.surName.trim().toLowerCase() : '';
            documentSurName = Utils.normalizeLastName(documentSurName);

            if (documentGivenName !== profileFirstInitial ||
                documentSurName !== profileLastName ||
                document.dateOfBirth.getSimpleString() !== dobTrimmed) {
                    return false;
                }
        }

        return true;
    }

    /**
     * Trims whitespace before and after in string, and remove extra whitespace between words.
     *
     * Ex. 'Foo     Bar' becomes 'Foo Bar'
     */
    static removeWhitespace(inString: string): string {
        if (inString) {
            return inString.trim().replace(/\s+/g, ' ');
        }

        return '';
    }

    /**
     * Normalizes the last name for comparison using the following rules.
     * - trims string
     * - removes whitespace in betwee multiple names (ex. bob smith > bobsmith)
     * - removes hyphens and apostrophies
     *
     * @param inString the last name to normalize
     */
    static normalizeLastName(inString: string): string {
        if (inString) {

            return this.removeSuffix(inString).trim().replace(/[\s\-\'\.\,\`]/g, '');
        }

        return '';
    }

    static removeSuffix(inString: string): string {
        if (inString) {
            return inString.toLowerCase().trim().replace(/(\s[js]r\.?|iii?|iv)$/g, '');
        }

        return '';
    }

    static convertISODateToLongDate(dateStr: string): string {
      let dobFormatStr = '';
      const dobArray: string[] = dateStr.split('-');
      if (dobArray.length === 3 &&  Utils.getMonthAbrvStr(Number(dobArray[1]))) {
        dobFormatStr = Utils.getMonthAbrvStr(Number(dobArray[1])) + ' ' + dobArray[2] + ', ' + dobArray[0];
      } else {
        dobFormatStr = dateStr;
      }
      return dobFormatStr;
    }

    /** Returns the protocol host and port part of the URL */
    static getBaseURL(): string {
        if (window && window.location && window.location.href) {
            return window.location.href.split('/').slice(0, 3).join('/');
        } else {
            return '/';
        }
    }

    static getPathNameFromURL(url: string): string {
        let pathName = '';
        if (url) {
            const urlParts = url.split('/');
            if (urlParts.length >= 3) {
                pathName = '/' + urlParts.slice(3);
            }
        }
        return pathName;
    }

    static isNumeric(num: any): boolean {
        return (typeof(num) === 'number' || typeof(num) === 'string' && num.trim() !== '') && !isNaN(num as number);
    }


  /* send messages to flutter webview */
  /* istanbul ignore next */
  static notifyWebView(key: WebViewMsg, params?: { [key: string]: any } ) {
    if (typeof Flutter !== 'undefined') {
        if (params) {
            Flutter.postMessage(key + '=' + JSON.stringify(params));
        } else {
            Flutter.postMessage(key);
        }
    }
  }
}

/*  enumeration for messages that can be sent to flutter webview */
export enum WebViewMsg {
    PAYMENT = 'Payment'
}

