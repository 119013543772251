import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FAQ } from '../../common/models/faq.model';
import { FAQContentItem } from '../../common/models/faq-content-item.model';

@Injectable()
export class FAQService {

    private faqList: FAQ[];
    private faqLocation = '../../assets/i18n/';

    hasFaqSubj: Subject<boolean> = new Subject<boolean>();
    showFaqBar: BehaviorSubject<string> = new BehaviorSubject(null);
    faqListLang: Subject<string> = new Subject<string>();

    constructor(private http: HttpClient) {
        this.hasFaqSubj.next(false);
        this.showFaqBar.next('true');
        this.faqListLang.next('en');
    }

    // iterate through list of FAQs, filter out content items that pertain to current page.
    // return new list for current page based on results.
    getFAQsForPage(routeName: string): FAQ[] {
        const pageFaqs = new Array<FAQ>();
        if (this.faqList) {
            this.faqList.forEach( faq => {
            const filteredContent = faq.content.filter(pageContent => pageContent.pages && pageContent.pages.indexOf(routeName) !== -1);
            if (filteredContent.length > 0) {
                    const tempFaq = new FAQ(faq);
                    tempFaq.content = new Array<FAQContentItem>();
                    filteredContent.forEach(pageContent => {
                        tempFaq.content.push(new FAQContentItem(pageContent));
                    });
                    pageFaqs.push(tempFaq);
                }
            });
        }

        if (pageFaqs && pageFaqs.length > 0) {
            this.hasFaqSubj.next(true);
        } else {
            this.hasFaqSubj.next(false);
        }

        return pageFaqs;
    }

    updateFAQList(langKey: string): Observable<any> {
        return this.http.get(this.faqLocation + 'faq-' + langKey + '.json').pipe(
            map((res: any) => {
               if (res && res.faq){
                   this.faqList = new Array<FAQ>();
                   res.faq.forEach((faq: any) => {
                        const tempFAQ = new FAQ(faq);
                        this.faqList.push(tempFAQ);
                    });

                   this.faqListLang.next(langKey);

                   return this.faqList;
               }
                else{
                  return null;
                }
            }));
    }

    getFAQS(): FAQ[] {
        return this.faqList;
    }

    public showHideFAQBar(show: boolean) {
        this.showFaqBar.next(show ? 'true' : 'false');
    }

}
