export class User {
	userId: string;
	username: string;
	membershipOrPassId: string;
    dateOfBirth : string;
	name: string;
	email: string;
	loginGovEmail : string;
	firstName: string;
	lastName: string;
	age: number;

	constructor() {
		this.userId = '';
		this.username = '';
		this.membershipOrPassId = '';
		this.name = '';
		this.dateOfBirth = '';
		this.email = '';
		this.loginGovEmail = '';
		this.firstName = '';
		this.lastName = '';
		this.age = null;
	}
}
