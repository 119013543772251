import { Phone } from './phone';
import { Address } from './address';

export class Employment {
	isCurrent: boolean;
	status: string;
	startMonth: string;
	startYear: string;
	endMonth: string;
	endYear: string;
	occupation: string;
	employer: string;
	phone: Phone;
	address: Address;

	constructor(jsonObject: any = null) {
		if (jsonObject !== null) {
			if (jsonObject instanceof Employment) {
				this.isCurrent = jsonObject.isCurrent;
				this.status = jsonObject.status;
				this.startMonth = jsonObject.startMonth;
				this.startYear = jsonObject.startYear;
				this.endMonth = jsonObject.endMonth;
				this.endYear = jsonObject.endYear;
				this.occupation = jsonObject.occupation;
				this.employer = jsonObject.employer;
				this.phone = jsonObject.phone;
				this.address = jsonObject.address;
			} else {
				// this is a json object lets deserialize it
				this.address = new Address(jsonObject);
				if (jsonObject.hasOwnProperty('employer')) {
					this.employer = jsonObject.employer;
				} else {
					this.employer = '';
				}
				if (jsonObject.hasOwnProperty('employmentStatus')) {
					this.status = jsonObject.employmentStatus;
				} else {
					this.status = '';
				}
				if (jsonObject.hasOwnProperty('endDate')) {
                    if (typeof jsonObject.endDate === 'string') {
						let endDate: string[] = jsonObject.endDate.split('-');
						this.endMonth = (endDate[1]);
						this.endYear = (endDate[0]);
					} else {
						this.endMonth = null;
						this.endYear = null;
					}
				} else {
					this.endMonth = null;
					this.endYear = null;
				}
				if (jsonObject.hasOwnProperty('occupation')) {
					this.occupation = jsonObject.occupation;
				} else {
					this.occupation = '';
				}
				if (jsonObject.hasOwnProperty('startDate')) {
				    if (typeof jsonObject.startDate === 'string') {
						let startDate: string[] = jsonObject.startDate.split('-');
						this.startMonth = (startDate[1]);
						this.startYear = (startDate[0]);
					}else {
						this.startMonth = null;
						this.startYear = null;
					}
				} else {
					this.startMonth = null;
					this.startYear = null;
				}
				this.phone = new Phone(jsonObject);
			}
		} else {
			this.isCurrent = false;
			this.status = '';
			this.startMonth = null;
			this.startYear = null;
			this.endMonth = null;
			this.endYear = null;
			this.occupation = '';
			this.employer = '';
			this.phone = new Phone();
			this.address = new Address();
		}
	}
}
