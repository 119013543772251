import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { tDate } from './tDate';
import { tEvent } from './tEvent';

@Injectable()
export class TimelineService {
	
	constructor(){}

	createTimelinePoints(events: tEvent[], startDate: tDate, endDate: tDate, rectWidth?: number, clientRect?: ClientRect): any {
		
		var rect: any;

		if(!clientRect){
			// define a bounding rectangle if none was provided
            rect = {
            	bottom: 552,
            	height: 35,
            	left: 265, 
            	right: 1175,
            	top: 517,
            };
            rectWidth = 900;
        } else {
        	rect = clientRect; 
        }

        // var w = rectWidth;
		var u = ((endDate.y-startDate.y)*12)+(endDate.m-startDate.m);
		var tu = rectWidth/u;

		var tpoints = new Array<any>();

		for(var event of events){
		  var startTick = this._getTick(event.s, startDate, tu, rectWidth, rect);
		  var endTick = this._getTick(event.e, startDate, tu, rectWidth, rect, true);
		  tpoints.push({o:'f', s:startTick, e:endTick});
		}

		tpoints = tpoints.sort((a,b) => {
			if(a.e === b.e){
				return a.s - b.s;
			} else {
				return a.e - b.e;
			}
		});

		var tpointsVer1:any = [];
		var prev = Math.round(rect.left);
		for(var p of tpoints){
		  if(p.s != prev){
		    tpointsVer1.push({o:'g', s:prev, e:p.s});
		  }
		  tpointsVer1.push(p);
		  prev = p.e;
		}

		var timelineEndPoint = Math.round(rect.width+rect.left);
		if(prev < timelineEndPoint){
			tpointsVer1.push({o:'g', s:prev, e:timelineEndPoint});
		}

		var tpointsFinal:any = [];
		for(var p of tpointsVer1){
		  if(p.s < p.e){
		    tpointsFinal.push(p);
		  }
		}

		return tpointsFinal;
	}

	hasGap(points: any[]) : boolean {
		//filter out all gaps based on user entries
		let allGaps = points.filter(x => x.o === 'g');
		let allFilled = points.filter(x => x.o === 'f');
		if(allGaps.length > 0){

			let numGap : number = 0;
			allGaps.forEach(gapPoint => {
				let isCovered = false;
				// for each gap check if there is a non-gap that covers it
				//ex : I worked mon-tues, and thurs - friday = gap on wednesday, but if I also worked a different job monday - friday, the first gap is covered 
				allFilled.forEach(filledPoint => {
					if(filledPoint.s <= gapPoint.s && filledPoint.e >= gapPoint.e){
						isCovered = true;
					}
				});
				if(!isCovered){
					numGap++;
				}
			});
			//if there was a gap that wasnt covered, return true
			return numGap > 0;
		} else {
			return false;
		}
	}

	private _getTick(tdate: tDate, startDate: tDate, tu: number, w: number, tdims: ClientRect, isEndDate? : boolean): number{
		var py = tdate.y;
		var pm = isEndDate? tdate.m+1 : tdate.m; // consider end month as the last day of the month
		var x = ((py-startDate.y)*12)+(pm-startDate.m);
		var y = x * tu;
		y = y>w?w:y;
		y = y<0?0:y;
		return Math.round(y+tdims.left);
	}

}