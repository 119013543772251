import { Country } from './country.model';

export class Address {
	startMonth: string;
	startYear: string;
	endMonth: string;
	endYear: string;
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	postalCode: string;
	countryCode: string;
	country: Country;
	isCurrent: boolean;
	mxNeighborhood: string;
    //validatedAddressUsed : string;
    validatedIndicator : string = "T"; // T: NOT VALIDATED, Y : VALIDATED, O : OVERRIDDEN

	constructor(jsonObject: any = null) {
		if (jsonObject !== null) {
			if (jsonObject instanceof Address) {
				this.startMonth = jsonObject.startMonth;
				this.startYear = jsonObject.startYear;
				this.endMonth = jsonObject.endMonth;
				this.endYear = jsonObject.endYear;
				this.addressLine1 = jsonObject.addressLine1;
				this.addressLine2 = jsonObject.addressLine2;
				this.city = jsonObject.city;
				this.state = jsonObject.state;
				this.postalCode = jsonObject.postalCode;
                this.countryCode = jsonObject.countryCode;
				this.country = jsonObject.country;
				this.isCurrent = jsonObject.isCurrent;
				this.mxNeighborhood = jsonObject.mxNeighborhood;
        //        this.validatedAddressUsed = jsonObject.validatedAddressUsed;
                this.validatedIndicator = jsonObject.validatedIndicator;

            } else {
				// this is a json object from the backend lets deserialize it
				if (jsonObject.hasOwnProperty('city')) {
					this.city = jsonObject.city;
				} else {
					this.city = '';
				}
				if (jsonObject.hasOwnProperty('country')) {
					this.country = new Country();
					this.country.isoCountryCode = jsonObject.country;
                    this.countryCode = jsonObject.country;
				} else {
					this.country = new Country();
				}
				if (jsonObject.hasOwnProperty('endDate')) {
					if (typeof jsonObject.endDate === 'string') {
						let endDate: string[] = jsonObject.endDate.split('-');
						this.endMonth = (endDate[1]);
						this.endYear = (endDate[0]);
					} else {
						this.endMonth = null;
						this.endYear = null;
					}
				} else {
					this.endMonth = null;
					this.endYear = null;
				}
				if (jsonObject.hasOwnProperty('postalCode')) {
					this.postalCode = jsonObject.postalCode;
				} else {
					this.postalCode = null;
				}
				if (jsonObject.hasOwnProperty('startDate')) {
					if (typeof jsonObject.startDate === 'string') {
						let startDate: string[] = jsonObject.startDate.split('-');
						this.startMonth = (startDate[1]);
						this.startYear = (startDate[0]);
					} else {
						this.startMonth = null;
						this.startYear = null;
					}
				} else {
					this.startMonth = null;
					this.startYear = null;
				}
				if (jsonObject.hasOwnProperty('state')) {
					this.state = jsonObject.state;
				} else {
					this.state = null;
				}
//                if (jsonObject.hasOwnProperty('validatedAddressUsed')) {
//					this.state = jsonObject.validatedAddressUsed;
//				} else {
//					this.validatedAddressUsed = null;
//				}
                if (jsonObject.hasOwnProperty('addressValidationCode')) {
					this.validatedIndicator = jsonObject.addressValidationCode;
				}

				if (jsonObject.hasOwnProperty('street1Address')) {
					this.addressLine1 = jsonObject.street1Address;
				} else {
					this.addressLine1 = null;
				}
				if (jsonObject.hasOwnProperty('street2Address')) {
					this.addressLine2 = jsonObject.street2Address;
				} else {
					this.addressLine2 = null;
				}
				if (jsonObject.hasOwnProperty('coloniaNeighborhood')) {
					this.mxNeighborhood = jsonObject.coloniaNeighborhood;
				} else {
					this.mxNeighborhood = '';
				}
				this.isCurrent = false;
			}
		} else {
			this.startMonth = null;
			this.startYear = null;
			this.endMonth = null;
			this.endYear = null;
			this.addressLine1 = null;
			this.addressLine2 = null;
			this.city = null;
			this.state = null;
			this.postalCode = null;
			this.country = new Country();
			this.isCurrent = false;
			this.mxNeighborhood = '';
		}
	}
}
