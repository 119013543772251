/** Add to get rid of cyclic dependency but ultimately date related utilites should go here. */

export default class DateUtils {

    static getMonthAbrvStr(month: number): string {
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        if (month) {
            month = parseInt(month.toString());
            return months[month - 1];
        } else
            return "";
    }
}
