import { Injectable } from '@angular/core';

// TODO: extend to remote service

@Injectable()
export class LogService {
    public message(obj:any, extraObj?:any){
        if(!extraObj){
            //console.log(obj);
        } else {
            //console.log(obj, extraObj);
        }
    }
    public warning(obj:any, extraObj?:any){
        if(!extraObj){
            //console.warn(obj);
        } else {
            //console.warn(obj, extraObj);
        }
    }
    public error(obj:any, extraObj?:any){
        if(!extraObj){
            //console.error(obj);
        } else {
            //console.error(obj, extraObj);
        }
    }
}
