export class AdditionalQuestion {

    questionTypeCode : string;
    questionDescription : string;
    response : string;
    details : string;
    countryCode : string;
    code: string;

    constructor(jsonObject: any = null){
    	this.questionTypeCode = '';
    	this.questionDescription = '';
    	this.response = '';
    	this.details = '';
    	this.countryCode = '';
    	this.code = '';
    	if (jsonObject !== null) {
             if (jsonObject instanceof AdditionalQuestion) {
                 this.questionDescription = jsonObject.questionDescription;
                 this.questionTypeCode = jsonObject.questionTypeCode;
                 this.response = jsonObject.response;
                 this.details = jsonObject.details;
                 this.countryCode = jsonObject.countryCode;
                 this.code = jsonObject.code;
             } else {
             	if (jsonObject.hasOwnProperty('countryFlag')) {
             		this.questionTypeCode = jsonObject.countryFlag;
             	}
             	if (jsonObject.hasOwnProperty('code')) {
             		this.code = jsonObject.code;
             	}
             	if (jsonObject.hasOwnProperty('country')) {
             		this.countryCode = jsonObject.country;
             	}
             	if (jsonObject.hasOwnProperty('details')) {
             		this.details = jsonObject.details;
             	}
             	if (jsonObject.hasOwnProperty('response')) {
             		this.response = jsonObject.response;
             	}
             }
        }
    }
}
