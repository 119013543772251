export class ArgentinaRequestedInfo {
    nationalID: number = null;
    promoCode: string = '';
    maternalSurname : string = '';
    maternalGivenName : string = '';
    paternalSurname : string = '';
    paternalGivenName : string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof ArgentinaRequestedInfo) {
                this.promoCode = jsonObject.promoCode;
                this.nationalID = jsonObject.nationalID;
                this.maternalSurname = jsonObject.maternalSurname;
                this.maternalGivenName = jsonObject.maternalGivenName;
                this.paternalGivenName = jsonObject.paternalGivenName;
                this.paternalSurname = jsonObject.paternalSurname;
            } else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) =>{
                        if(property.name === "ARGENTINA NATIONAL ID"){
                            this.nationalID = property.value;
                        }
                        if(property.name === "MOTHER FIRST NAME"){
                            this.maternalGivenName = property.value;
                        }
                        if(property.name === "MOTHER LAST NAME"){
                            this.maternalSurname = property.value;
                        }
                        if(property.name === "FATHER FIRST NAME"){
                            this.paternalGivenName = property.value;
                        }
                        if(property.name === "FATHER LAST NAME"){
                            this.paternalSurname = property.value;
                        }
                    });
                }
                if(jsonObject.hasOwnProperty('promotionCode')){
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
