import { Country } from './country.model';

export class ApplicationMinimumData {
    primaryCountry:Country;
    programCode:string;
    /**
     * Construct with minimum data required for new applications
     * @param primaryCountry
     * @param programCode
     */
    constructor(primaryCountry:Country, programCode:string){
        this.primaryCountry = primaryCountry;
        this.programCode = programCode;
    }
}
