export class BrazilRequestedInfo {
    promoCode: string = '';
    cpfNumber: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof BrazilRequestedInfo) {
                this.promoCode = jsonObject.promoCode;
                this.cpfNumber = jsonObject.cpfNumber;
            }  else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) => {
                        if (property.name === "BRAZIL CPF NUMBER") {
                            this.cpfNumber = property.value;
                        }
                    });
                }
                if (jsonObject.hasOwnProperty('promotionCode')) {
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
