<div id="{{id}}" class="modal modal-lg fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title flex-fill" [innerHTML]="title | translate"></h4>
        <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <h1 class="program-price" style="margin-bottom: 5px;">
          <span *ngIf="program === 'TSA'">{{'MODAL.FROM' | translate}} </span>
          <b *ngIf="currentLang === 'es'" >US{{programCost}}</b>
          <b *ngIf="currentLang !== 'es'" >{{programCost}} USD</b>
        </h1>
        <p *ngIf="program === 'TSA'" style="color: #2E6089;" translate>MODAL.NEW_ENROLLMENT</p>

        <p *ngIf="program !== 'TSA'"><em translate>MODAL.LESS_WAITING</em></p>
        <p *ngIf="program === 'TSA'"><em [innerHTML]="'MODAL.LESS_WAITING_TSA' | translate"></em></p>

        <h4 *ngIf="program !== 'TSA'" translate>MODAL.HOW_IT_WORKS</h4>
        <h4 *ngIf="program === 'TSA'" translate>MODAL.HOW_IT_WORKS</h4>

        <p *ngIf="program !== 'TSA' && program !=='UP'">1. {{'MODAL.APPLY_ONLINE' | translate}}<br>{{'MODAL.SUBMIT_ONLINE_APPLICATION' | translate}}.</p>
        <p *ngIf="program === 'TSA'">1. {{'MODAL.APPLY_ONLINE' | translate}}<br><span [innerHTML]="'MODAL.SUBMIT_ONLINE_APPLICATION_TSA' | translate"></span>.</p>
        <p *ngIf="program === 'UP'">1. {{'MODAL.APPLY_ONLINE' | translate}}<br>{{'MODAL.SUBMIT_ONLINE_APPLICATION' | translate}}.</p>

        <p *ngIf="program !== 'TSA' && program !== 'UP'">2. {{'MODAL.ENROLL' | translate}}<br>{{'MODAL.SCHEDULE_APPOINTMENT' | translate}}.<br>
          <a *ngIf="program !== 'FN'" target="_blank" [attr.href]="enrollmentCenterUrl" translate>HOME.BEST_MATCH_RESULTS.FIND_ENROLLMENT_CENTER</a>
          <a *ngIf="program === 'FN'" target="_blank" [attr.href]="this.schedulerViewLocationsUrl + 'fn'" translate>MODAL.FIND_FN_ENROLLMENT_CENTER</a><br>
          <a *ngIf="program === 'FN'" target="_blank" [attr.href]="this.schedulerViewLocationsUrl + 'fs'" translate>MODAL.FIND_FS_ENROLLMENT_CENTER</a>
        </p>
        <p *ngIf="program === 'TSA'">2. {{'MODAL.VIST_ENROLLMENT_LOCATION' | translate}}<br>{{'MODAL.SCHEDULE_APPOINTMENT_TSA' | translate}}.<br>
          <a target="_blank" [attr.href]="enrollmentCenterUrl" translate>MODAL.FIND_ENROLLMENT_LOCATION_NEAR_YOU</a>
        </p>

        <p *ngIf="program === 'UP'">2. {{'MODAL.ENROLL' | translate}}<br><span [innerHTML]="'MODAL.SCHEDULE_APPOINTMENT_UP' | translate"></span><br><br>
          <!--/* //NOSONAR */--><a target="_blank" href="https://www.cbp.gov/travel/trusted-traveler-programs/global-entry/enrollment-arrival" rel="noopener" translate>MODAL.FIND_UP_EOA</a>
          <br>
          <a target="_blank" [attr.href]="enrollmentCenterUrl" translate>MODAL.FIND_ENROLLMENT_CENTER</a>
        </p>

        <p *ngIf="program !== 'TSA'">3. {{'HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP_HEADER' | translate}} <br>{{'HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP' | translate}}<br>
          <span *ngIf="membershipLink" [innerHTML]="membershipLink | translate"></span>
        </p>
        <p *ngIf="program === 'TSA'"><span [innerHTML]="'HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP_HEADER_TSA' | translate"></span><br>{{'HOME.BEST_MATCH_RESULTS.VIEWMORE_CARD_MEMBERSHIP_TSA' | translate}}<br>
          <span *ngIf="membershipLink" [innerHTML]="membershipLink | translate"></span>
        </p>

        <h4 translate>MODAL.BENEFITS</h4>
        <p></p>
        <div class="program-cell__benefits-section-modal" [innerHTML]="benefits | translate: {value: tsaPreMapURL}"></div>

        <div *ngIf="program !== 'TSA' && program !== 'FN'" class="program-info-accordion">
          <div class="accordion-title" tabindex="-1">
            <a data-bs-toggle="collapse" href="#eptCollapsible-{{id}}" role="button" aria-expanded="false" attr.aria-controls="eptCollapsible-{{id}}">
              <span id="collapse-arrow1-{{id}}" class="fa fa-chevron-right pull-left"></span>
              <span class="program-info-title" translate>MODAL.ESTIMATED_PROCESSING_TIME.HEADER</span>
            </a>
          </div>
          <div id="eptCollapsible-{{id}}" class="collapse accordion-body">
            <p></p>
            <ul>
              <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI1</li>
              <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI2</li>
              <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI3
                <ul>
                  <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI3A</li>
                  <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI3B</li>
                </ul>
              </li>
              <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI4</li>
              <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI5
                <ul>
                  <li translate>MODAL.ESTIMATED_PROCESSING_TIME.LI5A</li>
                  <li [innerHTML]="'MODAL.ESTIMATED_PROCESSING_TIME.LI5B' | translate"></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="program === 'TSA'" >
          <h4 translate>MODAL.REASONS_FOR_DELAYS_HEADER</h4>
          <div>
            <p translate>MODAL.REASONS_FOR_DELAYS_TSA</p>
          </div>  
        </div>

        <div *ngIf="program !== 'TSA'" class="program-info-accordion">
          <div class="accordion-title" tabindex="-1">
            <a data-bs-toggle="collapse" href="#delayCollapsible-{{id}}" role="button" aria-expanded="false" attr.aria-controls="delayCollapsible-{{id}}">
              <span id="collapse-arrow2-{{id}}" class="fa fa-chevron-right pull-left"></span>
              <span class="program-info-title"  translate>MODAL.REASONS_FOR_DELAYS_HEADER</span>
            </a>
          </div>
            <div id="delayCollapsible-{{id}}" class="collapse  accordion-body">
              <p translate>MODAL.REASONS_FOR_DELAYS_TTP.REASON1</p>
                <ul>
                  <li translate>MODAL.REASONS_FOR_DELAYS_TTP.LI1</li>
                  <li translate>MODAL.REASONS_FOR_DELAYS_TTP.LI2</li>
                  <li translate>MODAL.REASONS_FOR_DELAYS_TTP.LI3</li>
                  <li translate>MODAL.REASONS_FOR_DELAYS_TTP.LI4</li>
                  <li translate>MODAL.REASONS_FOR_DELAYS_TTP.LI5</li>
                  <li translate>MODAL.REASONS_FOR_DELAYS_TTP.LI6</li>
                  <li translate>MODAL.REASONS_FOR_DELAYS_TTP.LI7</li>
                </ul>
              <p translate>MODAL.REASONS_FOR_DELAYS_TTP.FOOTER</p>
            </div>
        </div>
      </div>
      <!-- Confirm don't need the footer. -->
      <div class="modal-footer">
        <div class="modal-program-button">
            <a *ngIf="program === 'TSA'" tabindex="0" target="_blank" class="btn btn-success get-started-modal" (click)="logGetStartedProgram(program);" (keyup.enter)="logGetStartedProgram(program);" [attr.href]="tsaGetStarteURL" translate>HOME.GET_STARTED</a>
            <a *ngIf="program !== 'TSA'" tabindex="0" target="_blank" class="btn btn-success get-started-modal" (click)="logGetStartedProgram(program); logIn(id);" (keyup.enter)="logGetStartedProgram(program); logIn(id);" translate>HOME.GET_STARTED</a>
          </div>

      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
