export class IsraelRequestedInfo {
    promoCode: string = '';
    citizenshipNumber: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof IsraelRequestedInfo) {
                this.promoCode = jsonObject.promoCode;
                this.citizenshipNumber = jsonObject.citizenshipNumber;
            }  else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) =>{
                        if(property.name === "ISRAEL IDENTITY NUMBER"){
                            this.citizenshipNumber = property.value;
                        }
                    });
                }
                if(jsonObject.hasOwnProperty('promotionCode')){
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
