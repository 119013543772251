
<form #dateForm= "ngForm">
    <div>
        <label class="label-with-tooltip" >
            <span id="dateSelect{{domId}}"><span *ngIf="required" class="required">* </span>{{label | translate}}</span>
            <tooltip *ngIf="toolText" [tooltip-text] = "toolText"></tooltip>
        </label>
        <div class="row">
            <div class="col-4 date-selection">
                <select  attr.aria-labelledby="dateSelect{{domId}} monthHiddenLabel{{domId}}" name="month" 
                    [disabled]="disabled ? '' : null" #month="ngModel" [id]= "domId+ '_month'" 
                    [(ngModel)] = "date.month" (blur)="isValid()" class="form-select" [required]="required">
                    <option value=""></option>
                    <option *ngFor = "let month of months" [ngValue] = "month.value">{{month.label}}</option>
                </select>
                <label class="hidden" id= "monthHiddenLabel{{domId}}">Month selected from the drop down list</label>
                <p class="subtext" translate>DATE.MONTH</p>
            </div>
            <div class="col-4 date-selection">
                <input attr.aria-labelledby="dateSelect{{domId}} dayHiddenLabel{{domId}}" type = "text"  allowSpecial="true" 
                    [disabled]="disabled ? '' : null" [value-type]="'numeric'"  #day="ngModel" name="day" 
                    [id]= "domId+ '_day'" [(ngModel)] = "date.day" (blur)="onDayBlur()" class="form-control" maxlength="2" [required]="required">
                <label class="hidden" id= "dayHiddenLabel{{domId}}">Day in 2 digit format</label>
                <p class="subtext" translate>DATE.DAY</p>
            </div>
            <div class="col-4 date-selection">
                <input attr.aria-labelledby="dateSelect{{domId}} yearHiddenLabel{{domId}}" type = "text" allowSpecial="true" 
                    [disabled]="disabled ? '' : null" [value-type]="'numeric'" #year = "ngModel" 
                    [id]= "domId +'_year'" name="year" [(ngModel)] = "date.year" (blur)="isValid()" class="form-control"  maxlength="4" [required] = "required">
                <label class="hidden" id= "yearHiddenLabel{{domId}}">Year in 4 digit format</label>
                <p class="subtext" translate>DATE.YEAR</p>
            </div>
            <div class= "col-12">
                <p *ngIf = "invalidFormat" class="field-error" [innerHTML]= "'ERROR_MESSAGES.GENERAL.DATE_ERROR' | translate"></p>
                <p *ngIf = "minError" class="field-error" [innerHTML]= "'ERROR_MESSAGES.GENERAL.DATE_MIN_ERROR' | translate : {dateString : minDate}"></p>
                <p *ngIf = "maxError" class="field-error" [innerHTML]= "'ERROR_MESSAGES.GENERAL.DATE_MAX_ERROR' | translate : {dateString : maxDate}"></p>
                <p *ngIf = "errorLabel && required && parentSubmitted 
                            && ((month.errors && month.errors['required'])
                            || (day.errors && day.errors['required'])
                            || (year.errors && year.errors['required']))" class="field-error" [innerHTML]= "'ERROR_MESSAGES.DATES.REQUIRED.'+errorLabel | translate"></p>
                 <p *ngIf = "!errorLabel && required && parentSubmitted 
                            && ((month.errors && month.errors['required'])
                            || (day.errors && day.errors['required'])
                            || (year.errors && year.errors['required']))" class="field-error" [innerHTML]= "'ERROR_MESSAGES.GENERAL.REQUIRED' | translate"></p>
            </div>
        </div>
    </div>    
</form> 
    

