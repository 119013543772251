<!-- // NAVIGATION -->
<div class="nav-row" *ngIf="!hideBar">
  <div class="container">

    <nav class="navbar navbar-dark navbar-expand-md" role="navigation">
      <!-- // Brand information and hamburger menu -->
      <!-- <div class="navbar-header"> -->
        <a type="button" class="navbar-toggle navbar-toggler collapsed" data-bs-toggle="collapse" data-bs-target="#app-navbar" aria-expanded="false" >
          <i class="fa fa-bars" aria-hidden="true"></i> Menu
        </a>

        <div class="nav navbar-nav navbar-right d-md-none" *ngIf="authService.isUserLoggedIn()">
          <a class="navbar-toggle collapsed" href="#" title="{{'NAVBAR.LOG_OUT' | translate}}" (click)="logOut($event)">
            {{'NAVBAR.LOG_OUT' | translate}}
          </a>
        </div>
        <div *ngIf="!authService.isUserLoggedIn()" class="float-end">
          <ul class="nav navbar-nav navbar-right d-sm-flex d-md-none ms-auto flex-row">
            <!-- <li style="display:inline-block">
              <input type="button" class="btn btn-primary" value="{{'NAVBAR.SIGN_UP' | translate}}"/>
            </li> -->
            <li class="">
              <a  class="btn btn-default tsa-header-btn" (click)="logTsaLinkClicked()" [attr.href]="tsaURL" target="_blank" [innerHTML]="'NAVBAR.GO_TSA_BUTTON' | translate"></a>
            </li>
            <li class="">
              <input type="button" class="btn btn-default" value="{{'NAVBAR.LOG_IN' | translate}}" (click)="logIn()"/>
            </li>
          </ul>
        </div>
      <!-- </div> -->

      <!-- // NAVBAR items -->
      <div class="collapse navbar-collapse" id="app-navbar">
        <ul class="nav navbar-nav flex-row" >
          <li *ngIf="canShowNav">
            <a tabindex="0" title="{{'NAVBAR.LINK_TITLE_HOME' | translate}}" (click)="collapse('app-navbar')" [routerLink]="['/']" [routerLinkActive]="['router-link-active']" [routerLinkActiveOptions]="{exact:true}"><i class="fa fa-home"></i> {{'NAVBAR.HOME' | translate}}</a>
          </li>
          <li *ngIf="authService.isUserLoggedIn()  && canShowNav">
            <a tabindex="0" title="{{'NAVBAR.LINK_TITLE_DASHBOARD' | translate}}" (click)="collapse('app-navbar')" [routerLink]="['/dashboard']" [routerLinkActive]="['router-link-active']" [routerLinkActiveOptions]="{exact:true}">
              <i class="fas fa-tachometer-alt"></i> {{'NAVBAR.DASHBOARD' | translate}}
            </a>
          </li>
          <li>
            <a tabindex="0" title="{{'NAVBAR.LINK_TITLE_FREQUENTLY_ASKED_QUESTIONS' | translate}}"  href="/faq?lang={{translate.currentLang}}" target="_blank">
            <!-- <a tabindex="0" title="{{'NAVBAR.LINK_TITLE_FREQUENTLY_ASKED_QUESTIONS' | translate}}" (click)="scrollToFaq()" (keyup.enter)="scrollToFaq()"> -->
              <span class="d-inline d-md-none d-lg-inline"><i class="fa fa-question-circle"></i> {{'NAVBAR.FAQ' | translate}}</span>
              <span class="d-none d-md-inline d-lg-none"><i class="fa fa-question-circle"></i> {{'NAVBAR.FAQ_MOBILE' | translate}}</span>
            </a>
          </li>
        </ul>

        <ul class="nav navbar-nav navbar-right ms-auto d-none d-md-flex" *ngIf="canShowNav || accountCreation">          
          <li *ngIf="!authService.isUserLoggedIn() && canShowNav">
              <p class="navbar-button-tsa" [innerHTML]="'NAVBAR.LOOKING_TSA' | translate"></p>
              <a  class="btn btn-default tsa-header-btn" (click)="logTsaLinkClicked()" [attr.href]="tsaURL" target="_blank" [innerHTML]="'NAVBAR.GO_TSA_BUTTON' | translate"></a>
          </li>
          <li *ngIf="(!authService.isUserLoggedIn() && canShowNav)">
            <p class="navbar-button" [innerHTML]="'NAVBAR.RETURNING_MEMBER' | translate"></p>
            <input type="button" class="btn btn-default login-button" value="{{'NAVBAR.LOG_IN' | translate}}" (click)="logLoginButtonClicked(); logIn()"/>
          </li>
          <li class="d-none d-sm-block" *ngIf="(authService.isUserLoggedIn() && canShowNav) || accountCreation"  class="nav-item">
            <a tabindex="0" title="{{'NAVBAR.LOG_OUT' | translate}}" (keyup.enter)="logOut($event);" (click)="logOut($event)">
              {{'NAVBAR.LOG_OUT' | translate}}
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
<security-modal #securityModal [id]="'security-warn'" [route]="'/dashboard'"></security-modal>