export class SaudiArabiaRequestedInfo {
    promoCode: string = '';
    nationalID: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof SaudiArabiaRequestedInfo) {
                this.nationalID = jsonObject.nationalID;
                this.promoCode = jsonObject.promoCode;
            } else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) =>{
                        if(property.name === "SAUDI ARABIA NATIONAL ID"){
                            this.nationalID = property.value;
                        }
                    });
                }
                if(jsonObject.hasOwnProperty('promotionCode')){
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
