export class FAQContentItem {

    title: string;
    body: string;
    pages: String[];
    popular: boolean;

    constructor(jsonObject: any = null) {

        if (jsonObject !== null) {
            if (jsonObject instanceof FAQContentItem) {
                this.title = jsonObject.title;
                this.body = jsonObject.body;
                this.pages = jsonObject.pages;
                this.popular = jsonObject.popular;
            } else {
                //deserialize
                if (jsonObject.hasOwnProperty('title')) {
                    this.title = jsonObject.title;
                } else {
                    this.title = '';
                }

                if (jsonObject.hasOwnProperty('body')) {
                    this.body = jsonObject.body;
                } else {
                    this.body = '';
                }

                if (jsonObject.hasOwnProperty('pages')) {
                    this.pages = jsonObject.pages;
                } else {
                    this.pages = new Array < String > ();
                }

                if (jsonObject.hasOwnProperty('popular')) {
                    this.popular = jsonObject.popular;
                } else {
                    this.popular = false;
                }
            }
        } else {
            this.title = '';
            this.body = '';
            this.pages = new Array < String > ();
            this.popular = false;
        }
    }


    }
