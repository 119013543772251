<!-- error message modal definition -->

<div id="alertModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title" translate>MODAL.ALERT_TITLE</h4>
      </div> -->
      <div class="modal-body">
        <div class="with-icon error">
          <i class="fa fa-exclamation-triangle"></i>
          <p [innerHTML]="translateSrc | translate:params"></p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
  	  	<div class="col-12 col-md-4">
        	<button type="button" class="btn d-block w-100 btn-primary" data-bs-dismiss="modal" translate>MODAL.BUTTON_OK</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->