export class PilotRequestedInfo {
    promoCode: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof PilotRequestedInfo) {
                this.promoCode = jsonObject.promoCode;
            } else {
                // this is a json object let's deserialize it
                if(jsonObject.hasOwnProperty('promotionCode')){
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
