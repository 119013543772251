import { FAQContentItem } from './faq-content-item.model';
export class FAQ {

    topic: string;
    content: FAQContentItem[]; // W=Work, M=Mobile, H=Home

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof FAQ) {
                this.topic = jsonObject.topic;
                this.content = jsonObject.content;
            } else {
                // this is a json object lets deserialize it
                if (jsonObject.hasOwnProperty('topic')) {
                    this.topic = jsonObject.topic;
                } else {
                    this.topic = '';
                }
                if (jsonObject.hasOwnProperty('content')) {
                    this.content = jsonObject.content;
                } else {
                    this.content = new Array < FAQContentItem > ();
                }
            }
        } else {
            this.topic = '';
            this.content = new Array < FAQContentItem > ();
        }
    }

}
