import { Country } from '../country.model';
import { EnhancedModel } from '../enhanced.model';
// import { CodeName } from './code-name.model';
import { CodeNameHash } from './code-name-hash.interface';
import { CountryHash } from './country-hash.interface';
import { VisaClass } from '../visa-class.model';

/**
 * Init in TranslateReferenceService
 */
export class ReferenceDataModel extends EnhancedModel {

    public programInfo:any = {};
    public applicationTypes:any = {};

    private _isDataCached:boolean = false;
    public get isDataCached():boolean {
        return this._isDataCached;
    }
    private _srcData:any = {};
    public get srcData():any {
        return this._srcData;
    }

    private _countryList:Array<Country> = new Array<Country>();
    public get countryList():Array<Country> {
        return this._countryList;
    }

    private _countryHashMap:CountryHash = {};
    public get countryHashMap():CountryHash {
        return this._countryHashMap;
    }

    private _birthCountryList = new Array<Country>();
    public get birthCountryList():Array<Country> {
        return this._birthCountryList;
    }

    private _citizenshipCountryList:Array<Country> = new Array<Country>();
    public get citizenshipCountryList():Array<Country> {
        return this._citizenshipCountryList;
    }

    private _addressCountryList:Array<Country> = new Array<Country>();
    public get addressCountryList():Array<Country> {
        return this._addressCountryList;
    }

    private _applicationStatuses:CodeNameHash = {};
    public get applicationStatuses():CodeNameHash {
        return this._applicationStatuses;
    }

    private _visaClasses: Array<VisaClass> = new Array<VisaClass>();
    public get visaClasses(): VisaClass[] {
        return this._visaClasses;
    }

    private _birthStateList : Array<any> = new Array <any>();
    public get birthStates(): Array<any> {
        return this._birthStateList;
    }

    private _cardReplacementCodes: Array<any> = new Array <any>();
    public get cardReplacementCodes(): Array<any> {
        return this._cardReplacementCodes;
    }

    /**
     * Create this class with the JSON object from the reference service or existing class for cloning
     * @param srcData
     */
    constructor(srcData:any) {
        super();
        if (srcData instanceof ReferenceDataModel) {
            this.mapClone(srcData);
        } else if(srcData instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(srcData);
        } else {
            throw new Error('You must specify the correct parameter');
        }
    }

    public getCountryByIsoCode(code:string):Country {
        if(!this._countryHashMap[code]) {
            throw new Error('This country does not have a hash mapped value');
        }
        return this._countryHashMap[code];
    }

    public clone():ReferenceDataModel {
        let cloned:ReferenceDataModel = new ReferenceDataModel(this);
        return cloned;
    }
    protected mapClone(obj:ReferenceDataModel):void {
        this._isDataCached = obj.isDataCached;
        this._srcData = obj.srcData;
        this._countryList = obj.countryList;
        this._birthCountryList = obj.countryList;
        this._citizenshipCountryList = obj.citizenshipCountryList;
        this._addressCountryList = obj.addressCountryList;
        this._applicationStatuses = obj.applicationStatuses;
        this._cardReplacementCodes = obj.cardReplacementCodes;
        this._countryHashMap = obj.countryHashMap;
        this.programInfo = obj.programInfo;
        this.applicationTypes = obj.applicationTypes;
        this._visaClasses = obj.visaClasses;
    }

    protected mapJson(obj:any):void {

        this._srcData = obj;

        // init countries
        if (obj.hasOwnProperty('countries')) {

            //setup countries by category
            obj.countries.forEach((item: any) => {
                let country:Country = new Country(item);
                this._countryList.push(country);
                if(country.isForBirth === true) {
                    this._birthCountryList.push(country);
                }
                //temp for demo should be true
                if(country.isForCitizenship === true) {
                    this._citizenshipCountryList.push(country);
                }
                if(country.isForCurrentAddress === true) {
                    this._addressCountryList.push(country);
                }
                // create quick access hashmap
                this._countryHashMap[country.isoCountryCode] = country;
            });

        }

        if(obj.hasOwnProperty('states')) {
            if(obj.states.US){
                obj.states.US.forEach((state: any) => {
                   if(state.stateTypeCode === "A"){
                       this._birthStateList.push(state);
                   }

                });
            }
        }

        // init status types with hashmap
        if(obj.hasOwnProperty('applicationStatuses')) {
            obj.applicationStatuses.forEach((item: any) => {
                if(!item.code) {
                    throw new Error('Code not found on applicationStatuses item');
                }
                this._applicationStatuses[item.code] = item;
            });
        }

        // init visa classes
        if (obj.hasOwnProperty('visaClasses')) {
            obj.visaClasses.forEach((visaClass: any) => {
                this._visaClasses.push(new VisaClass(visaClass));
            });
        }

        // init program info
        if (obj.hasOwnProperty('programInfo')) {
            this.programInfo = obj.programInfo;
        }

        if (obj.hasOwnProperty('applicationTypes')) {
            this.applicationTypes = obj.applicationTypes;
        }

        if(obj.hasOwnProperty('cardReplacementCodes')) {
            this._cardReplacementCodes = obj.cardReplacementCodes;
            this._cardReplacementCodes.sort(function (x, y) {
                let a = x.name.toUpperCase(),
                    b = y.name.toUpperCase();
                return a == b ? 0 : a > b ? 1 : -1;
            });
        }

    }
}
