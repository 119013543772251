export class ProgramInfo{

    programName : string;
    programDescription : string;
    programEligibilityInfo : string;
    programNarrative : string;
    programMinAgeNumber : number;
    expirationYearNumber : number;
    ombNumber : string;
    fees : any;
    benefitCode : string;
    parentProgramCode : string;
    schedulingAvailabilityInd : boolean;
    applicationSubmitInd : boolean;
    paperReductionActInd : boolean;
    todoMessages : [{
        messageTypeCode : string;
        programStatusCode : string;
        message : string;
    }];

    constructor(){
        this.programName = "";
        this.programDescription = "";
        this.programEligibilityInfo = "";
        this.programNarrative = "";
        this.programMinAgeNumber = null;
        this.expirationYearNumber = null;
        this.ombNumber = null;
        this.benefitCode = "";
        this.parentProgramCode = "";
        this.schedulingAvailabilityInd = false;
        this.applicationSubmitInd = false;
        this.paperReductionActInd = false;
        this.todoMessages = null;
    }

}
