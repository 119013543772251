
import {of as observableOf, throwError as observableThrowError,  Observable ,  Subject, of } from 'rxjs';

import {retry, catchError, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { SETTINGS } from '../../app.constants';

@Injectable()
export class AuthHttpService {

  isRefreshing = false;
  tokenIsValid = false; // Exist and not expired
  tokenInvalidatedSubj: Subject<boolean>;
  tokenUpdatedSubj: Subject<boolean>;

  tokenExpDateSubj: Subject<Date>;
  tokenExpDate: Date;
  // private jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {
    this.tokenInvalidatedSubj = new Subject<boolean>();
    this.tokenUpdatedSubj = new Subject<boolean>();
    this.tokenExpDateSubj = new Subject<Date>();

    // This really should only be in auth.service
    this.tokenExpDateSubj.subscribe(updated => {
      if (updated) {
          this.tokenExpDate = updated;
      }
  });
  }

  // This should either be moved to auth.service or the token methods moved here
  public tokenRequiresRefresh(): boolean {
    if (environment.authenticateWithCookie) {
      return this.tokenInCookieRequiresRefresh();
    } else {
      return this.tokenInLocalStoragRequiresRefresh();
    }
  }

  public tokenInLocalStoragRequiresRefresh(): boolean {
    let refreshRequired = false;
    const token = localStorage.getItem(SETTINGS.TOKEN_NAME);
    try {

        if (!this.jwtHelper.isTokenExpired(token) ) {
            this.tokenIsValid = true;
            const expiration = this.jwtHelper.getTokenExpirationDate(token);
            const timeToRenew = new Date(expiration.getTime() - environment.TOKEN_REFRESH_BEFORE_MIN * 60000);
            const now = new Date();

            if (now > timeToRenew) {
                return true;
            }
        } else {
          if (token) {
              localStorage.removeItem(SETTINGS.TOKEN_NAME);
          }

          this.tokenIsValid = false;
          refreshRequired = true;
        }
    } catch (e) {
        if (token) {
            localStorage.removeItem(SETTINGS.TOKEN_NAME);
        }

        this.tokenIsValid = false;
        refreshRequired = true;
    }

    return refreshRequired;
  }

  public tokenInCookieRequiresRefresh(): boolean {
    if (this.tokenExpDate) {
      const timeToRenew = new Date(this.tokenExpDate.getTime() - environment.TOKEN_REFRESH_BEFORE_MIN * 60000);
      const now = new Date();

      if (now > timeToRenew) {
          return true;
      }
    }

    return false;
  }

  public refreshAndUpdateToken(broadcast?: boolean): Observable<boolean> {

    if (environment.authenticateWithCookie) {
      return this.refreshAndUpdateCookie();
    } else {
      return this.refreshAndUpdateTokenInLocalStorage(broadcast);
    }
  }

  public refreshAndUpdateCookie(): Observable<boolean> {
    this.isRefreshing = true;
    const tokenEndpoint = environment.uriCSEndpoint  + 'credential/v2/refresh';
    return this.http.get(tokenEndpoint).pipe(
      retry(2))
      .pipe(
       switchMap((data: any) => {
          if (data && data.expiration) {
            this.tokenExpDateSubj.next(new Date(data.expiration));
          }
          return observableOf(true);
        }),
        catchError(error => {
          // console.log('Error refreshing token : '  + error);
          return observableOf(false);
        })
      );
  }

  /**
   * Refresh the token and update localStorage
   * @param broadcast - set if you want subscribers of tokenUpdatedSubj to know.
   */
  public refreshAndUpdateTokenInLocalStorage(broadcast?: boolean): Observable<boolean> {
    const refToken = localStorage.getItem(SETTINGS.TOKEN_NAME);
    if (refToken) {
      this.isRefreshing = true;
      const tokenEndpoint = environment.uriCSEndpoint  + 'credential/' +  environment.apiVersion + 'refresh?token=' + refToken ;
      return this.http.get(tokenEndpoint).pipe(
        retry(2))
        .pipe(
         switchMap((data: any) => {
            if (data && data.hasOwnProperty('id_token')) {
                if (broadcast) {
                  this.tokenUpdatedSubj.next(true);
                }
                // this.tokenExpDateSubj.next(new Date(data.expiration));

                localStorage.setItem(SETTINGS.TOKEN_NAME, data.id_token);
                this.isRefreshing = false;
                return observableOf(true);
            } else {
              return observableOf(false);
            }
          }),
          catchError(error => {
            // console.log('Error refreshing token : '  + error);
            return observableOf(false);
          })
        );
    } else {
      return observableOf(false);
    }
  }

  public doRefresh(): Observable<any> {
    if (environment.authenticateWithCookie) {
      return this.doRefreshTokenInCookie();
    } else {
      return this.doRefreshTokenInLocalStorage();
    }
  }

  public doRefreshTokenInLocalStorage(): Observable<any> {
    const refToken = localStorage.getItem(SETTINGS.TOKEN_NAME);

    if (refToken  && this.tokenRequiresRefresh()) {
      return this.refreshAndUpdateToken(true);
    } else {
      return of(true);
    }
  }

  public doRefreshTokenInCookie(): Observable<any> {
    if (this.tokenRequiresRefresh()) {
      return this.refreshAndUpdateToken(true);
    } else {
      return of(true);
    }
  }
}
