
export class TsaPreData {
    
    
    clone(): TsaPreData {
      const retVal = new TsaPreData();
      retVal.knownTravelerNumber = this.knownTravelerNumber;
      retVal.verificationStatus = this.verificationStatus;
      retVal.hasTsaKTN = this.hasTsaKTN;
      retVal.subjectFirstName = this.subjectFirstName;
      retVal.subjectMiddleName = this.subjectMiddleName;
      retVal.subjectLastName = this.subjectLastName;
      retVal.subjectDateOfBirth = this.subjectDateOfBirth;

      return retVal;
    }

    static KTN_VERIFICATION_PENDING:string = 'PENDING';
    static KTN_VERIFICATION_INVALID:string = 'NO_MATCH';
    static KTN_VERIFICATION_VERIFIED:string = 'MATCH';
    static KTN_VERIFICATION_SENT:string = 'SENT';

    knownTravelerNumber:string;
    verificationStatus: string;    
    hasTsaKTN:string;
    subjectFirstName:string;
    subjectMiddleName:string;
    subjectLastName:string;
    subjectDateOfBirth:string;

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof TsaPreData){
                this.knownTravelerNumber = jsonObject.knownTravelerNumber;
                this.verificationStatus = jsonObject.verificationStatus;
                this.hasTsaKTN = jsonObject.hasTsaKTN;
                this.subjectFirstName = jsonObject.subjectFirstName;
                this.subjectMiddleName = jsonObject.subjectMiddleName;
                this.subjectLastName = jsonObject.subjectLastName;
                this.subjectDateOfBirth = jsonObject.subjectDateOfBirth;
            }
            else{
                if (jsonObject.hasOwnProperty('knownTravelerNumber')) {
                    this.knownTravelerNumber = jsonObject.knownTravelerNumber;
                }
                if (jsonObject.hasOwnProperty('verificationStatus')) {
                    this.verificationStatus = jsonObject.verificationStatus;
                }

                if (jsonObject.hasOwnProperty('hasTsaKTN')){
                    this.hasTsaKTN = jsonObject.hasTsaKTN;
                }
                if (jsonObject.hasOwnProperty('subjectFirstName')){
                    this.subjectFirstName = jsonObject.subjectFirstName;
                }
                if (jsonObject.hasOwnProperty('subjectMiddleName')){
                    this.subjectMiddleName = jsonObject.subjectMiddleName;
                }
                if (jsonObject.hasOwnProperty('subjectLastName')){
                    this.subjectLastName = jsonObject.subjectLastName;
                }
                if (jsonObject.hasOwnProperty('subjectDateOfBirth')){
                    this.subjectDateOfBirth = jsonObject.subjectDateOfBirth;
                }
            }            
        }
    }
}