import { Component, Input, ViewChild, DoCheck, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GoesDate } from '../../common/models/date.model';
import Utils from '../../common/utils/utils';


@Component({
    selector: 'date-selection',
    templateUrl: 'date-selection.component.html',
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DateSelectionComponent, multi: true }],
    standalone: false
})

export class DateSelectionComponent implements ControlValueAccessor, DoCheck, OnChanges {

    @ViewChild('dateForm') dateForm : NgForm;
    date: GoesDate = new GoesDate();
    dateString : string;
    invalidFormat : boolean;
    maxError : boolean;
    minError : boolean;
    parentSubmitted : boolean;

    @Input() errorLabel : string;
    @Input() label : string;
    @Input() required : boolean;
    @Input() name : string;
    @Input() toolText : string;
    @Input() minDate : string;
    @Input() maxDate : string;
    @Input() domId : string;
    @Input() disabled : boolean;

    months : any = [];

    constructor(private translate: TranslateService){}

    ngDoCheck() {
        // get months translation
        this.translate.get('GENERAL_REUSABLE.MONTHS').subscribe((res: any) => {
            if (res === 'GENERAL_REUSABLE.MONTHS') {
                // For unit tests. TODO: Figure out how to properly
                // mock this from the spec file.
                this.months = [{
                    "value": "01", "label": "01-Jan"
                }, {
                    "value": "02", "label": "02-Feb"
                }, {
                    "value": "03", "label": "03-Mar"
                }, {
                    "value": "04", "label": "04-Apr"
                }, {
                    "value": "05", "label": "05-May"
                }, {
                    "value": "06", "label": "06-Jun"
                }, {
                    "value": "07", "label": "07-Jul"
                }, {
                    "value": "08", "label": "08-Aug"
                }, {
                    "value": "09", "label": "09-Sep"
                }, {
                    "value": "10", "label": "10-Oct"
                }, {
                    "value": "11", "label": "11-Nov"
                }, {
                    "value": "12", "label": "12-Dec"
                }];
            } else {
                this.months = res;
            }
        });
    }

    writeValue( value : any){
        this.date = value;
    }
    propagateChange = (_: any) => {};

    registerOnTouched(fn: any) {
        this.propagateChange = fn;
    }

    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    ngOnChanges(inputs : any){
        this.propagateChange(this.date);
    }

    onDayBlur() {
        if (this.date.day && this.date.day.length == 1) {
            this.date.day = '0' + this.date.day;
        }
        this.isValid();
    }

    isValid(bypassRequired? : boolean) : boolean {

        let result:boolean = false;

        if(this.dateForm.valid){
            if(this.date.month && this.date.year && this.date.day){

                result = Utils.isValidDateInput(this.date.getSimpleString());
                if (!result){
                    this.invalidFormat = true;
                    return false;
                }
                else{
                    this.invalidFormat = false;
                }

                if(this.minDate){
                    result = Utils.isDateAfter(this.date.getSimpleString(), this.minDate);
                    if (!result){
                        this.minError = true;
                    }
                    else {
                        this.minError = false;
                    }

                }
                if(this.maxDate) {
                    result = Utils.isDateBefore(this.date.getSimpleString(), this.maxDate);
                    if (!result){
                        this.maxError = true;
                    }
                    else {
                        this.maxError = false;
                    }
                }
                if(this.maxError || this.minError) return false;
            }
            else {

                if(!bypassRequired) {
                    this.invalidFormat = false;
                    return false;
                }
            }
        }
        else {
            if(!bypassRequired) {
                this.invalidFormat = false;
                return false;
            }
        }
        return true;
    }
}
