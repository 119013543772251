<div id="printInfo" class="modal modal-xl fade" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title w-100 text-center" [innerHTML]="title | translate"></h4>
            <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="clearValues()"><span aria-hidden="true">&times;</span></button>
        </div>
        <div class="modal-body">
            <div id="printModalBody">
                <div class="hidden-print">
                    <a href="javascript:void(0)" alt="print this" (click)="printModal()"><i class="fa fa-print"></i> Print</a>
                </div>

                <div *ngIf="applicationTitle">
                    <h2 class="print-header"><b>Application for:</b>{{applicationTitle}}</h2>
                    <h2 class="print-header"><b>Version:</b>{{appStatusStr}}</h2>
                </div>
        
                <div>
                    <h2 class="print-header"><b>Time of Printout: </b>{{currentDayStr}}</h2>
                </div>
                <!-- DHS Logo and Application Logo -->
                <div class="header">
                    <div class="logo-ttp-main">
                    <img alt="{{'IMAGE_ALT.DHS_SEAL' | translate}}" src="assets/images/logos/dhs-seal.png">
                    <span class="cbp">U.S. Customs and<br> Border Protection</span> 
                    <div class="ttp">
                        Official Trusted Traveler Program Website | Department of Homeland Security
                    </div>
                    </div>
                </div>

                <p [innerHTML]="content"></p>
            </div>
        </div>
        <div class="modal-footer justify-content-center">
              <div class="col-md-4">
              <button id="printConfirmBtn" type="button" class="btn d-block w-100 btn-primary" data-bs-dismiss="modal" translate>MODAL.BUTTON_OK</button>
            </div>
        </div>
      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal --> 
