import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from '../services/error-handler.service';

import { SurveyDetail } from '../models/survey-detail.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class SystemConfigService {

    surveyDetail: SurveyDetail;

    constructor(private http: HttpClient,
                private errorHandler: ErrorHandlerService) {

    }

    public initData(): Promise<SurveyDetail | Observable<any>> {
        const uri: string = environment.uriEndpoint + environment.apiVersion + 'goesapp/config/survey';

        // return  this.http.get(uri)
        //     .toPromise().then((res:any) => {
        //         let surveyDetail  = new SurveyDetail();
        //         if (res) {
        //             surveyDetail  = new SurveyDetail(res.json());
        //         }
        //         return surveyDetail;
        //     })
        //     .catch((error:any) => this.errorHandler.handleError(error));

        // let promise = new Promise<SurveyDetail>((resolve, reject) => {
        //     this.http.get(uri)
        //       .toPromise()
        //       .then(
        //             (res:any) => {
        //                 let surveyDetail  = new SurveyDetail();
        //                 if (res) {

        //                     surveyDetail  = new SurveyDetail(res.json());
        //                 }
        //                 resolve(surveyDetail);
        //             },
        //         msg => { // Error

        //             this.errorHandler.handleError(msg);
        //             reject(msg);
        //         }
        //       );
        //   });
        const promise = new Promise<any>((resolve, reject) => {
            this.http.get(uri).pipe(
            ).toPromise()
            .catch( reject )
            .then(
                res => {
                        let surveyDetail  = new SurveyDetail();
                        if (res) {

                            surveyDetail  = new SurveyDetail(res);
                        }
                        resolve(surveyDetail);

                }
            );
        });

        return promise;

    }
}
