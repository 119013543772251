import { Component, OnInit } from '@angular/core';
import { ModalService } from '../modal.service';
import Utils from 'src/app/common/utils/utils';
import { DomSanitizer } from '@angular/platform-browser';

declare const $: any;

/**
 * This class represents the notify (service version of info) modal component
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'print-modal',
    templateUrl: 'print-modal.component.html',
    standalone: false
})

export class PrintModalComponent implements OnInit {
    private confirmBtn: HTMLElement;
    title: string;
    content: any;
    applicationTitle: string;
    buttonText: string;

    appStatusStr: string;
    currentDayStr: string;

    sanitzer: DomSanitizer;

    constructor(modalService: ModalService, sanitzer: DomSanitizer) {
        this.sanitzer = sanitzer;
        modalService.print = this.print.bind(this);
    }

    ngOnInit() {
        this.confirmBtn = document.getElementById('printConfirmBtn');
    }
    print(modalTitle: string, content?: string, applicationTitle?: string, submitted ?: boolean): Promise<boolean> {
        this.title = modalTitle;
        this.applicationTitle = applicationTitle;
        this.buttonText = 'Button Text';
        const todayTemp = new Date();
        this.currentDayStr = Utils.getTodayStr() + ' ' + Utils.extractNonMilTimeFromDate(todayTemp);

        if (!content) {
            const printArea = window.document.getElementById('print-area');
            content = printArea.outerHTML;
            content = content.replace(/<a /g, '<span ');
            content = content.replace(/<\/a>/g, '</span>');
        }
        this.content = this.sanitzer.bypassSecurityTrustHtml(content);

        if (applicationTitle) {
            this.applicationTitle = applicationTitle;
            // create the title
            let appStatusStr = 'Draft';
            if (submitted) {
                appStatusStr = 'Submitted';
            }
            this.appStatusStr = appStatusStr;
        }

        $('#printInfo').modal('show');

        const promise = new Promise < boolean > (resolve => {
            this._configurePrintModal(resolve);
        });
        return promise;
    }

    printModal() {
        $('#printModalBody').clone().appendTo('#printArea');
        $('go-app').addClass('screen');
        window.print();

        $('#printArea').empty();
        $('go-app').removeClass('screen');
    }

    clearValues() {
        this.currentDayStr = '';
        this.applicationTitle = '';
        this.content = '';
        this.appStatusStr = '';
        this.title = '';
    }

    private _configurePrintModal(resolve: (x: boolean) => any) {
        const confirmed = (e: any) => resolve(true);
        this.confirmBtn.onclick = ((e: any) => {
            e.preventDefault();
            confirmed(e);
            this.clearValues();

            $('#printInfo').modal('hide');
        });
    }

}
