import { Component } from '@angular/core';
import { ModalService } from '../modal.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const $: any;

/**
 * This class represents the information modal component
 */
@Component({
    selector: 'alert-modal',
    templateUrl: 'alert-modal.component.html',
    standalone: false
})

export class AlertModalComponent {

	translateSrc: string;
	params:any = { value: '' };

	constructor(modalService: ModalService) {
		// modalService.alert = this.alert.bind(this);
		modalService.alert$.subscribe(data => this.alert(data.key, data.params));
	}

	// todo: at times this is being set with an error string, not a translation key
	alert(msgKey: string, params?: any): void {
		this.translateSrc = msgKey;
		this.params = params;
		$('#alertModal').modal('show');
	}

}
