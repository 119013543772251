export const SETTINGS = {
    emailPattern : /^(([^<>()\[\]\\.,;:\s@\x22]+(\.[^<>()\[\]\\.,;:\s@\x22]+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    fastIdPattern : /^(4110|4270|8400|3958)([0-9]{10})$/,
    passIdPattern : /^[0-9]{9}$/,
    twNationalIdPattern : /^([A-Z]{1})([1-2]{1})([0-9]{8})$/,
    twCriminalRecordNumberPattern : /^((?![LRSY])[A-Z])([0-9]{2})_([0-9]{3})(0[1-9]|1[0-2])((0[1-9])|(1[0-9])|(2[0-9])|(3[0-1]))_([A-C0-9]{7})$/,
    textInputPattern :  /^(?:(?![\!\@\#\$\%\^\*\(\)\_\+\=\{\}\|\[\]\:\;\<\>\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/,
    oneAlphaInputPattern :  /^(?=.*[A-Za-z])(?:(?![\!\@\#\$\%\^\*\(\)\_\+\=\{\}\|\[\]\:\;\<\>\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/,
    alphabeticPattern : /[A-Za-z]/,
    numberPattern : /^[0-9]*$/,
    numberHyphenPattern : /^[0-9 \/-]+$/,
    vehicleMakePattern : /^(?:(?![\!\@\#\$\%\^\*\(\)\_\+\=\{\}\|\[\]\:\;\<\>\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/,
    millitaryCityPattern : /^[FfAaDd][Pp][Oo] *$/,
    alphanumericPattern : /^[0-9A-Za-z]*$/,
    docNumPattern: /^[ETXYZAFGHJKLMNPRW0-9{1}][0-9]{8}$/,
    poBoxPattern : /[Pp][. ]*[Oo][. ]*[Bb][Oo][Xx][\w]*.*|[Pp][Oo][Ss][Tt][\s]+[Oo][Ff]{2}[Ii][Cc][Ee][\s]+[Bb][Oo][Xx][\w]*.*$/,
    TOKEN_NAME : 'ttp_token',
    LOGINGOV_TOKEN_NAME: 'rentry_token',
    PILOT_COUNTRIES:  ['MX', 'AR', 'CO', 'GB', 'QA', 'SA', 'SG', 'TW', 'IL', 'BR'],
    baseReturnService: 'ttp-external',
    schedulerReturnService: 'ttp-external-scheduler',
    useStaticTestAPIData: false, // Change to true to test with JSON
    staticTestAPIRoot: '/dist/dev/assets/readonly-test-api/',
    REMOTE_LOCATION_ID: 999999999,
    REQUESTED_INFO_COUNTRIES: ['MX', 'AR', 'CO', 'GB', 'QA', 'SA', 'SG', 'TW', 'IL', 'BR', 'DO', 'AE'],
    US_PASSPORT_PATTERN: /^[etxyzafghjklmnprwETXYZAFGHJKLMNPRW0-9{1}][0-9]{8}$/,
    BR_PASSPORT_PATTERN: /^[A-Za-z]{2}[0-9]{6}$/,
    UK_PASSPORT_PATTERN: /^[0-9]{9}$/,
    CA_FORMAT: /(^[0-9A-Za-z]{8}$)|(^[A-Za-z]{1}[0-9]{6}[A-Za-z]{2}$)/,
    UP_MIN_AGE: 14,
    MIN_AGE_LEGAL_GUARDIAN_NOT_NEEDED: 18
};
