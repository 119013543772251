export class UnitedKingdomRequestedInfo {
    UKAccessCode: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof UnitedKingdomRequestedInfo) {
                this.UKAccessCode = jsonObject.UKAccessCode;
            } else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) =>{
                        if(property.name === "UK GET CODE"){
                            this.UKAccessCode = property.value;
                        }
                    });
                }
            }
        }
    }
}
