export class UnitedArabEmiratesRequestedInfo {    
    idNumber: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof UnitedArabEmiratesRequestedInfo) {               
                this.idNumber = jsonObject.idNumber;
            }  else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) => {
                        if (property.name === "EMIRATES ID NUMBER") {
                            this.idNumber = property.value;
                        }
                    });
                }              
            }
        }
    }
}
