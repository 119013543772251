
import { Component, AfterViewInit, DoCheck, Input, Output, EventEmitter } from '@angular/core';
import { TimelineService } from './timeline.service';
import { tDate } from './tDate';
import { tEvent } from './tEvent';

declare var $:any;

/**
 * This class represents the timeline component.
 */
@Component({
    selector: 'timeline',
    templateUrl: 'timeline.component.html',
    standalone: false
})

export class TimelineComponent implements DoCheck {

	@Input('start')
	ts:tDate;

	@Input('end')
	te:tDate;

	@Input('caption')
	caption: string;

	@Input('events')
	events: tEvent[];

	hasGap: boolean = false;

	private _width: number;

	constructor(private timelineService: TimelineService){}

	ngDoCheck() {
		this._setup();
		$(window).on('resize', function(){
			try {
				this._setup();
			} catch(e) {}
		});
	}

    private _setup() {
		var timeline = document.getElementById('timeline');
	    var tdims= timeline.getBoundingClientRect();
	    var w =  $('.timeline').width();
	    var tpointsFinal = this.timelineService.createTimelinePoints(this.events, this.ts, this.te, w, tdims);
        this.hasGap = this.timelineService.hasGap(tpointsFinal);

		if(timeline){
            timeline.innerHTML = "";
            for(var p of tpointsFinal){
                var elem = this._createDiv(p);
                timeline.appendChild(elem);
            }
        } 
	}

	private _createDiv(p: any): Node {
		var elem = document.createElement('div');
		if(p.o == 'f'){
			elem.innerHTML = '<i class="fa fa-check"></i>';
			elem.style.color = 'white';
			elem.style.backgroundColor = '#257C31';
			elem.style.border = '3px solid #006837';
		}
		if(p.o == 'g') {
            if((p.e - p.s) > 60){
			 elem.innerHTML = '<i class="fa fa-exclamation-triangle"></i>';   
            }
            else{
                elem.innerHTML = '<i class="fa fa-exclamation-triangle"></i>';   
            }
			elem.style.backgroundColor = '#D04A02';
			elem.style.border = '1px solid #F15A24';
			elem.style.overflow = 'hidden';
			elem.style.color = 'white';
		}
		// elem.style.position = 'absolute';
		elem.style.textAlign = 'center"';
		elem.className = 'filled';
		elem.style.left = p.s + 'px';
		elem.style.width = p.e - p.s + 'px';
		elem.style.display = 'inline-block';
		elem.style.height = '35px';
		elem.style.paddingTop = '5px';
		elem.style.left = p.s;
		return elem;
	}
}
