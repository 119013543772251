
<!-- // FOOTER -->

<span id="back-top" style="display: inline;">
  <a (click)="sc()" (keydown)="sc()" tabindex="0" class="ng-binding">
    <span class="fa fa-angle-up"></span>
    {{ 'GENERAL_REUSABLE.TOP' | translate }}
    <!--Top-->
  </a>
</span>
<footer>
  <div class="container">
  <div class="row">
    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
      <h5 translate>FOOTER.CONTACT_SUPPORT</h5>
      <div class="row">
        <div class="col-xl-6 col-lg-4 col-md-6 col-12">
          <h6 translate>FOOTER.CBP_SUPPORT</h6>
          <ul class="button-less">
            <li [innerHTML]=" 'FOOTER.CBP_INFO_CENTER' | translate"></li>
            </ul>
        </div>
        <div class="col-xl-5 col-lg-4 col-md-6 col-12">
          <h6 translate>TSA SUPPORT</h6>
          <ul class="button-less">
            <li [innerHTML]=" 'FOOTER.TSA_CONTACT_CENTER' | translate"></li>
          </ul>

          <!--<h6 translate>FOOTER.CALL_HOURS</h6>
              <ul class="button-less">
                <li>{{'FOOTER.TOLL_FREE' | translate}}: <strong><a href="tel:855-873-4637" title="{{'FOOTER.LINK_TITLE_DOMESTIC_NUMBER' | translate}}">855-USE-GOES</a></strong> (873-4637)</li>
                <li>{{'FOOTER.OUTSIDE_US' | translate}}: <strong><a href="tel:202-325-8060" title="{{'FOOTER.LINK_TITLE_INTERNATIONAL_NUMBER' | translate}}">202-325-8060</a></strong></li>
              </ul>-->
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-lg-4 col-md-6 col-12">
          <ul class="button-less">
             <li><a href="" data-bs-toggle="modal" data-bs-target="#mdl-need-help" title="{{'FOOTER.NEED_HELP_LINK_TITLE' | translate}}"
                translate>FOOTER.NEED_HELP</a></li>
            </ul>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6 col-12">
          <ul class="button-less">
            <li [innerHTML]=" 'FOOTER.TSA_PRE_FAQS' | translate"></li>
          </ul>
        </div>
      </div>
      <div class="row">
      <div class="col-xl-10 col-lg-4 col-md-6 col-12">
           
          <ul class="button-less">
            <li><span class="tsa-span"><a href="https://www.tsa.gov/precheck/credit-cards-offer" target="_blank" rel="noopener noreferrer"
                [innerHTML]="'Credit Cards and Loyalty Programs Featuring TSA PreCheck<sup>&reg;</sup>' | translate"></a></span>
                </li>
          </ul>

          <!--<h6 translate>FOOTER.CALL_HOURS</h6>
              <ul class="button-less">
                <li>{{'FOOTER.TOLL_FREE' | translate}}: <strong><a href="tel:855-873-4637" title="{{'FOOTER.LINK_TITLE_DOMESTIC_NUMBER' | translate}}">855-USE-GOES</a></strong> (873-4637)</li>
                <li>{{'FOOTER.OUTSIDE_US' | translate}}: <strong><a href="tel:202-325-8060" title="{{'FOOTER.LINK_TITLE_INTERNATIONAL_NUMBER' | translate}}">202-325-8060</a></strong></li>
              </ul>-->
        </div>
      </div>
  </div>

      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <h5 translate>FOOTER.SECURE_TRANSACTIONS</h5>
        <h6 translate>FOOTER.ACCEPTED_PAYMENT_METHODS</h6>
        <ul class="pay-methods">
          <li><img alt="{{'IMAGE_ALT.VISA_CARD_LOGO' | translate}}" src="assets/images/icons/icon-visa.png"></li>
          <li><img alt="{{'IMAGE_ALT.MASTERCARD_LOGO' | translate}}" src="assets/images/icons/icon-mastercard.png"></li>
          <li><img alt="{{'IMAGE_ALT.DISCOVER_CARD_LOGO' | translate}}" src="assets/images/icons/icon-discover.png"></li>
          <li><img alt="A{{'IMAGE_ALT.AMERICAN_EXPRESS_LOGO' | translate}}" src="assets/images/icons/icon-amex.png"></li>
        </ul>
        <br><br>
        <a href="" data-bs-toggle="modal" data-bs-target="#mdl-full-payment-list" title="{{'FOOTER.PAYMENT_OPTIONS_TITLE' | translate}}" translate>FOOTER.SEE_FULL_LIST</a>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <h5 translate>FOOTER.RESOURCES</h5>
        <ul class="resource-list">
          <li><a href="https://www.dhs.gov/" title="{{'FOOTER.LINK_TITLE_DEPT_HOMELAND_SECURITY' | translate}}" target="_blank" rel="noopener noreferrer">DHS.gov</a> ({{'FOOTER.DEPT_HOMELAND_SECURITY' | translate}})</li>
          <li><a href="https://www.cbp.gov/" title="{{'FOOTER.LINK_TO_CBP_TITLE' | translate}}" target="_blank" rel="noopener noreferrer">CBP.gov</a> ({{'FOOTER.CUSTOMS_AND_BORDER_PROTECTION' | translate}})</li>
          <li><a href="https://www.tsa.gov/"  target="_blank" rel="noopener noreferrer">TSA.gov</a> (Transportation Security Administration)</li>
          <li><a href="https://www.cbp.gov/site-policy-notices/accessibility" target="_blank" rel="noopener noreferrer" title="{{'FOOTER.ACCESSIBILITY' | translate}}" translate>FOOTER.ACCESSIBILITY</a></li>
          <!-- <li><a href="" data-bs-toggle="modal" data-bs-target="#mdl-privacy" title="{{'FOOTER.PRIVACY_ACT' | translate}}" translate>FOOTER.PRIVACY_ACT</a></li> -->
          <li><a tabindex="0" title="{{FOOTER.PRIVACY_ACT' | translate}}"  href="/privacy-act" target="_blank" rel="noopener noreferrer" translate>FOOTER.PRIVACY_ACT</a></li>
          <li><a href="https://www.cbp.gov/document/directives/vulnerability-disclosure-program-policy-and-rules-engagement" target="_blank" rel="noopener noreferrer" title="{{'FOOTER.VULNERABILITY_DISCLOSURE' | translate}}" translate>FOOTER.VULNERABILITY_DISCLOSURE</a></li>
        </ul>
      </div>

    </div>

  </div>
</footer>

<need-help-modal [id]="'mdl-need-help'"></need-help-modal>

<info-modal 
  [id]="'mdl-full-payment-list'" 
  [title]="'MODAL.FULL_PAYMENT_LIST_TITLE'"
  [buttonText]="'MODAL.BUTTON_CLOSE'"            
  [translate-src]="'MODAL.FULL_PAYMENT_LIST'"></info-modal>

<info-modal 
  [id]="'mdl-privacy'"
  [title]="'MODAL.PRIVACY_STATEMENT_TITLE'"
  [buttonText]="'MODAL.BUTTON_CLOSE'"            
  [translate-src]="'MODAL.PRIVACY_STATEMENT'"></info-modal>

<!-- // PAPERWORK REDUCTION ACT NOTICE -->
<div class="paperwork-reduction">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p [innerHTML]=paperWorkMsg></p>
      </div>
    </div>
  </div>
</div>