export class SingaporeRequestedInfo {
    NRIC: string = '';
    promoCode: string = '';
    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof SingaporeRequestedInfo) {
                this.NRIC = jsonObject.NRIC;
                this.promoCode = jsonObject.promoCode;
            } else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) =>{
                        if(property.name === "SINGAPORE NATIONAL REGISTRATION ID"){
                            this.NRIC = property.value;
                        }
                    });
                }
                if(jsonObject.hasOwnProperty('promotionCode')){
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
