import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Subject ,  Observable ,  Subscription, of, timer } from 'rxjs';
import {catchError, map, share, switchMap} from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorHandlerService } from './error-handler.service';
import { TranslateService } from '@ngx-translate/core';
import Utils from '../utils/utils';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthHttpService } from './auth-http.service';
import { ModalService} from '../../shared/modals/modal.service';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { environment } from '../../../environments/environment';
import { SETTINGS } from '../..//app.constants';
import { nanoid } from 'nanoid';

declare var $: any;

@Injectable()
export class AuthService {

    // tslint:disable-next-line:variable-name
    private _authorizedUserSource = new Subject<User>();
    // tslint:disable-next-line:variable-name
    private _user: User;
    // tslint:disable-next-line:variable-name
    private _activeTimer: Subscription;

    // observable streams
    authorizedUser$ = this._authorizedUserSource.asObservable();
    token = '';
    logoutParams = '';

    tokenExpiration: Date;

    constructor(
        private http: HttpClient,
        private authHttp: AuthHttpService,
        private errorHandler: ErrorHandlerService,
        private translate: TranslateService,
        private router: Router,
        private modalService: ModalService,
        private spinner: SpinnerService,
        public jwtHelper: JwtHelperService) {

        this._user = new User();
        this.authHttp.tokenInvalidatedSubj.subscribe((value) => {
            if (value) {
                this.tokenInvalidatedHandler();
            }
        });
        this.authHttp.tokenUpdatedSubj.subscribe(updated => {
            if (updated){
                this.resetSessionTimout();
            }
        });
        this.authHttp.tokenExpDateSubj.subscribe(updated => {
            if (updated) {
                this.tokenExpiration = updated;
                this.resetSessionTimout();
            }
        });
    }

    setUser(u: User){
        this._authorizedUserSource.next(u);
        this._user = u;
    }

    getUser(): User {
        return this._user;
    }

    public logOut(tokenName?: string) {
        if (environment.authenticateWithCookie) {
            this.logoutWithCookie();
        } else {
            this.logOutWithToken(tokenName);
        }
    }

    public logOutWithToken(tokenName?: string) {
        window.onbeforeunload = undefined;
        this.token = '';

        let TOKEN_KEY = SETTINGS.TOKEN_NAME;
        let tempToken = '';
        if (tokenName) {
            TOKEN_KEY = tokenName;
        }
        tempToken = localStorage.getItem(TOKEN_KEY);

        localStorage.removeItem(TOKEN_KEY);
        this.setUser(new User());
        if (this._activeTimer) {
          this._activeTimer.unsubscribe(); // stop the session timeout
        }

        if (tempToken) {
            const claims = this.jwtHelper.decodeToken(tempToken);

            // If hte logingov_id_token exist, attempt to logout of login.gov
            // TODO Currently login gov does not handle an expired id_token correctly so only attempt to logout
            // if the token is not expired.
            if (claims && claims.logingov_id_token) {
                if (!this.jwtHelper.isTokenExpired(claims.logingov_id_token)) {
                    this.getLogoutURL(tempToken).toPromise().then(res => {
                        if (res) {
                            const data = res;

                            // console.log('Redirecting .......', data.redirectUrl);
                            Utils.gotoExternalUrl(data.redirectUrl);
                        } else {
                            this.router.navigate(['/']);
                        }
                    });
                }
            } else {
                this.router.navigate(['/']);
            }
        }
        else {
            this.router.navigate(['/']);
        }
    }

    public logoutWithCookie() {
        window.onbeforeunload = undefined;

        // Cleanup
        localStorage.removeItem('state');

        // check if logged in to determine if to get login.gov logout url
        let logoutLoginGov = false;
        if (this.isUserLoggedIn()) {
            logoutLoginGov = true;
        }

        this.tokenExpiration = null;
        this.setUser(new User());
        if (this._activeTimer) {
          this._activeTimer.unsubscribe(); // stop the session timeout
        }

        if (logoutLoginGov) {
            this.getLogoutURL(null).toPromise().then(res => {
                if (res && res.redirectUrl) {
                    Utils.gotoExternalUrl(res.redirectUrl);
                } else {
                    this.router.navigate(['/']);
                }
            });
        } else {
            this.router.navigate(['/']);
        }
    }

    /**
     * Logs the user out by removing the JWT from local storage (Logout call to Login.gov if token not expired) :
     * 	1. Sets the logout logout paramters if the user's login.gov token is not expired.
     * 	2. Redirects the user to redirectUrl if available. If not to the home page.
     *
     * TODO: Is it still necessary to logout of Login.gov since the prompt the parameter forces the user to login?
     *
     * @param logoutParams - query params that will be sent to Login.gov .
     * @param redirectUrl - URL to send the user to after logging out .
     */
    public logOutAndRedirect(logoutParams: string, redirectUrl?: string) {
        window.onbeforeunload = undefined;
        this.token = '';

        localStorage.removeItem('state');

        const TOKEN_KEY = SETTINGS.TOKEN_NAME;
        const tempToken = localStorage.getItem(TOKEN_KEY);

        localStorage.removeItem(TOKEN_KEY);
        this.setUser(new User());
        if (this._activeTimer) {
          this._activeTimer.unsubscribe(); // stop the session timeout
        }

        if (tempToken) {
            const claims = this.jwtHelper.decodeToken(tempToken);

            // If the logingov_id_token exist, attempt to logout of login.gov
            // TODO Currently login gov does not handle an expired id_token correctly so only attempt to logout
            // if the token is not expired.
            if (claims && claims.logingov_id_token) {
                if (!this.jwtHelper.isTokenExpired(claims.logingov_id_token)) {

                    this.getLogoutURL(tempToken, logoutParams).toPromise().then(res => {
                        if (res) {
                            const data = res;

                            // console.log('Redirecting .......', data.redirectUrl);
                            Utils.gotoExternalUrl(data.redirectUrl);
                        } else {
                            this.router.navigate(['/']);
                        }
                    });
                }
            } else {
                if (redirectUrl) {
                    // console.log('Redirecting to.', redirectUrl);
                    Utils.gotoExternalUrl(redirectUrl);
                } else {
                    this.router.navigate(['/']);
                }
            }
        }
        else {
            if (redirectUrl) {
                // console.log('Redirecting to.', redirectUrl);
                Utils.gotoExternalUrl(redirectUrl);
            } else {
                this.router.navigate(['/']);
            }
        }
    }

    /**
     * Redirects the browser to login.gov so the user to enter credentials.
     * NOTE: token is deleted before redirect.
     *
     * @param {*} [overrideWindow]
     * @returns {Observable<any>}
     * @memberof AuthService
     */
    public logIn(overrideWindow?: any): Observable<any> {

        // Clean up old tokens before logging in
        localStorage.removeItem(SETTINGS.TOKEN_NAME);
        localStorage.removeItem(SETTINGS.LOGINGOV_TOKEN_NAME);

        const state = nanoid(32);

        // Save state to localstorage
        localStorage.setItem('state', this.translate.currentLang + ':' + state);

        if (!overrideWindow) {
            overrideWindow = window;
        }
        Utils.gotoExternalUrl(`${environment.uriCSEndpoint}credential/${environment.apiVersion}login?app=${environment.serviceProviderName}&lang=${this.translate.currentLang}&state=${state}`, overrideWindow);
        return of(false);
    }

    /**
     * Re-logs in the user if cookie or token available. Checks cookie or token based on environment variable.
     *
     * This should only be called when returning from extenal site (ex. Payment, scheduler, CBSA)
     *
     * @param {*} [overrideWindow]
     * @returns {Observable<any>}
     */
    public reLogin(overrideWindow?: any): Observable<any> {
        if (environment.authenticateWithCookie) {
            return this.reLoginWithCookie(overrideWindow);
        } else {
            return this.reLoginWithToken(overrideWindow);
        }
    }

    /**
     * Checks local storage for a token if valid, loads the user info.
     *
     * This should only be called when returning from extenal site (ex. Payment, scheduler)
     *
     * @param {*} [overrideWindow]
     * @returns {Observable<any>}
     * @memberof AuthService
     */
    private reLoginWithToken(overrideWindow?: any): Observable<any> {
        const token = localStorage.getItem(SETTINGS.TOKEN_NAME);
        if (token && !this.jwtHelper.isTokenExpired()) {
            this.startTimeoutWarningTimer();
            if (this._user && this._user.userId) {
                Utils.enableSiteLeaveWarning();
                this.enableDeleteOnClose();
                return of(this._user);
            }
            else {
                const uuid = this.getUserUUIDFromToken(token);
                const email = '';

                return this.loadUser(token, uuid, email).pipe(
                    map(res => {
                        if (res) {
                            return of(this._user);
                        }
                        else {
                            return of(false);
                        }
                }));
            }
        } else {
            // ????? Should login or remove token
            // return this.logIn(overrideWindow);
            this.tokenInvalidatedHandler();
            return of(false);
        }
    }

    /**
     * Calls the refresh endpoint to refresh the cookie and retrieve the expiration.
     *
     * This should only be called when returning from extenal site (ex. Payment, scheduler)
     *
     * @param {*} [overrideWindow]
     * @returns {Observable<any>}
     * @memberof AuthService
     */
    private reLoginWithCookie(overrideWindow?: any): Observable<any> {
        return this.authHttp.refreshAndUpdateToken().pipe(switchMap(res => {
            if (res) {
                // this.startTimeoutWarningTimer();

                return this.loadUser('', '', '').pipe(
                    map(loadRes => {
                        if (loadRes) {
                            return of(this._user);
                        }
                        else {
                            return of(false);
                        }
                }));

            } else {
                return of(false);
            }
        }));
    }

    public hasUserLoggedIn(): boolean {
        if (this._user.userId) {
            return true;
        }
        else {
            return false;
        }
    }

    /* setup the user based on login.gov provided data and check to see
     * if a user profile exists in TTP */
    public loadUser(token: string, uuid: string, email: string): Observable<any> {

        Utils.enableSiteLeaveWarning();
        this.enableDeleteOnClose();
        this._user.userId = uuid;
        this._user.username = email || uuid;
        this._user.email = email;
        this.token = token;

        if (token) {
            localStorage.setItem(SETTINGS.TOKEN_NAME, token);
        }
        return this.getUserInfo(this._user).pipe(
            map(res => {
                if (res) {
                    const user = new User();

                    user.userId = res.userId;
                    user.username = email ||  res.emailAddress || res.userId;
                    user.email = res.emailAddress || email;
                    user.dateOfBirth = res.dateOfBirth;
                    user.name = res.firstName + ' ' + res.lastName;
                    user.name = user.name.replace('undefined', '').trim();
                    user.userId = user.userId.replace('undefined', '').trim();
                    user.membershipOrPassId = res.membershipId || '';
                    user.loginGovEmail = res.loginGovEmail;
                    user.firstName = res.firstName;
                    user.lastName = res.lastName;
                    user.age = res.age;

                    this.setUser(user);

                    return of(user);
                }
                // else {
                // 	return of(false);
                // }
            })
        );
    }

    /**
     * If using cookies:
     *  - TODO add variable to indicate user logged in or token was refreshed
     *  - Ensure token not expired
     * If using local storage
     *  - Check if token is not expired and the user object is loaded.
     * @return {boolean} [description]
     *
     * TODO set token expiration with local storage as well
     */
    isUserLoggedIn(): boolean {
        if (environment.authenticateWithCookie) {
            if (this.tokenExpiration && (this.tokenExpiration > new Date())) {
                return true;
            } else {
                return false;
            }
        } else {
            try {
                const token = localStorage.getItem(SETTINGS.TOKEN_NAME);

                if (this.token && token && !this.jwtHelper.isTokenExpired() && this._user.userId) {
                    return true;
                }
                else {
                    return false;
                }
            } catch (e) {
                console.log('Error getting token : ', e);
                localStorage.removeItem(SETTINGS.TOKEN_NAME);
                return false;
            }
        }
    }

    /* Request a a token from the credential sevice (with the state and code) */
    requestToken(code: string, state: string): Observable<any> {

        let requestURI = '';
        if (environment.authenticateWithCookie) {
            requestURI = environment.uriCSEndpoint + 'credential/v2/token';
        } else {
            requestURI = environment.uriCSEndpoint + 'credential/v1/token';
        }

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        const tokenRequest = {
          state,
          code,
            app : environment.serviceProviderName
        };

        const body = this.serializeObj(tokenRequest);

        return this.http.post(requestURI, body, {headers}).pipe(
            map((res: any) => {
                if (environment.authenticateWithCookie) {
                    // Should get back the expiration so update
                    this.authHttp.tokenExpDateSubj.next(new Date(res.expiration));
                    // this.tokenExpiration = new Date(res.expiration);
                }
                return res;
            }),
            catchError(error => this.errorHandler.handleError(error)));
    }

    public createRandomNonce(strLength: number): string {

            // Prepend the state with the current lang
        let text = this.translate.currentLang + ':';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < strLength; i++) {
              text += possible.charAt(Math.floor(Math.random() * possible.length));
            }

        return text;
    }

    tokenInvalidatedHandler() {
        this.logOut();
        this.spinner.hide();
        Utils.scrollUp();
        this.modalService.notify('SESSION.EXPIRED', 'SESSION.SESSION', 'MODAL.BUTTON_OK');
    }

    // tslint:disable-next-line:variable-name
    public requestBypassToken(dev_uuid: string, email?: string): Observable<any> {

        let requestURI = '';
        if (environment.authenticateWithCookie) {
            requestURI = environment.uriCSEndpoint + 'credential/v2/bypass';
        } else {
            requestURI = environment.uriCSEndpoint + 'credential/' + environment.apiVersion + 'bypass';
        }

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');

        const tokenRequest = {
            dev_uuid,
            email: email ? email : ''
        };

        const body = this.serializeObj(tokenRequest);

        return this.http.post(requestURI, body, {headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })}).pipe(
            map((res: any) => {
                if (environment.authenticateWithCookie) {
                    this.authHttp.tokenExpDateSubj.next(new Date(res.expiration));
                    // this.tokenExpiration = new Date(res.expiration); // TODO this has be set
                }
                return res;
            }),
            catchError(error => this.errorHandler.handleError(error)));

    }

    public getLogoutURL(token?: string, redirectParams?: string): Observable<any> {
        // Need the logingov_id_token to logout from login gov
        const state = nanoid(32);

        // Save state to localstorage; pre-pend with language
        localStorage.setItem('state', this.translate.currentLang + ':' + state);

        let logoutURL = '';

        if (environment.authenticateWithCookie) {
            logoutURL = environment.uriCSEndpoint + 'credential/v2/logout';

        } else {
            logoutURL = environment.uriCSEndpoint + 'credential/' + environment.apiVersion + 'logout';
        }

        const logoutRequest = {
            app: environment.serviceProviderName,
            lang: this.translate.currentLang,
            state,
            token
        };

        const body = this.serializeObj(logoutRequest);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post(logoutURL, body, { headers }).pipe(
            map((res: any) => {
                localStorage.removeItem('state');
                return res;
            }),
            // Dont need to show user an error on logout
            catchError(error => {
                console.log('Logout error code : ' + error.status);

                return of(false);
            }));
    }

    /* check to see if a user profile exists, if so return complete user object
     * i.e. with name and date of birth etc. */
    private getUserInfo(u: User): Observable<any> {
        let requestURI = '';
        if (environment.authenticateWithCookie) {
            requestURI = environment.uriEndpoint + 'v1/goesapp/login';
        } else {
            requestURI = environment.uriEndpoint + 'v1/goesapp/login/' + u.userId;
        }

        return this.http.get(requestURI).pipe(
            map((res: Response) => {
                // localStorage.setItem('token', this.token);
                // localStorage.setItem('uuid', res.json().userId);
                return res;
            }),
            catchError(error => {
                this.errorHandler.handleError(error);
                this.logOut();
                return of(false);
            }));
    }

    private serializeObj(obj: any) {
        const result: any = [];
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {
                result.push(encodeURIComponent(property) + '=' + encodeURIComponent(obj[property]));
            }
        }

        return result.join('&');
    }

    private getUserUUIDFromToken(token: string): string {
        const claims = this.jwtHelper.decodeToken(token);
        return claims.sub;
    }

    /*
     * Destroys the active timer and starts a new one
     */
    private resetSessionTimout(){
        if (this._activeTimer) {
            this._activeTimer.unsubscribe();
        }
        this.startTimeoutWarningTimer();
    }

    /*
     * Starts a timer that will display a warning to the user, letting them know that
     * their session is about to expire and whether they want more time.
     */
    public startTimeoutWarningTimer() {
        const tokenExpireDate = this.getTokenExpirationDate();

        const now = new Date().getTime();
        const timeToExpire = (tokenExpireDate.getTime() - now) - 120000;
        const isActiveTimer = timer(timeToExpire);
        this._activeTimer = isActiveTimer.subscribe(x => {

            // start a countdown from 60 seconds
            const countDownDate = new Date();
            countDownDate.setSeconds(countDownDate.getSeconds() + 60);
            const serviceRef = this;
            const y = setInterval(() => {
                const now2 = new Date().getTime();
                const distance = countDownDate.getTime() - now2;
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                const modal = document.getElementById('confirmModal');
                const body = modal.getElementsByClassName('modal-body')[0];
                const pTag = body.getElementsByTagName('p')[0];
                pTag.innerHTML = serviceRef.getSessionTimeoutMsg(seconds);
                if (distance <= 0) {
                  clearInterval(y);
                  $('#confirmModal').modal('hide');
                  serviceRef.modalService.notify('SESSION.EXPIRED', 'Session', 'MODAL.BUTTON_OK');
                  serviceRef.logOut();
                }
            } , 1000, serviceRef);

            this.modalService.confirm(this.getSessionTimeoutMsg(),
                'Warning', 'GENERAL_REUSABLE.NO_BUTTON', 'GENERAL_REUSABLE.YES_BUTTON').then(confirmed => {
                clearInterval(y);
                if (confirmed){
                    // refresh token
                    this.authHttp.refreshAndUpdateToken().subscribe(refreshed => {
                        if (refreshed){
                            this.startTimeoutWarningTimer();
                        } else {
                            // something went wrong and the token was not refreshed, so log out the user
                            this.logOut();
                        }
                    });
                } else {
                    this.logOut();
                }
            });

        });
    }

    /*
     * Returns a session time out message in user's preferred language
     * If language translation is not found, then defaults to English
     */
    private getSessionTimeoutMsg(seconds?: number): string {
        if (seconds){
            switch (this.translate.currentLang){
                case 'fr':
                    return 'Votre session est sur le point d\'expirer. Avez-vous besoin de plus de temps? (' + seconds + ' secondes restantes)';
                case 'es':
                    return 'Su sesión está a punto de caducar. ¿Necesita más tiempo? (' + seconds + ' segundos restantes)';
                default:
                    return 'Your session is about to expire. Do you need more time? (' + seconds + ' seconds remaining)';
            }
        } else {
            switch (this.translate.currentLang){
                case 'fr':
                    return 'Votre session est sur le point d\'expirer. Avez-vous besoin de plus de temps?';
                case 'es':
                    return 'Su sesión está a punto de caducar. ¿Necesita más tiempo?';
                default:
                    return 'Your session is about to expire. Do you need more time?';
            }
        }
    }

    /**
     * Refreshes the token and then goes to external url.
     */
    public refreshTokenAndGotoExternalUrl(url: string, overrideWindow ?: any) {

        if (this.isUserLoggedIn()) {
            // refresh token before going to external site.
            this.authHttp.refreshAndUpdateToken().subscribe(refreshed => {
                if (refreshed) {
                    // For testing purpose
                    const token = localStorage.getItem(SETTINGS.TOKEN_NAME);
                    if (token) {
                        // console.log('Token found before going to external site.');
                    }
                    else {
                        // console.log('Token not found before going to external site.');
                    }
                    Utils.gotoExternalUrl(url, overrideWindow);
                } else {
                    // something went wrong and the token was not refreshed, so log out the user
                    this.logOut();
                }
            });
        }
    }

    public enableDeleteOnClose(): void {
        window.onunload = function() {
            const tempToken = localStorage.getItem(SETTINGS.TOKEN_NAME);
            if (tempToken) {
                localStorage.removeItem(SETTINGS.TOKEN_NAME);
                localStorage.setItem(SETTINGS.LOGINGOV_TOKEN_NAME, tempToken);
            }
        };
    }

    public getReentryToken(): string {
        return localStorage.getItem(SETTINGS.LOGINGOV_TOKEN_NAME);
    }

    /**
     * Clears out user 
     */
    public lightLogout() {
        window.onbeforeunload = undefined;

        this.token = '';
        this.tokenExpiration = null;
        this.setUser(new User());
        if (this._activeTimer) {
          this._activeTimer.unsubscribe(); // stop the session timeout
        }
        this.router.navigate(['/']);

        this.spinner.hide();
        Utils.scrollUp();
        this.modalService.notify('SESSION.MULTIPLE_SESSION', 'SESSION.MULTIPLE_SESSION_HEADER', 'MODAL.BUTTON_OK');
    }

    /**
     * If authentication is done with cookie, the token expiration date should be set for token and refresh calls.
     *
     * Otherwise get it from local storage.
     *
     * @returns token expiration date
     */
    private getTokenExpirationDate(): Date {
        if (environment.authenticateWithCookie) {
            return this.tokenExpiration;
        } else {
            const token = localStorage.getItem(SETTINGS.TOKEN_NAME);
            const tokenExpireDate = this.jwtHelper.getTokenExpirationDate(token);
            return tokenExpireDate;
        }
    }

}
