<style>
	.timeline {
		width: 100%;
		text-align: center;
		overflow: hidden;
		height: 35px;
	}
	
	.timeline-container {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.filled {	
		background-color: #257C31;
		height: 35px;
	}
</style>

<div class="container-fluid timeline-container">
 
	<div id="timeline" class="timeline d-flex"></div>
	<div class="row">
		<div class="col-2">{{ ts.m + "/" + ts.y }}</div>
		<div class="col-8 d-none d-sm-block text-center"><i translate>{{caption}}</i></div>
		<div class="col-2 offset-sm-7 offset-md-0 text-end">{{ te.m + "/" + te.y }}</div>
		<div class="col-12 d-sm-block d-md-none" translate>{{caption }}</div>
	</div>

</div>