import { ApplicationMetadata } from "./application-metadata.model";
import { Membership } from "./membership.model";
import { Reconsideration } from "./reconsideration.model";
import { EnhancedModel } from './enhanced.model';

export class DashboardMetadata  extends EnhancedModel {
    //If there is an application in progress this comes from GOES, otherwise GES
    countryOfPermanentResidenceny :string = '';
    gesCountryOfPermanentResidenceny :string = '';
    hasDocuments : boolean = false;
    hasVehicleDecal : boolean = false;
    passId : string = "";
    applications : Array<ApplicationMetadata> = new Array<ApplicationMetadata>();
    cards : [{
        program : string;
        status : string;
    }];
    memberships : Array<Membership> = new Array<Membership>();
    reconsiderations : Array<Reconsideration> = new Array<Reconsideration>();
    reconEligibleApps: Array<ApplicationMetadata> = new Array<ApplicationMetadata>();

    abtcCard : {
        expirationDate : string;
        fullDurationExpirationDate : string;
        allowedAction: string;
        canRenew: boolean;
        canReplace: boolean;
    };


    //If there is an application in progress this comes from GOES, otherwise GES
    primaryCitizenship : {
      citizenship : string;
      hasPassport : boolean;
    };
    gesPrimaryCitizenship : {
      citizenship : string;
      hasPassport : boolean;
    };

    oneTimeMessages : Array<Array<string>>;
    canApplyForFAST : boolean	= false;
  	canApplyForTTP : boolean	 	= false;
  	canApplyForPP : boolean		= false;
  	canApplyForAPEC : boolean	= false;
  	canUpdateMailingAddress : boolean = false;
  	canUpdateDocuments : boolean	= false;
  	canManageVehicles	: boolean = false;
    hasPendingAppSubmittals: boolean = false;

    constructor(obj:any) {
        super();
        if (obj instanceof DashboardMetadata) {
            this.mapClone(obj);
        }
        else if(obj instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(obj);
        }
        else {
            throw new Error("You must specify the correct parameter");
        }
    }

    public clone() {
        let cloned:DashboardMetadata = new DashboardMetadata(this);
        return cloned;
    }

    protected mapClone(dashboard:DashboardMetadata) {
        this.abtcCard = dashboard.abtcCard;
        this.applications = dashboard.applications;
        this.cards = dashboard.cards;
        this.countryOfPermanentResidenceny = dashboard.countryOfPermanentResidenceny;
        this.hasDocuments = dashboard.hasDocuments;
        this.hasVehicleDecal = dashboard.hasVehicleDecal;
        this.memberships = dashboard.memberships;
        this.primaryCitizenship = dashboard.primaryCitizenship;
        this.passId = dashboard.passId;
        this.reconsiderations=dashboard.reconsiderations;

        this.canApplyForFAST = dashboard.canApplyForFAST;
        this.canApplyForTTP = dashboard.canApplyForTTP;
        this.canApplyForPP = dashboard.canApplyForPP;
        this.canApplyForAPEC = dashboard.canApplyForAPEC;
        this.canUpdateMailingAddress = dashboard.canUpdateMailingAddress;
        this.canUpdateDocuments = dashboard.canUpdateDocuments;
        this.canManageVehicles	= dashboard.canManageVehicles;
        this.hasPendingAppSubmittals	= dashboard.hasPendingAppSubmittals;
    }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('abtcCard')) {
          this.abtcCard = jsonObject.abtcCard;
        }
        if (jsonObject.hasOwnProperty('applications')) {
          jsonObject.applications.forEach((item:any) => {
              let application = new ApplicationMetadata(item);
              this.applications.push(application);
          });
        }
        if (jsonObject.hasOwnProperty('cards')) {
          this.cards = jsonObject.cards;
        }
        if (jsonObject.hasOwnProperty('countryOfPermanentResidenceny')) {
          this.countryOfPermanentResidenceny = jsonObject.countryOfPermanentResidenceny;
        }
        if (jsonObject.hasOwnProperty('gesCountryOfPermanentResidenceny')) {
          this.gesCountryOfPermanentResidenceny = jsonObject.gesCountryOfPermanentResidenceny;
        }
        if (jsonObject.hasOwnProperty('hasDocuments')) {
          this.hasDocuments = jsonObject.hasDocuments;
        }
        if (jsonObject.hasOwnProperty('hasVehicleDecal')) {
          this.hasVehicleDecal = jsonObject.hasVehicleDecal;
        }
        if (jsonObject.hasOwnProperty('passId')) {
          this.passId = jsonObject.passId;
        }

        if (jsonObject.hasOwnProperty('memberships')) {
            jsonObject.memberships.forEach((item:any) => {
                let membership = new Membership(item);
                this.memberships.push(membership);
            });
        }
        if (jsonObject.hasOwnProperty('primaryCitizenship')) {
          this.primaryCitizenship = jsonObject.primaryCitizenship;
        }

        if (jsonObject.hasOwnProperty('gesPrimaryCitizenship')) {
          this.gesPrimaryCitizenship = jsonObject.gesPrimaryCitizenship;
        }

        if (jsonObject.hasOwnProperty('canApplyForFAST')) {
          this.canApplyForFAST = jsonObject.canApplyForFAST;
        }
        if (jsonObject.hasOwnProperty('canApplyForTTP')) {
          this.canApplyForTTP = jsonObject.canApplyForTTP;
        }
        if (jsonObject.hasOwnProperty('canApplyForPP')) {
          this.canApplyForPP = jsonObject.canApplyForPP;
        }
        if (jsonObject.hasOwnProperty('canApplyForAPEC')) {
          this.canApplyForAPEC = jsonObject.canApplyForAPEC;
        }
        if (jsonObject.hasOwnProperty('canUpdateMailingAddress')) {
          this.canUpdateMailingAddress = jsonObject.canUpdateMailingAddress;
        }
        if (jsonObject.hasOwnProperty('canUpdateDocuments')) {
          this.canUpdateDocuments = jsonObject.canUpdateDocuments;
        }
        if (jsonObject.hasOwnProperty('canManageVehicles')) {
          this.canManageVehicles = jsonObject.canManageVehicles;
        }
        if (jsonObject.hasOwnProperty('oneTimeMessages')) {
          this.oneTimeMessages = jsonObject.oneTimeMessages;
        }
        if (jsonObject.hasOwnProperty('hasPendingAppSubmittals')) {
          this.hasPendingAppSubmittals = jsonObject.hasPendingAppSubmittals;
        }
        if (jsonObject.hasOwnProperty('reconsiderations')) {
            jsonObject.reconsiderations.forEach((item:any) => {
               let reconsideration = new Reconsideration(item);
               this.reconsiderations.push(reconsideration);
          });

        }
        if (jsonObject.hasOwnProperty('reconEligibleApps')) {
          jsonObject.reconEligibleApps.forEach((item:any) => {
            let application = new ApplicationMetadata(item);
            this.reconEligibleApps.push(application);
          });
        }
    }
}
