import {Directive, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { TranslateReferenceService } from '../../common/services/translate-reference.service';

@Directive({
    selector: '[stateEligibiltiy]',
    providers: [{ provide: NG_VALIDATORS, useExisting: StateEligValidatorDirective, multi: true }],
    standalone: false
})

export class StateEligValidatorDirective implements Validator {

    @Input() addressType : string;

    constructor(
		private referenceService : TranslateReferenceService,
    ) {}

    validate(control: AbstractControl): {[key: string]: any} {

        if (this.addressType === 'C' || this.addressType === 'M') {

            const stateCode = control.value;
            const state = this.referenceService.getRef('states')["US"].find((s:any) => s.code === stateCode);
    
            if (state && !state.ttpEligible) {
                return {'stateEligibility' : true};
            } 
        }

        return null;
    }
}
