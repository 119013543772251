import { ProgramInfo } from './program-info.model';

export class Program {

    public static get NEXUS_CODE():string {
        return "NH";
    }
    public static get GLOBAL_ENTRY_CODE():string {
        return "UP";
    }
    public static get SENTRI_CODE():string {
        return "SH";
    }
    public static get KOREA_SMART_ENTRY_SERVICE_CODE():string {
        return "SE";
    }
    public static get US_CANADA_FAST_CODE():string {
        return  "FN";
    }
    public static get US_MEXICO_FAST_CODE():string {
        return "FS";
    }

    public static get TSA_PRECHECK_CODE():string {
        return "TSA";
    }

    selectedProgram : ProgramInfo = new ProgramInfo();
    apec : boolean = false;
    promoCode: string = "";
    ukAccessCode : string = "";

    constructor(){ }

}
