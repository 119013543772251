export enum StepStatus {
	Stay = 3,
	Valid = 2,
	MissingRequiredData = 1,
	Invalid = 0
}

export enum ProgressIndicator {
	Untouched = 0,
	Incomplete = 1,
	Complete = 2
}

export enum ViewAppUrlIndicator {
	default = 0, 							// This would be or applications located in GOES (status <= PI)
	acceptedByGES = 1,				// Applications already submitted and accepted by GES(not PR)
	submittedToGES = 2				// Pending Review application
}

/* this version of typescript only supports number based enums
 * so using a static class for string based enums instead */
export class ProgramCodes {
	static GlobalEntry = "UP";
	static FastNorth = "FN";
	static FastSouth = "FS";
	static Nexus = "NH";
	static Sentri = "SH";
	static APEC = "AB";
	static KoreaSmartEntryService = "SE";
}

export class RequestType {
    static abtcRequestTypes = ["AE", "AN", "AR", "AC"];
}

export class PostalCode {
	static USPattern = /^[0-9]{5}$|^[0-9]{9}$/;
	static CAPattern = /([A-Z][0-9]){3}$/;
}

export class ProgramType {
	static TTP = "TTP";
	static FAST = "FAST";
}
