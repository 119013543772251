import { Country } from "./country.model";

export class TravelHistory {

    haveYouTraveled: string;
    countries: Country[];

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof TravelHistory) {
                this.haveYouTraveled = jsonObject.haveYouTraveled;
                this.countries = jsonObject.countries;
            } else {
                // this is a json object let's deserialize it
                if(jsonObject.hasOwnProperty('visitedCountriesResponse')){
                  this.haveYouTraveled = jsonObject.visitedCountriesResponse;
                }
                if (jsonObject.hasOwnProperty('visitedCountries')) {
                    if (jsonObject.visitedCountries !== null) {
                        if (jsonObject.visitedCountries.length > 0) {
                            this.countries = new Array<Country>();
                            jsonObject.visitedCountries.forEach((countryISOCode: string) => {
                                let country = new Country();
                                country.isoCountryCode = countryISOCode;
                                this.countries.push(country);
                            });
                        } else {
                            this.countries = new Array<Country>();
                        }
                    } else {
                        this.countries = new Array<Country>();
                    }
                } else {
                    this.countries = new Array<Country>();
                }
            }
        }
    }

}
