import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  Subject, of } from 'rxjs';

import { Country } from '../models/country.model';
import { ErrorHandlerService } from '../services/error-handler.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from './log.service';
import { ModalService } from '../../shared/modals/modal.service';
import { LanguageDataHash } from '../models/translate-reference-data/language-data-hash.interface';
import { ReferenceDataModel } from '../models/translate-reference-data/reference-data.model';
import { CodeNameHash } from '../models/translate-reference-data/code-name-hash.interface';
import { CodeName } from '../models/translate-reference-data/code-name.model';
import { VisaClass } from '../models/visa-class.model';

import Utils from '../../common/utils/utils';
import { environment } from '../../../environments/environment';
import { SETTINGS } from './../../app.constants';

declare var $: any;

@Injectable()
export class TranslateReferenceService {

    // tslint:disable-next-line:variable-name
    private _data: LanguageDataHash = {};
    // tslint:disable-next-line:variable-name
    private _currentReferenceData: ReferenceDataModel;
    // tslint:disable-next-line:variable-name
    private _sysPulling = false;

    // this can be used to wait on reference data translations
    // to completely load before doing anything
    translationSwitchedSubj: Subject<boolean>;

    constructor(private http: HttpClient,
                private translateService: TranslateService,
                private errorHandler: ErrorHandlerService,
                private modalService: ModalService,
                private logService: LogService) {

        this.translationSwitchedSubj = new Subject<boolean>();

        // if language selection is changed, fetch relevant reference data.
        this.translateService.onLangChange.subscribe((event: any) => {
           this.onTranslationChange();
        });
    }

    /*
     * Return the reference data
     */
    pullData(): Observable<ReferenceDataModel> {
        if(this._currentReferenceData) {
            // return cache
            return of(this._currentReferenceData);
        } else {
            this.logService.error('TranslateReferenceService: reference data was not initialized (initData)');
            return of(null);
        }
     }

    getRef(path: string): any {
        if (this._currentReferenceData){
            return path.split('.').reduce(function(prev, curr){
                    return prev ? prev[curr] : undefined;
                }, this._currentReferenceData.srcData || self);
        }
        return null;
    }

    getCountries(): Country[] {
        return $.extend(true, [], this._currentReferenceData.countryList);
    }

    getCountryByIsoCode(isoCode: string): Country {
        // let country:Country = $.extend(true, {}, this._currentReferenceData.getCountryByIsoCode(isoCode));
        const country: Country =  new Country(this._currentReferenceData.getCountryByIsoCode(isoCode));
        return country;
    }

    getBirthCountries(): Country[] {
        const countries: Country[] = $.extend(true, [], this._currentReferenceData.birthCountryList);
        return countries;
        // return this.countryList.filter(country => country.isForBirth == true)
        // return this.birthCountryList;
    }

    getBirthStates(): Array<any> {
        const states: Array<any> = $.extend(true, [], this._currentReferenceData.birthStates);
        return states;
    }

    getCardReplacementCodes(): Array<any> {
        const replacementCodes: Array<any> = $.extend(true, [], this._currentReferenceData.cardReplacementCodes);
        return replacementCodes;
    }

    getCitCountries(): Country[] {
        const countries: Country[] = $.extend(true, [], this._currentReferenceData.citizenshipCountryList);
        return countries;
        // return this.countryList.filter(country => country.isForCitizenship == true);
        // return this.citCountryList;
    }

    getAddressCountries(): Country[] {
        const countries: Country[] = $.extend(true, [], this._currentReferenceData.addressCountryList);
        return countries;
        // return this.countryList.filter(country => country.isForCurrentAddress);
        // return this.countryList;
    }

    getVisaClasses(): VisaClass[] {
        return this._currentReferenceData.visaClasses;
    }

    // todo: make stronger type return
    getData(): any {
        if(!this.translateService.currentLang) {
            throw new Error('The current language has not been set. You must set this first before getting the ReferenceDataModel');
        }
        if(!this._currentReferenceData) {
            throw new Error('The reference data for this language is not available. Did you call the service yet?');
        }
        return this._currentReferenceData.srcData;
    }

    getApplicationStatus(code: string): CodeName {
        const statuses: CodeNameHash = this._currentReferenceData.applicationStatuses;
        const status: CodeName = statuses[code];
        return status;
    }

    getProgramName(code: string): string {
        try {
            const program = this._currentReferenceData.programInfo[code];
            return program.programName;
        } catch (e) {
            this.logService.warning('TranslateReference getProgramName could not find programInfo with ' + code);
        }
        return code;
    }

    getApplicationType(code: string): string {
        // if the code is a GES code, then we may need to map it to a TTP code
        code = this.mapGESCodeToTTP(code);

        if (this._data) {
            const types: any = this._currentReferenceData.applicationTypes;
            for (let type of types) {
                if (type['code'] === code) {
                    return type['name'];
                }
            }
        }
        return code;
    }

    /*
     * APP_INITIALIZER requires a function to return a promise.. Observables do not work.
     * To ensure the reference data is loaded before the app initializes, this will be used.
     */
    initData(): Promise<ReferenceDataModel> {

        this._sysPulling = true;

        if (!this.translateService.currentLang) {
            const navigator = window.navigator as any;
            this.translateService.use(Utils.getPreferredLanguage(navigator.languages));

            // // todo: this really should happen higher up in a bootstrap section
            // this.translateService.use('en');
        }

        // if(this._data[this.translateService.currentLang]) {
        //     throw new Error('TranslateReferenceService, you should not call initData twice, use pullData instead');
        // }

        let uri: string;
        if (SETTINGS.useStaticTestAPIData) {
            uri = SETTINGS.staticTestAPIRoot + 'reference-data.json';
        } else {
            uri = environment.uriEndpoint + environment.apiVersion + 'goesapp/reference/data?lang=' + this.translateService.currentLang;
        }

        const promise = new Promise<any>((resolve, reject) => {
            this.http.get(uri).pipe()
            .toPromise()
            .catch( reject )
            .then(
                res => {
                    console.log('Got reference data');
                    this._data[this.translateService.currentLang] = new ReferenceDataModel(res);
                    this._currentReferenceData = this._data[this.translateService.currentLang];
                    this._sysPulling = false;
                    this.translationSwitchedSubj.next(true);

                    resolve(this._currentReferenceData);
                }
            );
        });

        return promise;
    }

    /*
    * Try to map the code over to a TTP code and return the TTP code
    * If no mapping exists, then simply return the code
    */
    private mapGESCodeToTTP(code: string): string{
        switch (code){
            case 'IV': return 'IE';
            case 'CC': return 'CR';
            default: return code;
        }
    }

    /*
     * Reinitialize the reference data when language changes AND
     * reference data for that language is not available
     */
    private onTranslationChange(): void {
        if (!this._data[this.translateService.currentLang] && !this._sysPulling) {
            this.initData();
        } else {
            this._currentReferenceData = this._data[this.translateService.currentLang];
            this.translationSwitchedSubj.next(true);
        }
    }

}
