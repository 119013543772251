import { tDate } from './tDate';

export class tEvent {
	s: tDate;
	e: tDate;

	/* s and e must be in the following format: mm/yyyy */
	constructor(s:tDate, e:tDate){
		this.s = s;
		this.e = e;
	}

}