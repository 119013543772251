import { Injectable } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class ModalService{

    alert$: Observable<any>;
    // alert: (msgKey: string) => void;
    confirm: (msgKey: string, titleKey?:string, noKey?:string, yesKey?:string) => Promise<boolean>;
    notify: (msgKey: string, title : string, buttonText : string) => Promise<boolean>;

    print: (modalTitle: string, content?: string, applicationTitle?: string, submitted ?: boolean) => Promise<boolean>;

    private _alertObserver: Observer<any>;

    constructor() {
        this.alert$ = new Observable<any>(
            (observer: any) => this._alertObserver = observer).pipe(share());
    }

    alert (msgKey: string, params?: any) {
        if (this._alertObserver) {
            this._alertObserver.next({ key: msgKey, params: params });
        }
    }

}
