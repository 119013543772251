export class DominicanRepublicRequestedInfo {    
    cedNumber: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof DominicanRepublicRequestedInfo) {               
                this.cedNumber = jsonObject.cedNumber;
            }  else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) => {
                        if (property.name === "DOMINICAN CEDULA NUMBER") {
                            this.cedNumber = property.value;
                        }
                    });
                }              
            }
        }
    }
}
