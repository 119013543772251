import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlerService } from '../services/error-handler.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AnalyticsService {

    constructor(private http: HttpClient,
                private errorHandler: ErrorHandlerService) {

    }

    /**
     * Makes a call to server with best match choices.
     *
     * @param uuid
     */
    public logBestMatch(bestMatch: string, selected: string, citizenship: string) {
        let bestMatchSelected = bestMatch === selected ? 'true' : 'false';
        let uri:string = environment.uriEndpoint + environment.apiVersion + 'goesapp/analytics?' +
            'bestMatch=' + bestMatch +
            '&selected=' + selected +
            '&bestMatchSelected=' + bestMatchSelected +
            '&citizenship=' + citizenship;

        // We don't care about the result
        return  this.http.get(uri).pipe(
            map((res:Response) => {
            return res;
        }),
        catchError((error:any) => {
            // Log error to console
            // console.log("Unable to log best match");
            return of(false);
        }));
    }

    /**
     * User clicked the go to TSA Pre button fromt the nav bar
     */
    public logGoToTSASelected() {
        let uri:string = environment.uriEndpoint + environment.apiVersion + 'goesapp/analytics' +
            '?goToTSA=true';

        // We don't care about the result
        return  this.http.get(uri).pipe(
            map((res:Response) => {
            return res;
        }),
        catchError((error:any) => {
            return of(false);
        }));
    }

    /**
     * User clicked get started from one of the program cards
     * @param program - card selected
     */
    public logGetStarted(program: string) {
        let uri:string = environment.uriEndpoint + environment.apiVersion + 'goesapp/analytics' +
            '?getStartedProram=' + program;

        // We don't care about the result
        return  this.http.get(uri).pipe(
            map((res:Response) => {
            return res;
        }),
        catchError((error:any) => {
            return of(false);
        }));
    }

    /**
     * User just clicked the log in button on the nav bar.
     */
    public logLogin() {
        let uri:string = environment.uriEndpoint + environment.apiVersion + 'goesapp/analytics' +
            '?directLogIn=true';

        // We don't care about the result
        return  this.http.get(uri).pipe(
            map((res:Response) => {
            return res;
        }),
        catchError((error:any) => {
            return of(false);
        }));
    }

}
