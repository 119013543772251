import { Address } from './address';
import { EnhancedModel } from './enhanced.model';

export class Contact extends EnhancedModel {

    fullName: string = '';
    knownSinceMonth: number = 0;
    knownSinceYear: number = null;
    address: Address = new Address();
    phoneNumber: string = '';
    phoneExt: string = '';

    constructor(jsonObject: any = null) {
        super();
        if (jsonObject !== null) {
            if (jsonObject instanceof Contact) {
                this.mapClone(jsonObject);
            } else {
                // this is a json object let's deserialize it
                this.mapJson(jsonObject);
            }
        }
    }

    public clone():Contact {
        let cloned:Contact = new Contact(this);
        return cloned;
    }

    protected mapClone(contact:Contact){
        this.fullName = contact.fullName;
        this.knownSinceMonth = contact.knownSinceMonth;
        this.knownSinceYear = contact.knownSinceYear;
        this.address = contact.address;
        this.phoneNumber = contact.phoneNumber;
        this.phoneExt = contact.phoneExt;
    }

    protected mapJson(jsonObject: any){
        if(jsonObject.hasOwnProperty('fullName')){
            this.fullName = jsonObject.fullName;
        }
        if (jsonObject.hasOwnProperty('knownSinceMonth')) {
            this.knownSinceMonth = jsonObject.knownSinceMonth;
        }
        if (jsonObject.hasOwnProperty('knownSinceYear')) {
            this.knownSinceYear = jsonObject.knownSinceYear;
        }
        let wrapperObj : any = {
            	'street1Address': jsonObject['addressLine1'],
                'street2Address': jsonObject['addressLine2'],
                'city':jsonObject['city'],
                'state':  jsonObject['state'],
                'postalCode': jsonObject['zipCode'],
                'countryCode': jsonObject['country'],
                'addressValidationCode' : jsonObject['addressValidationCode']
        };
        this.address = new Address(wrapperObj);
        if (jsonObject.hasOwnProperty('address')) {
            this.address = new Address(jsonObject.address);
        }
        if (jsonObject.hasOwnProperty('phoneNumber')) {
            this.phoneNumber = jsonObject.phoneNumber;
        }
        if (jsonObject.hasOwnProperty('phoneExt')) {
            this.phoneExt= jsonObject.phoneExt;
        }
        if(jsonObject.hasOwnProperty('extension')) {
            this.phoneExt = jsonObject.extension;
        }

    }

}
