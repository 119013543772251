
import { AdditionalQuestion } from '../models/additional-question.model';

export class AdditionalInfo{

    questionList : AdditionalQuestion [];

    constructor(){
    	this.questionList = [];
    }
}
