import { Component, Input } from '@angular/core';

/**
 * This class represents the information modal component
 */
@Component({
    selector: 'info-modal',
    templateUrl: 'info-modal.component.html',
    standalone: false
})

export class InfoModalComponent {

	@Input('translate-src')
	translateSrc: string;

	@Input()
	id: string;

	@Input()
	title: string;

    @Input()
    buttonText : string;
}
