import { EnhancedModel } from './enhanced.model';

export class VisaClass extends EnhancedModel {
    code: string = '';
    name: string = '';

    constructor(srcData: any = null) {
        super();
        if (srcData !== null) {
            if (srcData instanceof VisaClass) {
                this.mapClone(srcData);
            } else if (srcData instanceof Object) {
                this.mapJson(srcData);
            }
        }
    }

    public clone(): VisaClass {
        let cloned: VisaClass = new VisaClass(this);
        return cloned;
    }

    protected mapClone(visaClass: VisaClass) {
        this.code = visaClass.code;
        this.name = visaClass.name;
    }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('code')) {
            this.code = jsonObject.code;
        }
        if (jsonObject.hasOwnProperty('name')) {
            this.name = jsonObject.name;
        }
    }
}
