import { Document } from './document.model';
import { Country } from './country.model';

export class CitizenshipDocuments {
    country: Country = new Country();
    documents: Document[]= new Array<Document>();

    constructor(jsonObject: any = null) {

        if (jsonObject !== null) {

            if (jsonObject instanceof CitizenshipDocuments) {
                this.country = jsonObject.country;
                this.documents = jsonObject.documents;
            } else {
                // this is a json object let's deserialize it

                this.country = new Country();
                if (jsonObject.hasOwnProperty('countryIsoCode')) {
                    this.country.isoCountryCode = jsonObject.countryIsoCode;
                }
                this.documents = new Array<Document>();
                if (jsonObject.hasOwnProperty('documents')) {
                    if (jsonObject.documents !== null) {
                        jsonObject.documents.forEach((document: any) => {
                            this.documents.push(new Document(document));
                        });
                    }
                }
            }
        } else {
            this.country = new Country();
            this.documents = new Array<Document>();
        }
    }

}
