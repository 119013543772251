import {Directive, Input, ElementRef, HostListener } from '@angular/core';
import { SETTINGS } from '../../app.constants';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[value-type]',
    standalone: false
})

// tslint:disable-next-line:directive-class-suffix
export class ValueType {

  constructor(private el: ElementRef) { }

  regexStr: any = '^[0-9]*$';

  @Input('value-type') ValueType: string;

  @HostListener('keydown', ['$event']) onKeyDown(event: Event) {

    const e = event as KeyboardEvent;
    if (this.ValueType) {
      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'a' || e.key === 'A')) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'c' || e.key === 'C')) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'x' || e.key === 'X')) ||
        // Allow: Ctrl+V
        ((e.key === 'v'  || e.key === 'V') && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'v' || e.key === 'V')) ||

        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return;
      }

      let ch = (e.key || String.fromCharCode(e.keyCode));
      if (ch === 'Spacebar') { ch = ' '; } // for IE

      switch (this.ValueType){
        case 'numeric': this.regexStr = '^[0-9]*$'; break;
        case 'alpha': this.regexStr = '^[a-zA-Z ]*$'; break;
        case 'alphanospace': this.regexStr = '^[a-zA-Z]*$'; break;
        case 'alphanumeric':  this.regexStr = '^[a-zA-Z0-9]*$'; break;
        case 'alphanumericspacehyphen':  this.regexStr = '^[a-zA-Z0-9 \-]*$'; break;
        case 'alphanumericspace':  this.regexStr = '^[a-zA-Z0-9 ]*$'; break;
        case 'alphanumericSpcAposPerComHyp':  this.regexStr = '^[a-zA-Z0-9 \'\.\,\-]*$'; break;
        case 'textPattern' :
          this.regexStr = /^(?:(?![\!\@\#\$\%\>\^\*\\\(\)\_\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
          break;
        case 'goesIdPattern' :
          this.regexStr = /^(?:(?![\!\#\$\%\>\^\*\\\(\)\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
          break;
        case 'vehicleMakePattern' : this.regexStr = SETTINGS.vehicleMakePattern; break;

        default : this.regexStr = this.ValueType; break;
      }

      const regEx =  new RegExp(this.regexStr);
      if (regEx.test(ch)) {
        return;
      }
      else {
        e.preventDefault();
      }
    }
  }

  @HostListener('keypress', ['$event']) onKeyPress(event: Event) {

    const e = event as KeyboardEvent;
    if (this.ValueType) {
      if ([46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'a' || e.key === 'A')) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'c' || e.key === 'C')) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'x' || e.key === 'X')) ||
        // Allow: Ctrl+V
        ((e.key === 'v'  || e.key === 'V') && e.ctrlKey === true) ||
        (e.metaKey && (e.key === 'v' || e.key === 'V')) ||
      
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
          // let it happen, don't do anything
          return;
      }

      let ch = (e.key || String.fromCharCode(e.keyCode));
      if (ch === 'Spacebar') {
        ch = ' '; // for IE
      }

      switch (this.ValueType){
        case 'numeric': this.regexStr = '^[0-9]*$'; break;
        case 'alpha': this.regexStr = '^[a-zA-Z ]*$'; break;
        case 'alphanospace': this.regexStr = '^[a-zA-Z]*$'; break;
        case 'alphanumeric':  this.regexStr = '^[a-zA-Z0-9]*$'; break;
        case 'alphanumericspace':  this.regexStr = '^[a-zA-Z0-9 ]*$'; break;
        case 'alphanumericspacehyphen':  this.regexStr = '^[a-zA-Z0-9 \-]*$'; break;
        case 'alphanumericSpcAposPerComHyp':  this.regexStr = '^[a-zA-Z0-9 \'\.\,\-]*$'; break;
        case 'textPattern' :
          this.regexStr = /^(?:(?![\!\@\#\$\%\>\^\*\\\(\)\_\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
          break;
        case 'goesIdPattern' :
          this.regexStr = /^(?:(?![\!\#\$\%\>\^\*\\\(\)\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
          break;
        case 'vehicleMakePattern' : this.regexStr = SETTINGS.vehicleMakePattern; break;
        default : this.regexStr = this.ValueType; break;
      }

      const regEx =  new RegExp(this.regexStr);
      if (regEx.test(ch)) {
        return;
      } else {
        e.preventDefault();
      }
    }
  }


    @HostListener('paste', ['$event']) paste(e: Event) {
      const ev = e as KeyboardEvent;
        // Get pasted data via clipboard API
        if (this.ValueType) {

          switch (this.ValueType){
            case 'numeric': this.regexStr = '^[0-9]*$'; break;
            case 'alpha': this.regexStr = '^[a-zA-Z ]*$'; break;
            case 'alphanospace': this.regexStr = '^[a-zA-Z]*$'; break;
            case 'alphanumeric':  this.regexStr = '^[a-zA-Z0-9]*$'; break;
            case 'alphanumericspacehyphen':  this.regexStr = '^[a-zA-Z0-9 \-]*$'; break;
            case 'alphanumericspace':  this.regexStr = '^[a-zA-Z0-9 ]*$'; break;
            case 'alphanumericSpcAposPerComHyp':  this.regexStr = '^[a-zA-Z0-9 \'\.\,\-]*$'; break;
            case 'textPattern' :
              this.regexStr = /^(?:(?![\!\@\#\$\%\>\^\*\\\(\)\_\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
              break;
            case 'goesIdPattern' :
              this.regexStr = /^(?:(?![\!\#\$\%\>\^\*\\\(\)\+\=\{\}\|\[\]\:\;\<\?\/\~])[\u0020-\u007f\u00a1-\u00ff])*$/;
              break;
            case 'vehicleMakePattern' : this.regexStr = SETTINGS.vehicleMakePattern; break;
            default : this.regexStr = this.ValueType; break;
          }
          const clipboardData = (e as any).clipboardData || (window as any).clipboardData;
          const pastedData = clipboardData.getData('Text');

          // this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '');

          const regEx =  new RegExp(this.regexStr);
          if (regEx.test(pastedData)) {
                return;
            }
            else {
                e.preventDefault();
            }
        }
    }

    // @HostListener('input', ['$event']) onInput(event: Event) {
    //   // this.el.nativeElement.value = this.el.nativeElement.value.replace( /[^a-zA-Z0-9 ]/gm, '');
    //   this.el.nativeElement.value = this.el.nativeElement.value.replace( /[^\u0000-\u00ff]*$/gm, '');
    // }

}
