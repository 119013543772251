<button #ttButton
	type="button" 
	class="btn btn-tooltip" 
	data-bs-container="body" 
	data-bs-toggle="popover"
	data-bs-trigger="hover focus" 
    data-bs-html="true"
	data-bs-placement="top"
	role="tooltip">
  <i class="fa fa-question" aria-hidden="true"></i>
</button>