import { Phone } from './phone';
import { GoesDate } from './date.model';
import { TsaPreData } from './tsa-pre-data.model';

export class Person {

    id: number;
    lastName: string;
    firstName: string;
    middleName: string;
    dob: GoesDate;
    birthCity: string;
    birthCountryCode: string;
    birthCountry: string;
    birthStateCode: string;
    birthState: string;
    email: string;
 	gender: string;
 	eyeColor: string;
 	heightType: string = 'E' ;
 	heightInches: number;
 	aliasFirstNames: string[] = [];
 	aliasLastNames: string[] = [];
 	phoneNumbers: Phone[] = [];
    hasAliases: string = '';
    hasTsaKtn: string = '';
    tsaPreKtn: string = '';
    tsaPreDtoDb: TsaPreData = null;
    lgLastName: string;
    lgFirstName: string;
    lgMiddleName: string;
    lgDateOfBirth: GoesDate;
    lgGender: string;
    lgMembershipResponse: string;
    lgTravellerProgramId: string;
    lgPassId: number;
    lgApplicationId: number;
    lgIdType: string;
    langPref : string = '';
    collectTsaKtn: string = '';
    age: number;

    // TODO: Add gaurdian to the model, see application mapper

    constructor(jsonObject: any = null) {
         if (jsonObject !== null) {
             if (jsonObject instanceof Person) {
                 this.id = jsonObject.id;
                 this.lastName = jsonObject.lastName;
                 this.firstName = jsonObject.firstName;
                 this.middleName = jsonObject.middleName;
                 this.dob = jsonObject.dob;
                 this.birthCity = jsonObject.birthCity;
                 this.birthCountry = jsonObject.birthCountry;
                 this.birthCountryCode = jsonObject.birthCountryCode;
                 this.birthStateCode = jsonObject.birthStateCode;
                 this.birthState = jsonObject.birthState;
                 this.email = jsonObject.email;
                 this.gender = jsonObject.gender;
                 this.eyeColor = jsonObject.eyeColor;
                 this.heightType = (jsonObject.heightInches) ? 'E' : '';
                 this.heightInches = jsonObject.heightInches;
                 this.aliasFirstNames = jsonObject.aliasFirstNames;
                 this.aliasLastNames = jsonObject.aliasLastNames;
                 this.phoneNumbers = jsonObject.phoneNumbers;
                 this.lgLastName = jsonObject.lgLastName;
                 this.lgFirstName = jsonObject.lgFirstName;
                 this.lgMiddleName = jsonObject.lgMiddleName;
                 this.lgDateOfBirth = jsonObject.lgDateOfBirth;
                 this.lgGender = jsonObject.lgGender;
                 this.lgMembershipResponse = jsonObject.lgMembershipResponse;
                 this.lgTravellerProgramId = jsonObject.lgTravellerProgramId;
                 this.lgPassId = jsonObject.lgPassId;
                 this.lgApplicationId = jsonObject.lgApplicationId;
                 this.lgIdType = jsonObject.lgIdType;
                 this.langPref = jsonObject.langPref;
                 this.age = jsonObject.age;

             } else {
                 // this is a json object lets deserialize it
                 if (jsonObject.hasOwnProperty('cityOfBirth')) {
                    this.birthCity = jsonObject.cityOfBirth;
                 } else {
                    this.birthCity = '';
                 }
                 if (jsonObject.hasOwnProperty('countryOfBirth')) {
                    this.birthCountry = jsonObject.countryOfBirth;
                 } else {
                     this.birthCountry = '';
                 }
                 if (jsonObject.hasOwnProperty('dateOfBirth')) {
                    this.dob = new GoesDate(null, null, null, jsonObject.dateOfBirth);
                 } else {
                     this.dob = new GoesDate();
                 }
                 if (jsonObject.hasOwnProperty('emailAddress')) {
                    this.email = jsonObject.emailAddress;
                 } else {
                     this.email = '';
                 }
                 if (jsonObject.hasOwnProperty('eyeColor')) {
                    this.eyeColor = jsonObject.eyeColor;
                 } else {
                     this.eyeColor = '';
                 }
                 if (jsonObject.hasOwnProperty('firstName')) {
                    this.firstName = jsonObject.firstName;
                 } else {
                     this.firstName = '';
                 }
                 if (jsonObject.hasOwnProperty('firstNameAliases')) {
                    if (jsonObject.firstNameAliases !== null) {
                        this.aliasFirstNames = new Array<string>();
                        jsonObject.firstNameAliases.forEach((name: string) => {
                            this.aliasFirstNames.push(name);
                        });
                    } else {
                        this.aliasFirstNames = new Array<string>();
                    }
                 } else {
                     this.aliasFirstNames = new Array<string>();
                 }
                 if (jsonObject.hasOwnProperty('gender')) {
                    this.gender = jsonObject.gender;
                 } else {
                     this.gender = '';
                 }
                 if (jsonObject.hasOwnProperty('heightInInches')) {
                    this.heightInches = Number(jsonObject.heightInInches);
                 } else {
                     this.heightInches = null;
                 }
                 if (jsonObject.hasOwnProperty('lastName')) {
                    this.lastName = jsonObject.lastName;
                 } else {
                     this.lastName = '';
                 }
                 if (jsonObject.hasOwnProperty('lastNameAliases')) {
                    if (jsonObject.lastNameAliases !== null) {
                        this.aliasLastNames = new Array<string>();
                        jsonObject.lastNameAliases.forEach((name: string) => {
                            this.aliasLastNames.push(name);
                        });
                    } else {
                        this.aliasLastNames = new Array<string>();
                    }
                 } else {
                     this.aliasLastNames = new Array<string>();
                 }
                 if (jsonObject.hasOwnProperty('middleName')) {
                    this.middleName = jsonObject.middleName;
                 } else {
                     this.middleName = '';
                 }
                 if (jsonObject.hasOwnProperty('phoneNumbers')) {
                    if (jsonObject.phoneNumbers !== null) {
                        this.phoneNumbers = new Array<Phone>();
                        jsonObject.phoneNumbers.forEach((phone: any) => {
                            this.phoneNumbers.push(phone);
                        });
                    } else {
                        this.phoneNumbers = new Array<Phone>();
                    }
                 } else {
                     this.phoneNumbers = new Array<Phone>();
                 }
                 if (jsonObject.hasOwnProperty('stateOfBirth')) {
                    this.birthState = jsonObject.stateOfBirth;
                 } else {
                     this.birthState = '';
                 }
                 if (jsonObject.hasOwnProperty('notificationLanguagePreference')) {
                     this.langPref = jsonObject.notificationLanguagePreference;
                 }

                 if (jsonObject.hasOwnProperty('otherNameResponse')) {
                     this.hasAliases = jsonObject.otherNameResponse;
                 }
                 if (jsonObject.hasOwnProperty('legalGuardian')) {
                     this.lgLastName = jsonObject.legalGuardian.lastName || '';
                     this.lgFirstName = jsonObject.legalGuardian.firstName || '';
                     this.lgMiddleName = jsonObject.legalGuardian.middleName || '';
                     if(jsonObject.legalGuardian.dateOfBirth) {
                         this.lgDateOfBirth = new GoesDate(null, null, null, jsonObject.legalGuardian.dateOfBirth);
                     } else {
                         this.lgDateOfBirth = new GoesDate();
                     }
                     this.lgGender = jsonObject.legalGuardian.gender || '';
                     this.lgMembershipResponse = jsonObject.legalGuardian.membershipResponse || '';
                     this.lgTravellerProgramId = jsonObject.legalGuardian.travellerProgramId || '';
                     this.lgPassId = jsonObject.legalGuardian.passId || '';
                     this.lgApplicationId = jsonObject.legalGuardian.applicationId || '';
                     
                    this.lgIdType = jsonObject.legalGuardian.idType;                    

                 }

                 if (jsonObject.hasOwnProperty('hasTsaKtn')) {
                    this.hasTsaKtn = jsonObject.hasTsaKtn;
                 }

                 if (jsonObject.hasOwnProperty('age')) {
                    this.age = jsonObject.age;
                 }

                 this.id = 0;
             }
         } else {
            this.id = 0;
            this.lastName = '';
            this.firstName = '';
            this.middleName = '';
            this.dob = new GoesDate();
            this.birthCity = '';
            this.birthCountry = '';
            this.birthCountryCode = '';
            this.birthStateCode = '';
            this.birthState = '';
            this.email = '';
            this.gender = '';
            this.eyeColor = '';
            this.heightType = '';
            this.heightInches = null;
            this.aliasFirstNames = new Array<string>();
            this.aliasLastNames = new Array<string>();
            this.phoneNumbers = new Array<Phone>();
            this.lgLastName = '';
            this.lgFirstName = '';
            this.lgMiddleName = '';
            this.lgDateOfBirth = new GoesDate();
            this.lgGender = '';
            this.lgMembershipResponse = '';
            this.lgTravellerProgramId = '';
            this.lgPassId = null;
            this.lgApplicationId = null;
            this.lgIdType = '';
            this.age = null;
         }

         if (!this.lgIdType || this.lgIdType.trim() ===''){
            this.lgIdType = 'A';
         }
    }
}
