import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ErrorHandlerService } from '../services/error-handler.service';

import { SurveyDetail } from '../models/survey-detail.model';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class SurveyStatsService {

    constructor(private http: HttpClient,
                private errorHandler: ErrorHandlerService) {

    }

    /**
     * Makes a call to server to indicate the user selected to do the survey.
     *
     * @param uuid
     */
    public logSurveyChoiceYes(uuid: string) {
        let uri:string = environment.uriEndpoint + environment.apiVersion + 'goesapp/survey/' + uuid + '/yes';

        // We don't care about the result
        return  this.http.get(uri).pipe(
            map((res:Response) => {
            return res;
        }),
        catchError((error:any) => this.errorHandler.handleError(error)));
    }

    /**
     * Makes a call to server to indicate the user selected to do the survey.
     *
     * @param uuid
     */
    public logSurveyChoiceNo(uuid: string) {
        let uri:string = environment.uriEndpoint + environment.apiVersion + 'goesapp/survey/' + uuid + '/no';

        // We don't care about the result
        return  this.http.get(uri).pipe(
            map((res:Response) => {
                return res;
            }),
            catchError((error:any) => this.errorHandler.handleError(error)));
    }

}
