export class MexicoRequestedInfo {
    RFC: string = '';
    promoCode : string = '';
    RFCOwnedByApplicant: string = '';
    CURP: string = '';
    PVC: number = null;
    maternalName: string = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof MexicoRequestedInfo) {
                this.promoCode = jsonObject.promoCode;
                this.CURP = jsonObject.CURP;
                this.PVC = jsonObject.PVC;
                this.RFC = jsonObject.RFC;
                this.RFCOwnedByApplicant = jsonObject.RFCOwnedByApplicant;
                this.maternalName = jsonObject.maternalName;
            }  else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('attributes') && jsonObject.attributes instanceof Array) {
                    jsonObject.attributes.forEach( (property : any) =>{
                        if(property.name === "RFC"){
                            this.RFC = property.value;
                        }
                        if(property.name === "OwnRFC"){
                            this.RFCOwnedByApplicant = property.value;
                        }
                        if(property.name === "CURP"){
                            this.CURP = property.value;
                        }
                        if(property.name === "PVC MEMBERSHIP NUMBER"){
                            this.PVC = property.value;
                        }
                        if(property.name === "MATERNAL NAME"){
                            this.maternalName = property.value;
                        }
                    });
                }
                if(jsonObject.hasOwnProperty('promotionCode')){
                    this.promoCode = jsonObject.promotionCode;
                }
            }
        }
    }
}
