import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { Application } from '../models/application.model';
import { ApplicationMinimumData } from '../models/application-minimum-data.model';
import { Country } from '../models/country.model';
import { Document } from '../models/document.model';
import { LoadApplicationRequest } from '../models/load.application.model';
import { Program } from '../models/program.model';
import { User } from '../models/user';
import { CreateApplicationRequest } from '../models/create-application.model';
import { CitizenshipDocuments } from '../models/citizenship-documents.model';

import { ErrorHandlerService } from '../services/error-handler.service';
import { LogService } from './log.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateReferenceService } from '../services/translate-reference.service';

import { Step } from '../models/step';
import ApplicationMapper from '../utils/application-mapper';
import { ProgramCodes } from '../utils/enums';
import { RequestType } from '../utils/enums';
import { ViewAppUrlIndicator } from '../utils/enums';
import Utils from '../utils/utils';
import { TimelineService, tEvent, tDate } from '../../shared/timeline/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SETTINGS } from '../../app.constants';
import { AuthService } from './auth.service';
import { TsaPreData } from '../models/tsa-pre-data.model';


@Injectable()
export class ApplicationService {

  private application: Application;
  private gesApplication: Application;
  // private questionList : AdditionalQuestion[];

  private editMode = false;
  private viewMode = false;
  private viewMembershipMode = false;

  textAlphaPattern: RegExp = SETTINGS.oneAlphaInputPattern;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private errorHandler: ErrorHandlerService,
    private refService: TranslateReferenceService,
    private timelineService: TimelineService,
    private authService : AuthService,
    private logService: LogService) {}

  resetApplication(): void {
      // todo: need minimum amount of information for creating a new application
      // this.application = new Application();
      this.logService.warning('todo: need minimum amount of information for creating a new application');
  }

  getEditMode(): boolean {
    return this.editMode;
  }
  getViewMode(): boolean {
    return this.viewMode;
  }
  getViewMembershipMode(): boolean {
    return this.viewMembershipMode;
  }
  setViewMode(bool: boolean): void {
    this.viewMode = bool;
  }
  setEditMode(bool: boolean): void {
    this.editMode = bool;
  }
  setViewMembershipMode(bool: boolean): void {
    this.viewMembershipMode = bool;
  }

  getApplication(): Application {
      // this.application.additionalInfo.questionList = this.questionData();
      return this.application;
  }

  setApplication(application: Application){
    this.application = application;
  }

  /* retrieve and set up the application */
  /* retrieve and set up the application */
  loadApplication(userId: string, loadAppRequest: LoadApplicationRequest): Observable<boolean> {

    if (SETTINGS.useStaticTestAPIData) {
      // new application for testing
      const primaryCountry: Country = this.refService.getCountryByIsoCode('US');
      const minApplicationData: ApplicationMinimumData = new ApplicationMinimumData(primaryCountry, Program.GLOBAL_ENTRY_CODE);
      this.application = new Application(minApplicationData);
      this.logService.message('Test application generated: ', this.application);
      return of(true);
    }

    if (!userId || !loadAppRequest.appId) {
      return this.errorHandler.handleError('Either user Id (' +
              userId + ') or application Id (' + loadAppRequest.appId + ') is not valid.');
    }

    const requestURI = this.getLoadApplicationUrl(userId, loadAppRequest);

    return this.http.get(requestURI).pipe(
      map((res: any) => {
        if (res) {
          const data = res;
          if (this.errorHandler.checkErrorCode(data)) {
            // no errors in retrieving application
            // ApplicationMapper.deserialize(data, this.application, this.errorHandler, this.refService.getCountries());
              // console.log("APPLICATION PRE-MAPPING: ",res.json());
              this.application = new Application(res);
              this._setCountryObjects(this.application);
              this._setVehicleRegistrationQuestions(this.application);
              this._filterCitDocuments(this.application);
              if (this.application.tsaPreData) {
                this.application.person.tsaPreDtoDb = 
                  this.application.tsaPreData.clone();
              }
              this.application.person.hasTsaKtn = this.application.tsaPreData.hasTsaKTN;
              this.application.person.tsaPreKtn = this.application.tsaPreData.knownTravelerNumber;                    
              return of(true);

          }
        }
        return null;
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }

  public getKtnAttributes(application: Application) : Observable<boolean>{
    let user : User = this.authService.getUser();

    let primaryCitizenshipLPRCountryIsoCode = application.primaryCitizenshipLPRCountryIsoCode;

    if (!application.primaryCitizenshipLPRCountryIsoCode || application.primaryCitizenshipLPRCountryIsoCode.trim()===''){
      primaryCitizenshipLPRCountryIsoCode = application.primaryCitizenshipCountryIsoCode;
    }

    let url = environment.uriEndpoint + 'v1/tsaKnownTraveler/biometricReuseService/isKTNRequired/' +
      application.id;
    
    if (user.membershipOrPassId && user.membershipOrPassId.trim() != ''){
      url += "?passId="+user.membershipOrPassId.trim();
    } 
    return this.http.get(url).pipe(
      map((res: any) => {
        
        if (res && res === true) {
          
          application.person.collectTsaKtn = 'Y'  
        }
        else {
           application.person.collectTsaKtn = 'N'
        }
        return of(true);  
      }),
      catchError(error => 
        this.errorHandler.handleError(error))
      );    
  }

  public getTsaPreData(application: Application) : Observable<void>{     
  
    let url = environment.uriEndpoint + 'v1/tsaKnownTraveler/biometricReuseService/tsaPreData/' +
      application.id;

    return this.http.get(url).pipe(
      map((res: any) => {
        if (res) {
          const tsaPreData:TsaPreData = new TsaPreData();
          tsaPreData.knownTravelerNumber = res.knownTravelerNumber;
          tsaPreData.verificationStatus = res.verificationStatus;
          
          tsaPreData.subjectFirstName = res.subjectFirstName;
          tsaPreData.subjectMiddleName = res.subjectMiddleName;
          tsaPreData.subjectLastName = res.subjectLastName;
          tsaPreData.subjectDateOfBirth = res.subjectDateOfBirth;


          application.person.tsaPreDtoDb = tsaPreData;
        }
        else {
          application.person.tsaPreDtoDb = null;
        } 
      }),
      catchError(error => 
        this.errorHandler.handleError(error))          
    );         
  }

  // set country objects for various application fields
  private _setCountryObjects(app: Application) {
    // set driver's license country
    if (app.driversLicense.countryOfIssuance.isoCountryCode){
        app.driversLicense.countryOfIssuance = this.refService.getCountryByIsoCode(app.driversLicense.countryOfIssuance.isoCountryCode);
    }

    // set address countries
    app.addresses.forEach(address => {
      if (address.country && address.country.isoCountryCode) {
          address.country = this.refService.getCountryByIsoCode(address.country.isoCountryCode);
      }
    });

    // set documents countries
    app.citizenshipDocuments.forEach(citDocs => {
      citDocs.country = this.refService.getCountryByIsoCode(citDocs.country.isoCountryCode);
      if (citDocs.country.isoCountryCode === app.primaryCitizenshipCountryIsoCode) {
        citDocs.country.isPrimary = true;
      }
    });

    // set travelhistory countries
    const tempCountryList: Country[] = new Array<Country>();
    app.travelHistory.countries.forEach(country => {
      const tempCountry: Country = this.refService.getCountryByIsoCode(country.isoCountryCode);
      if (tempCountry){
          tempCountryList.push(tempCountry);
      }
    });
    app.travelHistory.countries = tempCountryList;

    // set employment countries
    app.employments.forEach(employment => {
      if (employment.address.country.isoCountryCode) {
        employment.address.country =
          this.refService.getCountryByIsoCode(employment.address.country.isoCountryCode);
      }
    });
  }

  // set vehicle registration determination questions if application is for adding vehicles
  private _setVehicleRegistrationQuestions(app: Application) {
    if (app.requestType === 'VE') {
      app.driveBorder = 'Y';
      app.vehicleAlreadyRegistered = 'N';
      app.vehicleRegisterNow = 'Y';
    }
  }

  // filter citizenship documents based on program code
  private _filterCitDocuments(app: Application) {
    if (app.programCode === 'AB'){
        app.citizenshipDocuments.forEach(citDoc => {
           citDoc.documents = citDoc.documents.filter(doc => doc.type === 'PT');
        });
    }
  }

  /**
   *
   * @param userId May be GES ID
   * @param appId  Application id
   */
  getGESApplication(userId: string, appId: number, source: string): Observable<Application> {

    if (SETTINGS.useStaticTestAPIData) {
      // new application for testing
      // todo:
      const primaryCountry: Country = this.refService.getCountryByIsoCode('US');
      const minApplicationData: ApplicationMinimumData = new ApplicationMinimumData(primaryCountry, Program.GLOBAL_ENTRY_CODE);
      this.application = new Application(minApplicationData);
      this.logService.message('Test application generated: ', this.application);
      return of(this.application);
    }

    if (!userId || !appId) {
      return this.errorHandler.handleError('Either user Id (' + userId + ') or application Id (' + appId + ') is not valid.');
    }

    const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/ges/' + appId + '?source=' + source;
    return this.http.get(requestURI).pipe(
      map(res => {
        if (res) {
          const data = res;
          if (this.errorHandler.checkErrorCode(data)) {
            // no errors in retrieving application
            // ApplicationMapper.deserialize(data, this.application, this.errorHandler, this.refService.getCountries());
              // console.log("APPLICATION PRE-MAPPING: ",res.json());
              this.gesApplication = new Application(res);
              if (this.gesApplication.driversLicense.countryOfIssuance.isoCountryCode){
                  this.gesApplication.driversLicense.countryOfIssuance =
                    this.refService.getCountryByIsoCode(this.gesApplication.driversLicense.countryOfIssuance.isoCountryCode);
              }
              this.gesApplication.addresses.forEach(address => {
                if (address.country && address.country.isoCountryCode) {
                    address.country = this.refService.getCountryByIsoCode(address.country.isoCountryCode);
                }
              });
              this.gesApplication.citizenshipDocuments.forEach(citDocs => {
                citDocs.country = this.refService.getCountryByIsoCode(citDocs.country.isoCountryCode);
                if (citDocs.country.isoCountryCode === this.gesApplication.primaryCitizenshipCountryIsoCode) {
                  citDocs.country.isPrimary = true;
                }
              });
              const tempCountryList: Country[] = new Array<Country>();
              this.gesApplication.travelHistory.countries.forEach(country => {
                const tempCountry: Country = this.refService.getCountryByIsoCode(country.isoCountryCode);
                if (tempCountry){
                    tempCountryList.push(tempCountry);
                }
              });
              this.gesApplication.travelHistory.countries = tempCountryList;

              this.gesApplication.employments.forEach(employment => {
                // Self employed would not have a country so check for null.
                // NOTE should a country even be part of the model????
                if (employment.address.country.isoCountryCode) {
                  employment.address.country =
                    this.refService.getCountryByIsoCode(employment.address.country.isoCountryCode);
                }
              });
              return of(this.gesApplication);
          }
        }
        return null;
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }

  /**
   *
   */
  createApplication(userId: string, request: CreateApplicationRequest): Observable<any> {
    if (!userId) {
      return this.errorHandler.handleError('User Id (' + userId + ') is not valid.');
    }

    return this.http.post(environment.uriEndpoint + environment.apiVersion + 'goesapp/users/' + userId + '/applications/', request).pipe(
      map( (response: any) => {
        return response;
      }),
      catchError(error => this.errorHandler.handleError(error)));

  }

  /* update application */
  updateApplication(userId: string, appId: number, serverValidate: boolean, certify?: boolean): Observable<any> {

    const headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    if (!userId || !appId) {
      return this.errorHandler.handleError('Either user Id (' + userId + ') or application Id (' + appId + ') is not valid.');
    }
    let requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/' + appId + '/' + serverValidate;
    if (certify) {
        requestURI += '?certify=true';
        this.application.person.langPref = this.translate.currentLang;
    }

    const body = ApplicationMapper.serialize(this.application, this.errorHandler, this.refService);
    return this.http.put(requestURI, body, headers).pipe(
      map((res: Response) => {
        if (res) {
          const data = res;
          if (this.errorHandler.checkErrorCode(data)) {
              // update application documents (if exists) based on response

              /****** Commented out because it was affecting the model binding for the documents *******/
              const resApp = new Application(data);
              // this._filterCitDocuments(resApp);
              // this._setCountryObjects(resApp);
              // this.application.citizenshipDocuments = resApp.citizenshipDocuments;

              /** Set the document number and document key for each document */
              this.application.citizenshipDocuments.forEach((cd) => {
                cd.documents.forEach(doc => {
                  // If their is no docId find in the returned documents
                  if (!doc.docId) {
                    resApp.citizenshipDocuments.forEach((resultCd: any) => {
                      const returnedDoc = resultCd.documents.find((d: any) => {
                          return d.documentNumber === doc.documentNumber &&
                                  d.countryOfIssuance.isoCountryCode === doc.countryOfIssuance.isoCountryCode;
                      });

                      if (returnedDoc) {
                        doc.docId = returnedDoc.docId;
                        doc.documentKey = returnedDoc.documentKey;
                      }
                    });
                  }
                });
              });

              return of(true);
          }
        }
        return null;
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }

  /*
   * Update the status of specified applications to the specified status
   */
  updateApplicationStatus(userId: string, appIds: number[], status: string): Observable<boolean>{
    const headers = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      })
    };

    if (!userId || appIds || appIds.length === 0) {
      this.logService.warning('update application status - parameters passed in are not valid');
    }
    const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/update/requestStatus/' + status;
    const body = JSON.stringify(appIds);
    return this.http.put(requestURI, body, headers).pipe(
      map(res => true),
      catchError(error => this.errorHandler.handleError(error))
    );
  }

  validatePersonalInfo(step: Step): boolean {
    step.errorSummary = { fields: [], summary: [] };
    const isFastNorth = this.application.programCode === 'FN';
    const person = this.application.person;
    if (!person.gender){
        step.errorSummary.fields.push('GENDER');
        step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.GENDER_REQUIRED');
    }
    if (!person.eyeColor && !isFastNorth){
      step.errorSummary.fields.push('EYECOLOR');
      step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.EYE_COLOR_REQUIRED');
    }
    if (!person.heightType && !isFastNorth){
        step.errorSummary.fields.push('HEIGHT');
        step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.HEIGHT_REQUIRED');
    }
    if (!person.heightInches && !isFastNorth){
        step.errorSummary.fields.push('HEIGHT');
        step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.HEIGHT_REQUIRED');
    }

    if (person.hasAliases === 'Y') {
      if (person.aliasFirstNames.filter(name => name !== '').length === 0 &&
                          person.aliasLastNames.filter(name => name !== '').length === 0) {
        step.errorSummary.fields.push('ALIAS');
        step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.ALIAS_FIRSTNAME_REQUIRED');
      }

      const isDuplicateFirstName = person.aliasFirstNames.some(function(item, idx) {
          return person.aliasFirstNames.indexOf(item) !== idx;
      });
      if (isDuplicateFirstName) {
        step.errorSummary.fields.push('ALIAS');
        step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.DUPLICATE_ALIAS_FIRST_NAME');
      }
      const isDuplicateLastName = person.aliasLastNames.some(function(item, idx) {
          return person.aliasLastNames.indexOf(item) !== idx;
      });
      if (isDuplicateLastName) {
        step.errorSummary.fields.push('ALIAS');
        step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.DUPLICATE_ALIAS_LAST_NAME');
      }

      if (person.aliasFirstNames.filter(name => !Utils.isValidName(name, false)).length) {
        step.errorSummary.fields.push('ALIAS');
        step.errorSummary.summary.push('SERVER_ERRORS.1102_UI');
      }

      if (person.aliasLastNames.filter(name => !Utils.isValidName(name, false)).length) {
        step.errorSummary.fields.push('ALIAS');
        step.errorSummary.summary.push('SERVER_ERRORS.1103_UI');
      }
    }

    const age = this.authService.getUser().age;
    if (Number.isInteger(age) && age < 18) {
        if (!person.lgLastName){
            step.errorSummary.fields.push('GUARDIAN_LASTNAME');
            step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_LAST_NAME_REQUIRED');
        }
        if (!person.lgFirstName){
            step.errorSummary.fields.push('GUARDIAN_FIRSTNAME');
            step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_FIRST_NAME_REQUIRED');
        }
        if (!person.lgDateOfBirth.getSimpleString()){
            step.errorSummary.fields.push('GUARDIAN_DATE_OF_BIRTH');
            step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_DOB_REQUIRED');
        }
        if (!person.lgGender){
            step.errorSummary.fields.push('GUARDIAN_GENDER');
            step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_GENDER_REQUIRED');
        }

        if (!person.lgIdType || (person.lgIdType == 'A' && !person.lgApplicationId)) {
          step.errorSummary.fields.push('GUARDIAN_APPLICATIONID');
          step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_APPLICATIONID_REQUIRED');
        }

        if (person.lgIdType == 'P' && !person.lgPassId) {
          step.errorSummary.fields.push('GUARDIAN_PASSID');
          step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.GUARDIAN_PASSID_REQUIRED');
        }
    }

    this.validateTsaData(step);

    return step.errorSummary.fields.length > 0;
  }
  validateTsaData(step: Step):boolean {
    if (this.application.person.hasTsaKtn === 'Y' && this.application.tsaPreData && this.application.person.tsaPreDtoDb) {
      if (this.application.person.tsaPreKtn === this.application.person.tsaPreDtoDb.knownTravelerNumber  
          && this.application.person.tsaPreDtoDb.verificationStatus === TsaPreData.KTN_VERIFICATION_INVALID){
            step.errorSummary.fields.push('TSAPRE_KTN');
            step.errorSummary.summary.push('ERROR_MESSAGES.PERSONAL_INFO.TSA_KTN_INVALID');
      } 
    }
    return step.errorSummary.fields.length > 0;
  }

  validateLicense(step: Step): boolean {
    step.errorSummary = { fields: [], summary: [] };
    const lic = this.application.driversLicense;
    const UsCaMx = ['US', 'CA', 'MX'];
    const hasLic = lic.haveLicense === 'Y';
    const isFast = (this.application.programCode === ProgramCodes.FastNorth || this.application.programCode === ProgramCodes.FastSouth);
    if (!lic.haveLicense && !isFast) {
      step.errorSummary.fields.push('DO_YOU_CURRENTLY_HOLD');
      step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW');
    }
    if (hasLic || isFast){
      if (!lic.licenseNumber){
        step.errorSummary.fields.push('DRIVERS_LICENSE_NUMBER');
        step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.LICENSE_NUMBER_REQUIRED');
      }
      if (!lic.countryOfIssuance.isoCountryCode){
        step.errorSummary.fields.push('COUNTRY_OF_ISSUANCE');
        step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.COUNTRY_OF_ISSUANCE_REQUIRED');
      }
      if (!lic.stateOfIssuance && lic.countryOfIssuance.isoCountryCode !== 'MX' &&
                  (lic.countryOfIssuance.isoCountryCode === 'US' || lic.countryOfIssuance.isoCountryCode === 'CA')){
        step.errorSummary.fields.push('STATE_OF_ISSUANCE');
        step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.STATE_OF_ISSUANCE_REQUIRED');
      }
      if (!lic.expirationDate.isPopulated() && UsCaMx.includes(lic.countryOfIssuance.isoCountryCode)){
          step.errorSummary.fields.push('EXPIRATION_DATE');
          step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.LICENSE_EXPIRATION_REQUIRED');
      }
      else if (lic.expirationDate.isPopulated() && !Utils.isDateAfter(lic.expirationDate.getSimpleString(), Utils.getTodayStr())) {
        step.errorSummary.fields.push('EXPIRATION_DATE');
        const todayString = Utils.getTodayStr();
        this.translate.get('ERROR_MESSAGES.GENERAL.DATE_MIN_ERROR', {dateString : todayString}).subscribe(res => {
            step.errorSummary.summary.push(res);
        });
      }
      if (!lic.lastName){
        step.errorSummary.fields.push('LAST_NAME');
        step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.LAST_NAME_REQUIRED');
      }
      if (!lic.firstName){
         step.errorSummary.fields.push('FIRST_NAME');
         step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.FIRST_NAME_REQUIRED');
      }
      // if(!lic.middleName && hasLic){
      //   step.errorSummary.fields.push('MIDDLE_NAME');
      //   step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.MIDDLE_NAME_REQUIRED');
      // }
      if (!lic.dateOfBirth.isPopulated()){
          step.errorSummary.fields.push('DATE_OF_BIRTH');
          step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.DATE_OF_BIRTH_REQUIRED');

      }
      if (!lic.isCDL && (lic.countryOfIssuance.isoCountryCode === 'MX' || lic.countryOfIssuance.isoCountryCode === 'US') ){
        step.errorSummary.fields.push('IS_CDL');
        step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW');


      }
      if (!lic.isEDL && (lic.countryOfIssuance.isoCountryCode === 'CA' || lic.countryOfIssuance.isoCountryCode === 'US')){
        step.errorSummary.fields.push('IS_EDL');
        step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW');
      }

      if (!lic.isHazmat && (lic.countryOfIssuance.isoCountryCode === 'US' || lic.countryOfIssuance.isoCountryCode === 'MX') && lic.isCDL === 'Y'){
        step.errorSummary.fields.push('IS_HAZMAT');
        step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW');
      }
      // if(!lic.isHazmat && hasLic) step.errorSummary.fields.push('');
    }
    return step.errorSummary.fields.length > 0;
  }

  validateDocuments(step: Step): boolean {

    step.errorSummary = { fields: [], summary: [] };
    const app: Application = this.application;

    app.citizenshipDocuments.forEach((docs: CitizenshipDocuments) => {
        const citFields: any [] = [];
        // check for added documents;
        if (docs.documents.filter(doc => doc.citizenshipProofInd).length <= 0) {
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CIT_DOCS_REQUIRED');
        }

        if (docs.country.isoCountryCode === app.primaryCitizenshipCountryIsoCode) {
            if (docs.documents.filter(doc => doc.admissibilityProofInd).length <= 0) {
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.ADMIS_DOCS_REQUIRED');
            }

            if ((app.primaryCitizenshipLPRCountryIsoCode === 'MX' ||
                  app.primaryCitizenshipLPRCountryIsoCode === 'CA' ||
                  app.primaryCitizenshipLPRCountryIsoCode === 'US') && app.primaryCitizenshipCountryIsoCode !== 'US'){
                if (docs.documents.filter(doc => doc.lprProofInd).length <= 0) {
                    step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.LPR_DOC_REQUIRED');
                }
            }

            // Check US document verified status; status must be VERIFIED, OVERRIDE or UNAVAILABLE
            if (docs.documents
                  .filter(doc => doc.countryOfIssuance.isoCountryCode === 'US')
                  .filter(doc => doc.type === 'PT' || doc.type === 'PR')
                  .filter(doc => !doc.verified ||
                    (doc.verified.toUpperCase() !== 'VERIFIED' &&
                     doc.verified.toUpperCase() !== 'OVERRIDE' &&
                     doc.verified.toUpperCase() !== 'UNAVAILABLE')).length > 0) {
              step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.UNVERIFIED_DOCUMENTS');
            }
        }

        docs.documents.forEach((doc: Document) => {
          const fields: string[] = [];

          if (!doc.surName) {
            fields.push('LAST_NAME_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.LAST_NAME_REQUIRED');
          }
          if (!doc.givenName) {
            fields.push('GIVEN_NAME_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.GIVEN_NAME_REQUIRED');
          }
          if (doc.surName && !this.textAlphaPattern.test(doc.surName)) {
            fields.push('LAST_NAME_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.LAST_NAME_PATTERN');
          }
          if (doc.middleName && !this.textAlphaPattern.test(doc.middleName)) {
            fields.push('MIDDLE_NAME_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.MIDDLE_NAME_PATTERN');
          }
          if (doc.givenName && !this.textAlphaPattern.test(doc.givenName)) {
            fields.push('GIVEN_NAME_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.GIVEN_NAME_PATTERN');
          }

          if (!doc.dateOfBirth.getSimpleString()) {
            fields.push('DATE_OF_BIRTH_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DRIVERS_LICENSE.DATE_OF_BIRTH_REQUIRED');
          }
          if (!doc.countryOfIssuance.isoCountryCode) {
            fields.push('COUNTRY_OF_ISSUANCE_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.COUNTRY_REQUIRED');
          }
          if (doc.type !== 'BB' && !doc.documentNumber) {
            fields.push('DOCUMENT_NUMBER');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.DOCUMENT_NUMBER_REQUIRED');
          }

          if ((doc.type === 'PT' ||  doc.type === 'PR' || doc.type === 'VS' ||
              doc.type === 'BCC' || doc.type === 'WP' || doc.type === 'SP')) {
            const todayString = Utils.getTodayStr();
            if (!doc.expiration.getSimpleString()) {
                fields.push('EXPIRATION_LABEL');
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.EXPIRATION_REQUIRED');
            }
            else if (!Utils.isDateAfter(doc.expiration.getSimpleString(), todayString)) {
                fields.push('EXPIRATION_LABEL');
                this.translate.get('ERROR_MESSAGES.GENERAL.DATE_MIN_ERROR', {dateString : todayString}).subscribe(res => {
                    step.errorSummary.summary.push(res);
                });
            }
          }
          if (doc.type === 'VS' && !doc.class){
            fields.push('VISA_CLASS_LABEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.CARD.VISA_CLASS_REQUIRED');
          }
          citFields.push(fields);
      });
        step.errorSummary.fields.push(citFields);
    });    

    // REQUESTED INFO
    let pilotCountry = false;
    const country = this.refService.getCountryByIsoCode(app.primaryCitizenshipCountryIsoCode);
    if (country.geEligibility === 'PILOT' && app.programCode === 'UP' &&
          this.application.primaryCitizenshipLPRCountryIsoCode !== 'US' && this.application.primaryCitizenshipLPRCountryIsoCode !== 'CA') {
            pilotCountry = true;
        }
    if (this.showRequested()) {

        const reqFields: string[] = [];
        // SINGAPORE
        if (app.primaryCitizenshipCountryIsoCode === 'SG' && app.programCode === 'UP'){
            if (!app.sgRequestedInfo.NRIC){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.SINGAPORE_NATIONAL_ID_REQUIRED');
                reqFields.push('SINGAPORE_NATIONAL_ID');
            }
            if (pilotCountry && !app.sgRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

        // ARGENTINA
        if (app.primaryCitizenshipCountryIsoCode === 'AR' && app.programCode === 'UP'){
            if (!app.arRequestedInfo.nationalID){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.ARGENTINA_NATIONAL_ID_REQUIRED');
                reqFields.push('ARGENTINA_NATIONAL_ID');
            }
            if (pilotCountry && !app.arRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

        // COLOMBIA
        if (app.primaryCitizenshipCountryIsoCode === 'CO' && app.programCode === 'UP'){
            if (!app.coRequestedInfo.nationalID){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.COLOMBIA_NATIONAL_ID_REQUIRED');
                reqFields.push('COLOMBIA_NATIONAL_ID');
            }

            if (pilotCountry && !app.coRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }

            if (app.coRequestedInfo.maternalName && !Utils.isValidName(app.coRequestedInfo.maternalName)) {
              step.errorSummary.summary.push('SERVER_ERRORS.1201_UI');
              reqFields.push('SECOND_SURNAME_LABEL');
            }
        }

        // TAIWAN
        if (app.primaryCitizenshipCountryIsoCode === 'TW' && app.programCode === 'UP'){
            if (!app.twRequestedInfo.nationalID){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.TAIWAN_NATIONAL_ID_REQUIRED');
                reqFields.push('TAIWAN_NATIONAL_ID');
            }
            if (!app.twRequestedInfo.criminalRecordNumber){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.TAIWAN_CRIMINAL_RECORD_NUMBER_REQUIRED');
                reqFields.push('TAIWAN_CRIMINAL_RECORD');
            }
            if (pilotCountry && !app.twRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }


        // ISRAEL
        if (app.primaryCitizenshipCountryIsoCode === 'IL' && app.programCode === 'UP'){
            if (!app.ilRequestedInfo.citizenshipNumber){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.ISRAEL_CITIZENSHIP_NUMBER_REQUIRED');
                reqFields.push('ISRAEL_CITIZENSHIP_NUMBER');
            }
            if (pilotCountry && !app.ilRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

        // SAUDI ARABIA
        if (app.primaryCitizenshipCountryIsoCode === 'SA' && app.programCode === 'UP'){
            if (!app.saRequestedInfo.nationalID){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.SAUDIA_NATIONAL_ID_REQUIRED');
                reqFields.push('SAUDIA_NATIONAL_ID');
            }
            if (pilotCountry && !app.saRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

        // QATAR
        if (app.primaryCitizenshipCountryIsoCode === 'QA' && app.programCode === 'UP'){
            if (!app.qaRequestedInfo.nationalID){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.QATAR_ID_REQUIRED');
                reqFields.push('QATAR_ID_LABEL');
            }
            if (pilotCountry && !app.saRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

        // UK
        if (app.primaryCitizenshipCountryIsoCode === 'GB' && app.programCode === 'UP'){
            if (!app.ukRequestedInfo.UKAccessCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.UK_ACCESS_CODE_REQUIRED');
                reqFields.push('UK_ACCESS_CODE_LABEL');
            }
        }

        // MEXICO
        if (app.primaryCitizenshipCountryIsoCode === 'MX' && (app.programCode === 'UP' || app.programCode === 'NH')){
            if (app.programCode === 'NH'){
                if (!app.mxRequestedInfo.PVC) {
                    step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PVC_REQUIRED');
                    reqFields.push('PVC_LABEL');
                }
            }
            if (app.programCode === 'UP'){
                if (!app.mxRequestedInfo.RFC) {
                    step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.RFC_REQUIRED');
                    reqFields.push('RFC_LABEL');
                }
                if (!app.mxRequestedInfo.RFCOwnedByApplicant) {
                    step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.RFC_OWNER_REQUIRED');
                    reqFields.push('RFC_OWNED_BY_APPLICANT_LABEL');
                }
                if (!app.mxRequestedInfo.CURP) {
                    step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.CURP_REQUIRED');
                    reqFields.push('CURP_LABEL');
                }
            }
            if (pilotCountry && !app.mxRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

          // BRAZIL
        if (app.primaryCitizenshipCountryIsoCode === 'BR' && app.programCode === 'UP') {
            if (!app.brRequestedInfo.cpfNumber) {
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.BRAZIL_CPF_REQUIRED');
                reqFields.push('BRAZIL_CPF_NUMBER');
            }
            if (pilotCountry && !app.brRequestedInfo.promoCode) {
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

        // let reqCountires = ['MX', 'TW', 'UK', 'QA', 'SA', 'SG', 'IL', 'CO', 'AR'];
        const reqCountries = SETTINGS.PILOT_COUNTRIES;

        if (pilotCountry && !reqCountries.includes(app.primaryCitizenshipCountryIsoCode)){
            if (!app.pilotRequestedInfo.promoCode){
                step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.PROMO_CODE_REQUIRED');
                reqFields.push('PROMO_CODE_LABEL');
            }
        }

        // DOMINICAN REPUBLIC
        if (app.primaryCitizenshipCountryIsoCode === 'DO' && app.programCode === 'UP') {
          if (!app.doRequestedInfo.cedNumber) {
              step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.DOMINICAN_REPUBLIC_CED_REQUIRED');
              reqFields.push('DOMINICAN_REPUBLIC_CED_NUMBER');
          }
        }

        // UNITED ARAB EMIRATES
        if (app.primaryCitizenshipCountryIsoCode === 'AE' && app.programCode === 'UP') {
          if (!app.aeRequestedInfo.idNumber) {
              step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.REQUESTED.UNITED_ARAB_EMIRATES_ID_REQUIRED');
              reqFields.push('UNITED_ARAB_EMIRATES_ID_REQUIRED');
          }
        }

        step.errorSummary.fields[0].push(reqFields);

    }

    return step.errorSummary.summary.length > 0;

  }

  validateDocumentsNameDOBMatchProfile(step: Step, user: User): boolean {
    const app: Application = this.application;
    let nameDOBMatched = true;

    app.citizenshipDocuments.forEach((docs: CitizenshipDocuments) => {
      docs.documents
          .filter(doc => doc.countryOfIssuance.isoCountryCode === 'US')
          .filter(doc => doc.type === 'PT' || doc.type === 'PR')
          .forEach(appDoc => {
            if (!Utils.doesDocNameDOBMatchUser(user, appDoc)) {
              nameDOBMatched = false;
            }
          });
    });

    if (!nameDOBMatched) {
      step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.PROFILE_NAME_DOB_MISMATCH');
    }

    return step.errorSummary.summary.length > 0;
  }

  validateABTCPassport(step: Step): boolean {
    let selectedPassport: Document = null;
    const todayString = Utils.getTodayStr();

    step.errorSummary = { fields: [], summary: [] };
    const app = this.application;
    app.citizenshipDocuments.forEach(citDoc => {
       citDoc.documents.forEach(doc => {
           if (doc.type === 'PT'){
               if (doc.abtcInd){
                   selectedPassport = doc;
               }
           }
       });
    });

    if (!selectedPassport) {
        step.errorSummary.summary.push('ERROR_MESSAGES.ABTC_PASSPORT.SELECTED_REQUIRED');
    }
    else {
        if (!selectedPassport.surName) {
            step.errorSummary.summary.push('ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.LAST_NAME_REQUIRED');
            step.errorSummary.fields.push('LAST_NAME');
        }
        if (!selectedPassport.givenName){
            step.errorSummary.summary.push('ERROR_MESSAGES.SIGN_UP_PERSONAL_INFO.GIVEN_NAME_REQUIRED');
            step.errorSummary.fields.push('FIRST_NAME');
        }
        if (!selectedPassport.dateOfBirth.getSimpleString()){
            step.errorSummary.summary.push('ERROR_MESSAGES.ABTC_PASSPORT.DOB_REQUIRED');
            step.errorSummary.fields.push('BIRTH_DATE');
        }
        if (!selectedPassport.countryOfIssuance.isoCountryCode){
            step.errorSummary.summary.push('ERROR_MESSAGES.ABTC_PASSPORT.COUNTRY_OF_ISSUANCE_REQUIRED');
            step.errorSummary.fields.push('COUNTRY_OF_ISSUANCE_LABEL');
        }
        if (!selectedPassport.documentNumber){
            step.errorSummary.summary.push('ERROR_MESSAGES.ABTC_PASSPORT.PASSPORT_NUMBER_REQUIRED');
            step.errorSummary.fields.push('PASSPORT_NUMBER');
        }
        if (!selectedPassport.expiration.getSimpleString()){
            step.errorSummary.summary.push('ERROR_MESSAGES.ABTC_PASSPORT.EXPIRATION_REQUIRED');
            step.errorSummary.fields.push('EXPIRATION');
        }
        if (selectedPassport.expiration.getSimpleString() &&
              (!Utils.isDateAfter(selectedPassport.expiration.getSimpleString(), todayString)))
        {
            step.errorSummary.summary.push('ERROR_MESSAGES.ABTC_PASSPORT.EXPIRATION_DATE_RANGE');
            step.errorSummary.fields.push('EXPIRATION');
        }

        // Check US document verified status; status must be VERIFIED, OVERRIDE or UNAVAILABLE
        if (selectedPassport.countryOfIssuance.isoCountryCode === 'US' &&
            (selectedPassport.verified.toUpperCase() !== 'VERIFIED' &&
            selectedPassport.verified.toUpperCase() !== 'OVERRIDE' &&
            selectedPassport.verified.toUpperCase() !== 'UNAVAILABLE')) {
            step.errorSummary.summary.push('ERROR_MESSAGES.DOCUMENTS.UNVERIFIED_DOCUMENTS');
        }
    }
    return step.errorSummary.summary.length > 0;
  }


  validateVehicles(step: Step): boolean {
    step.errorSummary = { fields: [], summary: [] };
    const app = this.application;

    // vehicle registration is required but none were provided, so add an error to the summary
    if (app.requestType !== 'VE'){
      if (!app.driveBorder) {
        step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.VEHICLE_QUESTION_REQUIRED');
      }

      if (app.driveBorder === 'Y') {
        if (!app.vehicleAlreadyRegistered) {
          step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.VEHICLE_QUESTION_REQUIRED');
        }

        if (app.vehicleAlreadyRegistered === 'N'){
            if (!app.vehicleRegisterNow) {
              step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.VEHICLE_QUESTION_REQUIRED');
            }
        }

      }
    }

    if (app.requestType === 'VE' || (app.driveBorder === 'Y' && app.vehicleAlreadyRegistered === 'N' && app.vehicleRegisterNow === 'Y')) {
        if (app.vehicles.length === 0){
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.VEHICLE_REQUIRED');
        }
        for (const veh of app.vehicles) {
          const vehFields: string[] = [];
          if (!veh.make) {
            vehFields.push('LABEL_MAKE');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.MAKE_REQUIRED');
          }

          if (!veh.model) {
            vehFields.push('LABEL_MODEL');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.MODEL_REQUIRED');
          }
          if (!veh.year){
            vehFields.push('LABEL_YEAR');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.YEAR_REQUIRED');

          }
          // if(!veh.color){
          //   vehFields.push('LABEL_COLOR');
          //   step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.COLOR_REQUIRED');

          // }
          if (!veh.vin){
            vehFields.push('LABEL_VIN');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.VIN_REQUIRED');
          }
          if (!veh.licensePlateNumber){
            vehFields.push('LABEL_LICENSE_PLATE_NUMBER');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.PLATE_REQUIRED');

          }
          if (!veh.licenseCountryOfIssuance){
            vehFields.push('LABEL_LICENSE_COUNTRY_OF_ISSUANCE');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.COUNTRY_OF_ISSUANCE_REQUIRED');

          }
          if (veh.licenseCountryOfIssuance === 'US' && !veh.govtLicensePlate){
            vehFields.push('LABEL_GOVERNMENT_ISSUED_LICENSE_PLATE');
            step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED');
          }
          if (!veh.stateProvinceOfIssuance &&
              (veh.licenseCountryOfIssuance === 'CA' ||  veh.licenseCountryOfIssuance === 'MX' ||
                (veh.licenseCountryOfIssuance === 'US' && veh.govtLicensePlate === 'N') )){
            vehFields.push('LABEL_LICENSE_STATE_PROVINCE_OF_ISSUANCE');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.STATE_OF_ISSUANCE_REQUIRED');

          }
          if (!veh.owner){
            vehFields.push('LABEL_WHO_OWNS_THE_VEHICLE');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_REQUIRED');

          }
          // I = individual and B = business
          if (!veh.ownerLastName && veh.owner === 'I'){
            vehFields.push('LABEL_LAST_NAME');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_LAST_NAME_REQUIRED');

          }
          if (!veh.ownerFirstName && veh.owner === 'I'){
            vehFields.push('LABEL_FIRST_NAME');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_FIRST_NAME_REQUIRED');

          }
          // if(!veh.ownerMiddleName && veh.owner == 'I'){
          //   vehFields.push('LABEL_MIDDLE_NAME');
          //   step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_MIDDLE_NAME_REQUIRED');

          // }
          if (!veh.ownerDateOfBirth.getSimpleString() && veh.owner === 'I'){
            vehFields.push('LABEL_DATE_OF_BIRTH');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_DATE_OF_BIRTH_REQUIRED');

          }
          if (!veh.ownerPhoneFormat && (veh.owner === 'I' || veh.owner === 'B' )){
            vehFields.push('LABEL_PHONE_NUMBER_FORMAT');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_PHONE_FORMAT_REQUIRED');

          }
          if (!veh.ownerPhoneCountryCode &&
                (veh.owner === 'I' || veh.owner === 'B' ) && (veh.ownerPhoneFormat === 'M' || veh.ownerPhoneFormat === 'I')){
            vehFields.push('LABEL_PHONE_COUNTRY_CODE');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_PHONE_COUNTRY_CODE_REQUIRED');
          }
          if (!veh.ownerPhoneNumber && (veh.owner === 'I' || veh.owner === 'B' )){
            vehFields.push('LABEL_PHONE_NUMBER');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_PHONE_NUMBER_REQUIRED');
          }

          // Business owner checks
          if (veh.owner === 'B'){
              // Business Name
              if (!veh.businessName){
                vehFields.push('LABEL_BUSINESS_NAME');
                step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.CARD.BUSINESS_NAME_REQUIRED');
              }
              // State
              if (veh.businessAddress.country.isoCountryCode === 'US' ||
                    veh.businessAddress.country.isoCountryCode === 'CA' || veh.businessAddress.country.isoCountryCode === 'MX'){
                  if (!veh.businessAddress.state){
                    vehFields.push('LABEL_STATE');
                    step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.CARD.STATE_REQUIRED');

                  }
              }
              // City
              if (!veh.businessAddress.city){
                    vehFields.push('LABEL_CITY');
                    step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.CARD.CITY_REQUIRED');

              }
              // Zipcode
              if (veh.businessAddress.country.isoCountryCode === 'US' ||
                    veh.businessAddress.country.isoCountryCode === 'CA' || veh.businessAddress.country.isoCountryCode === 'MX'){
                  if (!veh.businessAddress.postalCode){
                    vehFields.push('LABEL_POSTAL_CODE');
                    step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.ZIP_REQUIRED');

                  }
              }
              // Country
              if (!veh.businessAddress.country.isoCountryCode){
                    vehFields.push('LABEL_COUNTRY');
                    step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLE.CARD.COUNTRY_REQUIRED');


              }
              // Addresss Line 1
              if (!veh.businessAddress.addressLine1){
                    vehFields.push('LABEL_ADDRESS_LINE_1');
                    step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.ADDRESS_LINE_1_REQUIRED');
              }
          }
          // if(!veh.businessName && veh.owner == 'B') vehFields.push('');
          // if(veh.owner =='B'){
          //   let addr = veh.businessAddress;
          //   if(addr){
          //     if(!addr.addressLine1) vehFields.push('');
          //     if(!addr.city) vehFields.push('');
          //     if(!addr.state) vehFields.push('');
          //     if(!addr.postalCode) vehFields.push('');
          //   }
          // }

          if (!veh.ownerGender && veh.owner === 'I'){
            vehFields.push('LABEL_GENDER');
            step.errorSummary.summary.push('ERROR_MESSAGES.VEHICLES.OWNER_GENDER_REQUIRED');

          }
          // if(step.errorSummary.fields.length > 0){
          step.errorSummary.fields.push(vehFields);
        //  }
        }
    }
    return step.errorSummary.summary.length > 0;
  }

  validateAddresses(step: Step): boolean {
    let isABTC = false;
    let isCardReplace = false;

    isABTC = this.application.programCode === 'AB' || this.application.requestType === 'AC';
    isCardReplace = this.application.requestType === 'CR';

    step.errorSummary = { fields: [], summary: [] };

    this.validateAddressTimeilne(step);
    for (const addr of this.application.addresses){
      const addrFields: string[] = [];

      if (!addr.startMonth || !addr.startYear){
          addrFields.push('STARTDATE');
          step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.START_DATE_REQUIRED');
      }

      if (!addr.isCurrent) {
          if (!addr.endYear || !addr.endMonth){
            addrFields.push('ENDDATE');
            step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.END_DATE_REQUIRED');
          }
      }
      if (!addr.addressLine1){
        addrFields.push('ADDRESS_LINE_1');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.ADDRESS_LINE_1_REQUIRED');
      }
      if (!addr.city){
        addrFields.push('CITY');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CITY_REQUIRED');
      }
      if (!addr.state && (addr.countryCode === 'MX' || addr.countryCode === 'US' || addr.countryCode === 'CA')){
        addrFields.push('STATE');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.STATE_REQUIRED');
      }
      if (!addr.postalCode && (addr.countryCode === 'MX' || addr.countryCode === 'US' || addr.countryCode === 'CA')){
         addrFields.push('ZIP');
         step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.ZIP_REQUIRED');
      }
      if (!addr.countryCode) {
        addrFields.push('COUNTRY');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.COUNTRY_REQUIRED');
      }
      if (addr.isCurrent && addr.validatedIndicator === 'T' &&
            (addr.countryCode === 'US' || addr.countryCode === 'MX' || addr.countryCode === 'CA')){
        if (step.errorSummary.summary.indexOf('ERROR_MESSAGES.ADDRESSES.TRILLIUM_REQUIRED') === -1){
            step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.TRILLIUM_REQUIRED');
        }
      }

      if (addr.isCurrent && addr.countryCode === 'US' && addr.state && !isABTC && !isCardReplace) {

        const state = this.refService.getRef('states').US.find((s: any) => s.code === addr.state);

        if (!state || !state.ttpEligible) {
            addrFields.push('STATE');
            step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.STATE_NOT_ALLOWED');
        }
      }

      // if(step.errorSummary.fields.length >0){
      step.errorSummary.fields.push(addrFields);
      // }
    }

    if (!this.application.isMailingAddressResponse) {
        step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW');
        step.errorSummary.fields.push('MAILING_SAME');
    }
    const mailing = this.application.mailing;
    if (mailing && this.application.isMailingAddressResponse === 'N'){
      if (!mailing.startYear || !mailing.startMonth){
        step.errorSummary.fields.push('MAILING_SINCE');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.MAILING_START_REQUIRED');
      }
      if (!mailing.addressLine1){
        step.errorSummary.fields.push('MAILING_ADDRESS_LINE_1');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.MAILING_ADDRESS_LINE_1_REQUIRED');
      }
      if (!mailing.city){
        step.errorSummary.fields.push('MAILING_CITY');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.MAILING_CITY_REQUIRED');

      }
      if (!mailing.state && (mailing.countryCode === 'MX' || mailing.countryCode === 'US' || mailing.countryCode === 'CA')){
        step.errorSummary.fields.push('MAILING_STATE');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.MAILING_STATE_REQUIRED');

      }
      if (mailing.state && mailing.countryCode === 'US' && !isABTC && !isCardReplace) {

        const state = this.refService.getRef('states').US.find((s: any) => s.code === mailing.state);

        if (!state || !state.ttpEligible) {
          step.errorSummary.fields.push('MAILING_STATE');
          step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.STATE_NOT_ALLOWED');
        }
      }

      if (!mailing.postalCode && (mailing.countryCode === 'MX' || mailing.countryCode === 'US' || mailing.countryCode === 'CA')){
          step.errorSummary.fields.push('MAILING_ZIP');
          step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.MAILING_ZIP_REQUIRED');

      }
      if (!mailing.countryCode){
        step.errorSummary.fields.push('MAILING_COUNTRY');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.MAILING_COUNTRY_REQUIRED');

      }

      if (mailing.validatedIndicator === 'T' && (mailing.countryCode === 'US' || mailing.countryCode === 'CA' || mailing.countryCode === 'MX')){
           if (step.errorSummary.summary.indexOf('ERROR_MESSAGES.ADDRESSES.TRILLIUM_REQUIRED') === -1){
                step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.TRILLIUM_REQUIRED');
           }
      }

    }

    const uscontact = this.application.contact;
    const uscontactAddr = this.application.contact.address;
    if (this.showUSContact()){
      if (!uscontact.fullName){
          step.errorSummary.fields.push('CONTACT_FULL_NAME');
          step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CONTACT_FULL_NAME_REQUIRED');

      }
      if (!uscontactAddr.addressLine1){
        step.errorSummary.fields.push('CONTACT_ADDRESS_LINE_1');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CONTACT_ADDRESS_LINE_1_REQUIRED');
      }
      if (!uscontactAddr.city){
        step.errorSummary.fields.push('CONTACT_CITY');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CONTACT_CITY_REQUIRED');
      }
      if (!uscontactAddr.state){
        step.errorSummary.fields.push('CONTACT_STATE');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CONTACT_STATE_REQUIRED');
      }
      if (!uscontactAddr.postalCode){
        step.errorSummary.fields.push('CONTACT_ZIP');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CONTACT_ZIP_REQUIRED');
      }
      if (!uscontactAddr.country){
        step.errorSummary.fields.push('CONTACT_COUNTRY');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CONTACT_COUNTRY_REQUIRED');
      }
      if (!uscontact.phoneNumber){
        step.errorSummary.fields.push('CONTACT_PHONE_NUMBER');
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CONTACT_PHONE_REQUIRED');
      }
      if (uscontactAddr.validatedIndicator === 'T'){
           if (step.errorSummary.summary.indexOf('ERROR_MESSAGES.ADDRESSES.TRILLIUM_REQUIRED') === -1){
                step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.TRILLIUM_REQUIRED');
           }
      }
    }
    return step.errorSummary.summary.length > 0;
  }

  validateEmployments(step: Step): boolean {
    step.errorSummary = { fields: [], summary: [] };

    this.validateEmploymentTimeline(step);


    for (const emp of this.application.employments){
      const empFields: string[] = [];
      if (!emp.status){
        empFields.push('STATUS');
        step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.STATUS_REQUIRED');
      }
      // if(!emp.startMonth){
      //   empFields.push('STARTDATE');
      //   step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.START_DATE_REQUIRED');
      // }
      if (!emp.startYear || !emp.startMonth){
          empFields.push('STARTDATE');
          step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.START_DATE_REQUIRED');
      }
      if (!emp.isCurrent){
       // if(!emp.endMonth){
       //   empFields.push('ENDDATE');
       //   step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.END_DATE_REQUIRED');
       // }
        if (!emp.endYear || !emp.endMonth){
          empFields.push('ENDDATE');
          step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.END_DATE_REQUIRED');
        }
      }
      if (emp.isCurrent && (emp.status === 'E' || emp.status === 'F')
            && emp.address.validatedIndicator === 'T' &&
              (emp.address.country.isoCountryCode === 'US' ||
                emp.address.country.isoCountryCode === 'CA' || emp.address.country.isoCountryCode === 'MX')){
          if (step.errorSummary.summary.indexOf('ERROR_MESSAGES.EMPLOYMENT.TRILLIUM_REQUIRED') === -1){
              step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.TRILLIUM_REQUIRED');
          }
      }
      if (emp.status === 'E' || emp.status === 'F'){
        if (!emp.occupation){
            empFields.push('OCCUPATION');
            step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.OCCUPATION_REQUIRED');

        }
        if (!emp.employer){
          empFields.push('EMPLOYER');
          step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.EMPLOYER_REQUIRED');

        }
        if (emp.isCurrent && emp.phone){
          if (!emp.phone.format){
            empFields.push('EMPLOYER_PHONE_FORMAT');
            step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.EMPLOYER_PHONE_FORMAT');
          }
          if (emp.phone.format && (emp.phone.format === 'M' || emp.phone.format === 'I') && !emp.phone.countryCode){
            empFields.push('EMPLOYER_COUNTRY_CODE');
            step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.EMPLOYER_PHONE_COUNTRY_CODE');
          }
          if (!emp.phone.number){
            empFields.push('EMPLOYER_PHONE');
            step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.EMPLOYER_PHONE_NUMBER');
          }
        }
        if (emp.address){
          if (!emp.address.addressLine1){
              step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.ADDRESS_LINE_1_REQUIRED');
              empFields.push('ADDRESS_LINE_1');
          }
          if (!emp.address.city){
            empFields.push('CITY');
            step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.CITY_REQUIRED');
          }
          if (!emp.address.state &&
                (emp.address.country &&
                  (emp.address.country.isoCountryCode === 'CA'  ||
                    emp.address.country.isoCountryCode === 'MX'  || emp.address.country.isoCountryCode === 'US'))){
            empFields.push('STATE');
            step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.STATE_REQUIRED');
          }
          if (!emp.address.postalCode &&
                (emp.address.country &&
                  (emp.address.country.isoCountryCode === 'CA'
                    || emp.address.country.isoCountryCode === 'MX'  || emp.address.country.isoCountryCode === 'US'))){
            empFields.push('ZIP');
            step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.ZIP_REQUIRED');
          }
          if (emp.address.country && !emp.address.country.isoCountryCode){
            empFields.push('COUNTRY');
            step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.COUNTRY_REQUIRED');
          }
        }
      }
      step.errorSummary.fields.push(empFields);
    }
    // check to see if atleast one current employment is present
    let hasCurrentEmp = false;
    this.application.employments.forEach(emp => {
      if (emp.isCurrent) {
        hasCurrentEmp = true;
        return;
      }
    });
    if (!hasCurrentEmp) {
      step.errorSummary.summary.push('ERROR_MESSAGES.EMPLOYMENT.ATLEAST_ONE_EMPLOYMENT_REQ');
    }

    if (this.application.employments.length === 0){
        step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW');
    }
    return step.errorSummary.summary.length > 0;
  }

  validateTravelHistory(step: Step): boolean {
    step.errorSummary = { fields: [], summary: [] };
    const th = this.application.travelHistory;
    if (!th.haveYouTraveled){
      step.errorSummary.fields.push('HAVE_YOU_TRAVELED');
      step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.REQUIRED_QUESTION');
    }
    if ((th.haveYouTraveled === 'Y' && !th.countries) || (th.haveYouTraveled === 'Y' && th.countries.length === 0)) {
      step.errorSummary.fields.push('COUNTRIES');
      step.errorSummary.summary.push('ERROR_MESSAGES.GENERAL.COUNTRIES_REQUIRED');
    }
    return step.errorSummary.fields.length > 0;
  }

  validateQuestions(step: Step): boolean {
    step.errorSummary = { fields: [], summary: [] };
    const list = this.application.additionalInfo.questionList;
    for (const q of list){
      const qFields: string[] = [];
      let qSummary = '';
      if (!q.response) {
          qFields.push(q.code);
          qSummary = 'ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW';
      }
      if (q.response === 'Y' && !q.details){
        qFields.push('PLEASE_PROVIDE_DETAILS');
        qSummary = 'ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW';

      }
      if (q.response === 'Y' && q.questionTypeCode === '1' && !q.countryCode) {
          qFields.push('PLEASE_SELECT_COUNTRY');
          qSummary = 'ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW';
      }
      if (q.response === 'N' && q.code === 'APC') {
          // qFields.push('EVEN_ARREST_EXPUNGED');
          // qSummary= 'ERROR_MESSAGES.GENERAL.REQUIRED_REVIEW';
      }
      if (qSummary !== ''){
         step.errorSummary.summary.push(qSummary);
      }

      step.errorSummary.fields.push(qFields);

    }
    return step.errorSummary.summary.length > 0;
  }

  /** Validate ABTC add on */
  validateABTC(step: Step): boolean {
    step.errorSummary = { fields: [], summary: [] };

    if (this.application.addAbtc) {
      let passportSelected = false;

      for (const cd of this.application.citizenshipDocuments) {
          if (cd.country.isoCountryCode === 'US') {
              for (const doc of cd.documents) {
                  if (doc.type === 'PT' && doc.abtcInd && doc.citizenshipProofInd === true) {
                      passportSelected = true;
                      break;
                  }
              }
          }
      }

      if (!passportSelected) {
        step.errorSummary.fields.push('PASSPORT_NUMBER_LABEL');
        step.errorSummary.summary.push('ERROR_MESSAGES.ABTC.PASSPORT_REQUIRED');
      }
    }

    return step.errorSummary.fields.length > 0;
  }

  showUSContact(): boolean {

        // dont show US Contact for ABTC applications
        if (this.application.programCode === 'AB' ||  RequestType.abtcRequestTypes.includes(this.application.requestType)){
          return false;
        }

        // don't show if Fast North App, or Fast South App
        if (this.application.programCode === 'FN' || this.application.programCode === 'FS'){
          return false;
        }

        // don't show US Contact for MXC applying for FAST South
        if (this.application.primaryCitizenshipCountryIsoCode === 'MX' && this.application.programCode === 'FS'){
          return false;
        }

        // check addresses for US or CA address
        const currentAddresses = this.application.addresses.filter(add => add.isCurrent);
        let hasAllEmpty = true;
        if (currentAddresses.length === 0){
            return false;
        }
        for (const address of currentAddresses){
            if (address.countryCode === 'US' || address.countryCode === 'CA'){
                return false;
            }
            else if (address.countryCode !== ''){
                hasAllEmpty = false;
            }
        }
        if (hasAllEmpty) {
            return false;
        }

        // check mailing for CA or US address
        if (this.application.isMailingAddressResponse === 'N'){
            if (this.application.mailing){
                if (this.application.mailing.countryCode === 'US' || this.application.mailing.countryCode === 'CA'){
                    return false;
                }
            }
        }
        if (!this.application.isMailingAddressResponse){
            return false;
        }

        // don't show if GE AND foreign nationals (not including MX) USC, USLPR, MXC
        if (this.application.programCode === 'UP'){
            if (this.application.primaryCitizenshipCountryIsoCode !== 'US' &&
               this.application.primaryCitizenshipCountryIsoCode !== 'MX' &&
               this.application.primaryCitizenshipLPRCountryIsoCode !== 'US'){

                return false;
            }
        }

        return true;
    }


  public removeABTC(userId: string, appId: string): Observable<any> {
    const requestURI: string =  environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/remove/abtc/' + appId;

    return this.http.post(requestURI, {}).pipe(
      map(res => {
        if (res){
            // console.log(res);
            return true;
        }
        return null;
      }),
      catchError(error => this.errorHandler.handleError(error)));

  }

  /** Cancel application actually deletes an unpaid application */
  public cancelApplication(userId: string, appId: string): Observable<any> {
    if (!userId) {
      return this.errorHandler.handleError('User Id (' + userId + ') is not valid.');
    }

    if (!appId) {
      return this.errorHandler.handleError('Application Id (' + appId + ') is not valid.');
    }

    const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/' + appId;

    // This returns 200 or an exception
    return this.http.delete(requestURI).pipe(
      map(res => {
        return true;
      }),
      catchError(error => this.errorHandler.handleError(error)));
  }

    /** To get SES applicationId to cancel and remove it from ttp external */
    public getSESApplicationId(userId: string): Observable<any> {

      if (!userId) {
        return this.errorHandler.handleError('Application Id (' + userId + ') is not valid.');
      }

      const requestURI = environment.uriEndpoint + 'v1/goesapp/dashboard/metadata/ges/' + userId;
      // This returns 200 or an exception
      return this.http.get(requestURI).pipe(
        map(res => {
          return res;
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }

    /** To allow applicants cancel their SES application */
    public cancelSESApplication(userId: string, appId: string): Observable<any> {

      if (!appId) {
        return this.errorHandler.handleError('Application Id (' + appId + ') is not valid.');
      }

      const requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/external/applications/' + appId + '/cancel';
      // This returns 200 or an exception
      return this.http.put(requestURI, null).pipe(
        map(res => {
          return true;
        }),
        catchError(error => this.errorHandler.handleError(error)));
    }

  public getLoadApplicationUrl(userId: string, loadAppRequest: LoadApplicationRequest): string {
    let requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/' + loadAppRequest.appId;

    if (loadAppRequest) {
      switch (loadAppRequest.viewAppUrlType) {
        case ViewAppUrlIndicator.acceptedByGES :  requestURI = environment.uriEndpoint +
                                                    'v1/goesapp/users/' + userId +
                                                    '/applications/ges/' + loadAppRequest.appId + '?source=' + loadAppRequest.source;
                                                  break;
        case ViewAppUrlIndicator.submittedToGES : requestURI = environment.uriEndpoint + 'v1/goesapp/users/' + userId + '/applications/submitted/' + loadAppRequest.appId + '?source=' + loadAppRequest.source + '&requestType=' + loadAppRequest.requestType ;
                                                  break;
      }
    }

    // console.log('Load application url : ', requestURI);
    return requestURI;
  }


  private validateEmploymentTimeline(step: Step): void {
    // create a set of events for all current and past employments
    let employmentEvents: tEvent[] = [];
    let employmentTimelineStart: tDate;
    let employmentTimelineEnd: tDate;

    const today = new Date();
    // const age = Utils.getAge(this.application.person.dob);
    const age = this.application.person.age;
    const year = today.getFullYear() - (age < 5 ? age : 5);
    employmentTimelineStart = new tDate(today.getMonth() + 1, year);
    employmentTimelineEnd = new tDate(today.getMonth() + 1, today.getFullYear());
    employmentEvents = [];
    for (const employment of this.application.employments){
        if (
            (employment.startMonth && employment.startYear && employment.isCurrent) ||
            (employment.startMonth && employment.startYear && employment.endMonth && employment.endYear)
        ) {
            // full year has been entered
            if (Number(employment.startYear) > 1000) {
                const start = new tDate(Number(employment.startMonth), Number(employment.startYear));
                const end = new tDate(
                    employment.isCurrent ? new Date().getMonth() + 1 : Number(employment.endMonth),
                    employment.isCurrent ? new Date().getFullYear() : Number(employment.endYear)
                );
                employmentEvents.push(new tEvent(start, end));
            }
        }
    }

    const points = this.timelineService.createTimelinePoints(employmentEvents, employmentTimelineStart, employmentTimelineEnd);
    const hasGap = this.timelineService.hasGap(points);
    if (hasGap){
        step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.EMPLOYMENT_HISTORY_GAP');
    }
  }

  private validateAddressTimeilne(step: Step): void {
    let isABTC = false;
    let isCardReplace = false;
    isABTC = this.application.programCode === 'AB' || this.application.requestType === 'AC';
    isCardReplace = this.application.requestType === 'CR';

    if (!isABTC && !isCardReplace){
        // create a set of events for all current and past addresses
        let addressEvents: tEvent[] = [];
        let addressTimelineStart: tDate;
        let addressTimelineEnd: tDate;

        const today = new Date();
        const dob = this.application.person.dob;
        const age = Utils.getAge(dob);
        const year = (age <= 5 ? dob.date.getUTCFullYear() : today.getFullYear() - 5);
        const month = (age <= 5 ? dob.date.getUTCMonth() : today.getMonth()) + 1;
        addressTimelineStart = new tDate(month, year);
        addressTimelineEnd = new tDate(today.getMonth() + 1, today.getFullYear());
        addressEvents = [];
        for (const address of this.application.addresses) {
            if (
                (address.startMonth && address.startYear && address.isCurrent) ||
                (address.startMonth && address.startYear && address.endMonth && address.endYear)
            ) {
                // full year has been entered
                if (Number(address.startYear) > 1000) {
                    const start = new tDate(Number(address.startMonth), Number(address.startYear));
                    // var end = new tDate(Number(address.endMonth), Number(address.endYear));
                    const end = new tDate(
                        address.isCurrent ? new Date().getMonth() + 1 : Number(address.endMonth),
                        address.isCurrent ? new Date().getFullYear() : Number(address.endYear)
                    );
                    addressEvents.push(new tEvent(start, end));
                }
            }
        }
        const points = this.timelineService.createTimelinePoints(addressEvents, addressTimelineStart, addressTimelineEnd);
        const hasGap = this.timelineService.hasGap(points);
        if (hasGap){
            step.errorSummary.summary.push('ERROR_MESSAGES.ADDRESSES.ADDRESS_HISTORY_GAP');
        }
    }
  }

  public showRequested(): boolean{
        let result = false;
        const requestedInfoCountries = SETTINGS.REQUESTED_INFO_COUNTRIES;
        const requestedInfoCodes: Array<string> = ['UP'];
        const lprExceptions: Array<string> = ['CA', 'US'];
        if ((this.application.primaryCitizenshipCountryIsoCode === 'MX' &&
            (this.application.programCode === 'UP' || this.application.programCode === 'NH')) ||
            (requestedInfoCountries.includes(this.application.primaryCitizenshipCountryIsoCode)
              && requestedInfoCodes.includes(this.application.programCode))
        ){
          if (!lprExceptions.includes(this.application.primaryCitizenshipLPRCountryIsoCode)){
            result = true;
          }
        }
        const country = this.refService.getCountryByIsoCode(this.application.primaryCitizenshipCountryIsoCode);
        if (!result &&
              !lprExceptions.includes(this.application.primaryCitizenshipLPRCountryIsoCode) &&
                  !requestedInfoCountries.includes(this.application.primaryCitizenshipCountryIsoCode) && this.application.programCode === 'UP' && country.geEligibility === 'PILOT'){
            result = true;
        }
        return result;
  }
}
