import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import {TextPattern } from './directives/textPattern.directive';
import { FaqBarComponent } from './faq-bar/index';
import { ToolbarComponent } from './toolbar/index';
import { OptionModalComponent } from './modals/option-modal/index';
import { TooltipComponent } from './tooltip/index';
import { NavbarComponent } from './navbar/index';
import { SectionHeaderComponent } from './section-header/index';
import { FooterComponent } from './footer/index';
import { NewsScrollerComponent } from './news-scroller/index';
import { InfoModalComponent } from './modals/info-modal/index';
import { SecondaryModalComponent } from './modals/secondary-modal/index';
import { NotifyModalComponent } from './modals/notify-modal/notify-modal.component';
import { SecurityModalComponent } from './modals/security-modal/index';
import { NeedHelpComponent } from './modals/need-help-modal/index';
import { SelectMoreComponent } from './selectmore/index';
import { DateSelectionComponent } from './date-selection/index';
import { AlertModalComponent } from './modals/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { TimelineComponent, TimelineService} from './timeline/index';
import { SpinnerComponent, SpinnerService } from './spinner/index';

import { ModalService } from './modals/modal.service';

import { ValueType } from './directives/valuetype.directive';
import { MatchValidatorDirective } from '../common/directives/match-validator.directive';
import { NoWhitespaceDirective } from './directives/no-whitespace.directive';

import { HelpFloaterComponent } from './help-floater/help-floater.component';
import { TtpDatePipe } from '../shared/pipes/date-format.pipe';
import { ProgramInfoModalComponent } from './modals/program-info-modal/index';
import { StateEligValidatorDirective } from './directives/state-elig-validator.directive';
import { ConsiderTsaModalComponent } from './modals/consider-tsa-modal/consider-tsa-modal.component';
import { TSASiteLeavingModalComponent } from './modals/tsa-site-leaving-modal/tsa-site-leaving-modal.component';
import { PrintModalComponent } from './modals/print-modal/print-modal.component';


/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        TranslateModule
    ],
    declarations: [
        FaqBarComponent,
        SecondaryModalComponent,
        OptionModalComponent,
        TooltipComponent,
        ToolbarComponent,
        NavbarComponent,
        SectionHeaderComponent,
        FooterComponent,
        NewsScrollerComponent,
        InfoModalComponent,
        AlertModalComponent,
        NotifyModalComponent,
        ConfirmModalComponent,
        TimelineComponent,
        SpinnerComponent,
        SecurityModalComponent,
        NeedHelpComponent,
        DateSelectionComponent,
        SelectMoreComponent,
        MatchValidatorDirective,
        ValueType,
        TextPattern,
        NoWhitespaceDirective,
        HelpFloaterComponent,
        TtpDatePipe,
        ProgramInfoModalComponent,
        StateEligValidatorDirective,
        ConsiderTsaModalComponent,
        TSASiteLeavingModalComponent,
        PrintModalComponent
    ],
    exports: [
        FaqBarComponent,
        SecondaryModalComponent,
        OptionModalComponent,
        TooltipComponent,
        ToolbarComponent,
        NavbarComponent,
        SectionHeaderComponent,
        FooterComponent,
        NewsScrollerComponent,
        InfoModalComponent,
        ConfirmModalComponent,
        AlertModalComponent,
        NotifyModalComponent,
        TimelineComponent,
        SpinnerComponent,
        SecurityModalComponent,
        NeedHelpComponent,
        SelectMoreComponent,
        ValueType,
        TextPattern,
        DateSelectionComponent,
        CommonModule,
        FormsModule,
        RouterModule,
        MatchValidatorDirective,
        NoWhitespaceDirective,
        HelpFloaterComponent,
        TtpDatePipe,
        ProgramInfoModalComponent,
        StateEligValidatorDirective,
        ConsiderTsaModalComponent,
        TSASiteLeavingModalComponent,
        PrintModalComponent
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ ModalService, SpinnerService, TimelineService ]
    };
  }
}
