import { Component, Input, Output, OnInit, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { KeyPair } from './keyPair';

/**
 * This class represents the selectmore component.
 */
@Component({
    selector: 'selectmore',
    templateUrl: 'selectmore.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})


export class SelectMoreComponent implements OnInit {

	@Input()
	collection: KeyPair[];

	@Input('alpha-sort')
	alphaSort: boolean;

	@Input('multi-select')
	multiSelect: boolean;

	@Input('selected')
	selectedItems: KeyPair[];
	@Output() selectedItemsChange = new EventEmitter<KeyPair[]>();

	private _sortLetter:string;
	private _originalCollection: KeyPair[];
	private _isFiltered: boolean = false;

	ngOnInit(): void {
		this._originalCollection = this.collection;
		if(!this.selectedItems) this.selectedItems = [];
		if(this.alphaSort) {
			this.collection = this.collection.sort(function(a, b){
				return a.description.localeCompare(b.description);
			});
		}
	}


	add(item: KeyPair): void {
		this.selectedItems.push(item);
		if(this._isFiltered) this.collection = this._originalCollection;
		this.selectedItemsChange.emit(this.selectedItems);
	}

	remove(item: KeyPair): void {
		var index = this.selectedItems.indexOf(item);
		this.selectedItems.splice(index, 1);
		this.selectedItemsChange.emit(this.selectedItems);
	}

	isNewGroup(item: KeyPair): boolean {
		if(item){
			if(item.description){
				var letter = item.description.charAt(0);
				if(letter != this._sortLetter){
					this._sortLetter = letter;
					return true;
				}
			}
		}
		return false;
	}

	isSelected(item: KeyPair): boolean {
		return this.selectedItems.find(x => x.value == item.value)? true: false;
	}

	getSortLetter(): string{
		return this._sortLetter;
	}

	filter(event: any):void {
		var criteria:string = event.target.value;
		if(criteria) {
			this._isFiltered = true;
			this.collection = this._originalCollection.filter(function(a){
				return a.description.toLowerCase().search(criteria.toLowerCase())>=0;
			});
		} else {
			this.collection = this._originalCollection;
		}
	}
}
