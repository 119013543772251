import { EnhancedModel } from './enhanced.model';

export class Reconsideration extends EnhancedModel {


    reconsiderationId : string = '';
    // requestId: string = '';
    program : string = '';
    reconsiderationStatus : string = '';
    PassId : string = '';
    requestReason : string='';
    submittedDateStr : string = '';

    constructor(obj:any) {
        super();
        if (obj instanceof Reconsideration) {
            this.mapClone(obj);
        }
        else if(obj instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(obj);
        }
        else {
            throw new Error("You must specify the correct parameter");
        }
    }

    public clone() {
        let cloned:Reconsideration = new Reconsideration(this);
        return cloned;
    }

    protected mapClone(recon:Reconsideration) {
        this.reconsiderationId = recon.reconsiderationId;
        /* TODO: Use ReconsiderationID Sequence number not generated Alpha char string  */
        // this.requestId = recon.requestId;
        // this.requestId = recon.reconsiderationId;
        this.program = recon.program;
        this.reconsiderationStatus = recon.reconsiderationStatus;
        this.PassId = recon.PassId;
        this.requestReason=recon.requestReason;
        this.submittedDateStr = recon.submittedDateStr;

          }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('reconsiderationId')) {
            this.reconsiderationId = jsonObject.reconsiderationId;
        }
         if (jsonObject.hasOwnProperty('program')) {
            this.program = jsonObject.program;
        }
        /* TODO: Use ReconsiderationID Sequence number not generated Alpha char string  */
        // if (jsonObject.hasOwnProperty('requestId')) {
        //     this.requestId = jsonObject.requestId;
        // }

        // if (jsonObject.hasOwnProperty('reconsiderationId')) {
        //     this.requestId = jsonObject.reconsiderationId;
        // }
        if (jsonObject.hasOwnProperty('reconsiderationStatus')) {
            this.reconsiderationStatus = jsonObject.reconsiderationStatus;
        }
        if (jsonObject.hasOwnProperty('PassId')) {
            this.PassId = jsonObject.PassId;
        }
        if (jsonObject.hasOwnProperty('requestReason')) {
            this.requestReason = jsonObject.requestReason;
        }
        if (jsonObject.hasOwnProperty('submittedDateStr')) {
            this.submittedDateStr = jsonObject.submittedDateStr;
        }
    }

}
