import { Country } from './country.model';
import { GoesDate } from './date.model';

// TODO Make driver license extend Document or get rid of DriversLicense
export class DriversLicense {

    docId: number;
    haveLicense: string;
    licenseNumber = '';
    countryOfIssuance: Country = new Country();
    stateOfIssuance = '';
    freeTextState = '';
    expirationDate: GoesDate = new GoesDate();
    lastName = '';
    suffix = '';
    firstName = '';
    middleName = '';
    dateOfBirth: GoesDate = new GoesDate();
    isCDL = '';
    isEDL = '';
    isHazmat = '';
    documentKey = '';

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof DriversLicense) {
                this.docId = jsonObject.docId;
                this.haveLicense = jsonObject.haveLicense;
                this.licenseNumber = jsonObject.licenseNumber;
                this.countryOfIssuance = jsonObject.countryOfIssuance;
                this.stateOfIssuance = jsonObject.stateOfIssuance;
                this.expirationDate = jsonObject.expirationDate;
                this.lastName = jsonObject.lastName;
                this.freeTextState = jsonObject.freeTextState;
                this.suffix = jsonObject.suffix;
                this.firstName = jsonObject.firstName;
                this.middleName = jsonObject.middleName;
                this.dateOfBirth = jsonObject.dateOfBirth;
                this.isCDL = jsonObject.isCDL;
                this.isEDL = jsonObject.isEDL;
                this.isHazmat = jsonObject.isHazmat;
                this.documentKey = jsonObject.documentKey;
            } else {
                // note that this.haveLicense is now set in application.model deserialization
                // this is a json object lets deserialize it
                // if (jsonObject.hasOwnProperty('hasLicense')) {
                //     if (typeof jsonObject.hasLicense === 'string') {
                //         this.haveLicense = jsonObject.hasLicense;
                //     } else if (typeof jsonObject.hasLicense === 'boolean') {
                //         if (jsonObject.hasLicense) {
                //             this.haveLicense = 'Y';
                //         } else {
                //             this.haveLicense = 'N';
                //         }
                //     }
                // } else {
                //     this.haveLicense = '';
                // }
                if (jsonObject.hasOwnProperty('docId')) {
                    this.docId = Number(jsonObject.docId);
                } else {
                    this.docId = null;
                }

                if (jsonObject.hasOwnProperty('cdlInd')) {
                    if (typeof jsonObject.cdlInd === 'string') {
                        this.isCDL = jsonObject.cdlInd;
                    } else if (typeof jsonObject.cdlInd === 'boolean') {
                        if (jsonObject.cdlInd) {
                            this.isCDL = 'Y';
                        } else {
                            this.isCDL = 'N';
                        }
                    } else {
                        this.isCDL = '';
                    }
                } else {
                    this.isCDL = '';
                }
                if (jsonObject.hasOwnProperty('countryOfIssuanceIsoCode')) {
                    this.countryOfIssuance = new Country();
                    this.countryOfIssuance.isoCountryCode = jsonObject.countryOfIssuanceIsoCode;
                } else {
                    this.countryOfIssuance = new Country();
                }
                if (jsonObject.hasOwnProperty('dateOfBirth')) {
                    this.dateOfBirth = new GoesDate(null, null, null, jsonObject.dateOfBirth);
                } else {
                    this.dateOfBirth = new GoesDate();
                }
                if (jsonObject.hasOwnProperty('edlInd')) {
                    if (typeof jsonObject.edlInd === 'string') {
                        this.isEDL = jsonObject.edlInd;
                    } else if (typeof jsonObject.edlInd === 'boolean') {
                        if (jsonObject.edlInd) {
                            this.isEDL = 'Y';
                        } else {
                            this.isEDL = 'N';
                        }
                    } else {
                        this.isEDL = '';
                    }
                } else {
                    this.isEDL = '';
                }
                if (jsonObject.hasOwnProperty('expiration')) {
                    this.expirationDate = new GoesDate(null, null, null, jsonObject.expiration);
                } else {
                    this.expirationDate = new GoesDate();
                }
                if (jsonObject.hasOwnProperty('freeTextState')) {
                    this.freeTextState = jsonObject.freeTextState;
                }
                if (jsonObject.hasOwnProperty('stateOfIssuance')) {
                    this.stateOfIssuance = jsonObject.stateOfIssuance;
                } else {
                    this.stateOfIssuance = '';
                }
                if (jsonObject.hasOwnProperty('givenName')) {
                    this.firstName = jsonObject.givenName;
                } else {
                    this.firstName = '';
                }
                if (jsonObject.hasOwnProperty('hazmatInd')) {
                    if (typeof jsonObject.hazmatInd === 'string') {
                        this.isHazmat = jsonObject.hazmatInd;
                    } else if (typeof jsonObject.hazmatInd === 'boolean') {
                        if (jsonObject.hazmatInd) {
                            this.isHazmat = 'Y';
                        } else {
                            this.isHazmat = 'N';
                        }
                    } else {
                        this.isHazmat = '';
                    }
                } else {
                    this.isHazmat = '';
                }
                if (jsonObject.hasOwnProperty('licenseNumber')) {
                    this.licenseNumber = jsonObject.licenseNumber;
                } else {
                    this.licenseNumber = '';
                }
                if (jsonObject.hasOwnProperty('middleName')) {
                    this.middleName = jsonObject.middleName;
                } else {
                    this.middleName = '';
                }
                if (jsonObject.hasOwnProperty('suffix')) {
                    this.suffix = jsonObject.suffix;
                } else {
                    this.suffix = '';
                }
                if (jsonObject.hasOwnProperty('surName')) {
                    this.lastName = jsonObject.surName;
                } else {
                    this.lastName = '';
                }
                if (jsonObject.hasOwnProperty('documentKey')) {
                    this.documentKey = jsonObject.documentKey;
                } else {
                    this.documentKey = '';
                }
            }
        } else {
            this.docId = null;
            this.haveLicense = '';
            this.licenseNumber = '';
            this.countryOfIssuance = new Country();
            this.stateOfIssuance = '';
            this.freeTextState = '';
            this.expirationDate = new GoesDate();
            this.lastName = '';
            this.suffix = '';
            this.firstName = '';
            this.middleName = '';
            this.dateOfBirth = new GoesDate();
            this.isCDL = '';
            this.isEDL = '';
            this.isHazmat = '';
            this.documentKey = '';
        }
    }

}
