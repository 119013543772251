import { AdditionalInfo } from './additional-info.model';
import { Person } from './person.model';
import { TravelHistory } from './travel-history.model';
import { DriversLicense } from './drivers-license.model';
import { Address } from './address';
import { Vehicle } from './vehicle.model';
import { Employment } from './employment';
import { Passport } from './passport.model';
import { ApplicationMinimumData } from './application-minimum-data.model';
import { Contact } from './contact.model';
import { EnhancedModel } from './enhanced.model';
import { AdditionalQuestion } from './additional-question.model';
import { CitizenshipDocuments } from './citizenship-documents.model';
import { ColombiaRequestedInfo } from './documents/colombia-requested-info.model';
import { IsraelRequestedInfo } from './documents/israel-requested-info.model';
import { MexicoRequestedInfo } from './documents/mexico-requested-info.model';
import { QatarRequestedInfo } from './documents/qatar-requested-info.model';
import { SaudiArabiaRequestedInfo } from './documents/saudi-arabia-requested-info.model';
import { SingaporeRequestedInfo } from './documents/singapore-requested-info.model';
import { UnitedKingdomRequestedInfo } from './documents/uk-requested-info.model';
import { ArgentinaRequestedInfo } from './documents/argentina-requested-info.model';
import { BrazilRequestedInfo } from './documents/brazil-requested-info.modal';
import { DominicanRepublicRequestedInfo } from './documents/dominican-republic-requested-info.modal';
import { UnitedArabEmiratesRequestedInfo } from './documents/united-arab-emirates-requested-info.modal';
import { TaiwanRequestedInfo } from './documents/taiwan-requested-info.model';

import { PilotRequestedInfo } from './documents/pilot-requested-info.model';
import { ProgramCodes, RequestType } from '../utils/enums';
import { Step } from './step';
import { TsaPreData } from './tsa-pre-data.model';

export class Application extends EnhancedModel {

  id: number = 0;

  // steps required to fill out the application (to support different types of applications)
  steps: Array<Step> = new Array<Step>();

  // person
  person: Person = new Person();

  // program info
  programCode: string = '';
  programConvertInd: boolean = null;
  feeAmount: number = 0;

  // documents
  citizenshipDocuments: Array<CitizenshipDocuments> = new Array<CitizenshipDocuments>();
  citizenshipDocumentsCompleted:boolean = false; // for the front-end only
  primaryCitizenshipLPRCountryIsoCode: string = '';
  primaryCitizenshipCountryIsoCode: string = '';
  coRequestedInfo: ColombiaRequestedInfo = new ColombiaRequestedInfo();
  ilRequestedInfo: IsraelRequestedInfo = new IsraelRequestedInfo();
  mxRequestedInfo: MexicoRequestedInfo = new MexicoRequestedInfo();
  qaRequestedInfo: QatarRequestedInfo = new QatarRequestedInfo();
  saRequestedInfo: SaudiArabiaRequestedInfo = new SaudiArabiaRequestedInfo();
  sgRequestedInfo: SingaporeRequestedInfo = new SingaporeRequestedInfo();
  ukRequestedInfo: UnitedKingdomRequestedInfo = new UnitedKingdomRequestedInfo();
  arRequestedInfo: ArgentinaRequestedInfo = new ArgentinaRequestedInfo();
  twRequestedInfo: TaiwanRequestedInfo = new TaiwanRequestedInfo();
  brRequestedInfo: BrazilRequestedInfo = new BrazilRequestedInfo();
  pilotRequestedInfo : PilotRequestedInfo = new PilotRequestedInfo();
  doRequestedInfo: DominicanRepublicRequestedInfo = new DominicanRepublicRequestedInfo();
  aeRequestedInfo: UnitedArabEmiratesRequestedInfo = new UnitedArabEmiratesRequestedInfo();

  // vehicles
  driveBorder: string = '';
  vehicleAlreadyRegistered: string = '';
  vehicleRegisterNow: string = '';
  vehicles: Array<Vehicle> = new Array<Vehicle>();

  // address history
  addresses: Array<Address> = new Array<Address>();
  // diffMailing: boolean = false;
  isMailingAddressResponse: string; // response to 'is mailing same as current address'
  mailing: Address = new Address();
  contact: Contact = new Contact();

  // employment history
  employments: Array<Employment> = new Array<Employment>();

  // additional information
  additionalInfo: AdditionalInfo = new AdditionalInfo();

  // travel history
  travelHistory: TravelHistory = new TravelHistory();

  // driver's license
  driversLicense: DriversLicense = new DriversLicense();

  // abtc information
  passports: Array<Passport> = new Array<Passport>();
  addAbtc: boolean = null;
  cardReplacementCode: string="";

  //in the case of standalone, what program its added to
  originalMembership : string = '';

  tsaPreData: TsaPreData;

  status: any = null; // todo: need datatype

  // application request info
  requestStatus: string = '';
  requestType: string  = '';
  isModified: boolean = false;

  // force required validations
  forceRequired: boolean = false;

  /**
   * You must construct this class with one of the listed datatypes
   * @param obj Application | ApplicationMinimumData | (json) Object
   */
  constructor(obj: any) {
    super();
    if (obj instanceof Application) {
      this.mapClone(obj);
    }
    else if(obj instanceof ApplicationMinimumData){
      this.setupNew(obj);
    }
    else if(obj instanceof Object) {
      // this must be a json object let's deserialize it
      this.mapJson(obj);
    } else {
      throw new Error("You must specify the correct parameter");
    }
  }

  public clone():Application {
    let cloned:Application = new Application(this);
    return cloned;
  }

  public getCurrentStep(): Step {
    if(this.steps)
      return this.steps.find(x => x.isCurrent);
    else
      return null;
  }

  protected mapClone(application:Application){
    //this.abtcPassportNumber = application.abtcPassportNumber;
    this.addAbtc = application.addAbtc;
    this.additionalInfo = application.additionalInfo;
    this.addresses = application.addresses;
    this.arRequestedInfo = application.arRequestedInfo;
    this.coRequestedInfo = application.coRequestedInfo;
    this.contact = application.contact;
    // this.diffMailing = application.diffMailing;
    this.driveBorder = application.driveBorder;
    this.driversLicense = application.driversLicense;
    this.employments = application.employments;
    this.feeAmount = application.feeAmount;
    this.id = application.id;
    this.ilRequestedInfo = application.ilRequestedInfo;
    this.primaryCitizenshipLPRCountryIsoCode = application.primaryCitizenshipLPRCountryIsoCode;
    this.mailing = application.mailing;
    this.mxRequestedInfo = application.mxRequestedInfo;
    this.person = application.person;
    this.primaryCitizenshipCountryIsoCode = application.primaryCitizenshipCountryIsoCode;
    this.programCode = application.programCode;
    this.programConvertInd = application.programConvertInd;
    this.qaRequestedInfo = application.qaRequestedInfo;
    this.saRequestedInfo = application.saRequestedInfo;
    this.sgRequestedInfo = application.sgRequestedInfo;
    this.twRequestedInfo = application.twRequestedInfo;
    this.pilotRequestedInfo = application.pilotRequestedInfo;
    this.travelHistory = application.travelHistory;
    this.ukRequestedInfo = application.ukRequestedInfo;
    this.vehicleAlreadyRegistered = application.vehicleAlreadyRegistered;
    this.vehicleRegisterNow = application.vehicleRegisterNow;
    this.vehicles = application.vehicles;
    this.citizenshipDocuments = application.citizenshipDocuments;
    this.citizenshipDocumentsCompleted = application.citizenshipDocumentsCompleted;

    this.requestType = application.requestType;
    this.requestStatus = application.requestStatus;
    this.cardReplacementCode = application.cardReplacementCode;
    this.tsaPreData = application.tsaPreData;
  }

  protected mapJson(jsonObject: any) {

    if (jsonObject.hasOwnProperty('requestType')) {
      this.requestType = jsonObject.requestType;
      if(RequestType.abtcRequestTypes.includes(this.requestType)){
          this.addAbtc = false;
      }
    }

    if (jsonObject.hasOwnProperty('requestStatus')) {
      this.requestStatus = jsonObject.requestStatus;
    }
    if (jsonObject.hasOwnProperty('membershipProgramCode')) {
      this.originalMembership = jsonObject.membershipProgramCode;
    }

    if (jsonObject.hasOwnProperty('programCodes')) {
      if(jsonObject.programCodes && jsonObject.programCodes.length === 2){
        for(let code of jsonObject.programCodes){
          if (code === 'AB'){
            if(!RequestType.abtcRequestTypes.includes(this.requestType)){
              this.addAbtc = true;
            }
          }
          else {
            this.programCode = code;
          }
        }
      } else if(jsonObject.programCodes && jsonObject.programCodes.length === 1) {
        this.programCode = jsonObject.programCodes[0];
      }
    }

    if (jsonObject.hasOwnProperty('additionalQuestions')) {
        jsonObject.additionalQuestions.forEach((question: any) => {
            // TODO: map the other questions here, check the code then
            // set the appropriate property to the resposne
            let ttpQuestion = new AdditionalQuestion(question);
            this.additionalInfo.questionList.push(ttpQuestion);

        });
    }

    if (jsonObject.hasOwnProperty('currentAddress')) {
      let address = new Address(jsonObject.currentAddress);
      address.isCurrent = true;
      this.addresses.push(address);
    }

    if (jsonObject.hasOwnProperty('vehicleQuestions')) {

        for(let vehicleQuestion of jsonObject['vehicleQuestions']){
            if(vehicleQuestion.code === 'VDR'){
                this.driveBorder = vehicleQuestion.response;
            }
            if(vehicleQuestion.code === 'VAC'){
                this.vehicleAlreadyRegistered = vehicleQuestion.response;
            }
            if(vehicleQuestion.code === 'VRG'){
                this.vehicleRegisterNow = vehicleQuestion.response;
            }
        }
    }

    if (jsonObject.hasOwnProperty('addressHistory')) {
      if (jsonObject.addressHistory !== null) {
        jsonObject.addressHistory.forEach((address: any) => {
          this.addresses.push(new Address(address));
        });
      }
    }

    if (jsonObject.hasOwnProperty('currentEmployements')) {
      if (jsonObject.currentEmployements !== null) {
        jsonObject.currentEmployements.forEach((employment: any) => {
            let tempEmployment = new Employment(employment);
            tempEmployment.isCurrent = true;
            this.employments.push(tempEmployment);
        });
      }
    }

    if (jsonObject.hasOwnProperty('pastEmployements')) {
      if (jsonObject.pastEmployements !== null) {
        jsonObject.pastEmployements.forEach((employment: any) => {
            let passEmploment = new Employment(employment);
            passEmploment.isCurrent = false;
            this.employments.push(passEmploment);
        });
      }
    }

    if (jsonObject.hasOwnProperty('differentPrimaryResidenceExplantaion')) {
      // TODO: this currently doesn't map to anything
    }

    if (jsonObject.hasOwnProperty('documentsByCitizenshipCountry')) {
      if (jsonObject.documentsByCitizenshipCountry !== null) {
        jsonObject.documentsByCitizenshipCountry.forEach((citizenshipDocuments: any) => {
          let temp : CitizenshipDocuments = new CitizenshipDocuments(citizenshipDocuments);
          this.citizenshipDocuments.push(temp);
        });
      }
    }

    if (jsonObject.hasOwnProperty('license')) {
      this.driversLicense = new DriversLicense(jsonObject.license);
    }

    if(jsonObject.hasOwnProperty('driverLicenseResponse')){
      if(this.programCode === ProgramCodes.FastNorth || this.programCode === ProgramCodes.FastSouth ){
        // default to yes for FAST
        this.driversLicense.haveLicense = 'Y';
      } else {
        this.driversLicense.haveLicense = jsonObject.driverLicenseResponse;
      }
    }

    // if (jsonObject.hasOwnProperty('hasLicense')) {
    //   if (jsonObject.hasLicense) {
    //     if (jsonObject.hasOwnProperty('license')) {
    //       this.driversLicense = new DriversLicense(jsonObject.license);
    //       this.driversLicense.haveLicense = 'Y';
    //     }
    //   }
    //   else {
    //     //If program code is fastNorth or fastSouth, prepopulate haveLicense to YES
    //     this.driversLicense = new DriversLicense();
    //     if(this.programCode != ProgramCodes.FastNorth &&
    //        this.programCode != ProgramCodes.FastSouth ){
    //         this.driversLicense.haveLicense = 'N';
    //     }
    //     else {
    //         this.driversLicense.haveLicense = 'Y';
    //     }
    //   }
    // }

    if (jsonObject.hasOwnProperty('id')) {
      this.id = Number(jsonObject.id);
    }

    if (jsonObject.hasOwnProperty('partnerPropertiesDto')) {
      this.arRequestedInfo = new ArgentinaRequestedInfo(jsonObject.partnerPropertiesDto);
      this.coRequestedInfo = new ColombiaRequestedInfo(jsonObject.partnerPropertiesDto);
      this.ilRequestedInfo = new IsraelRequestedInfo(jsonObject.partnerPropertiesDto);
      this.mxRequestedInfo = new MexicoRequestedInfo(jsonObject.partnerPropertiesDto);
      this.qaRequestedInfo = new QatarRequestedInfo(jsonObject.partnerPropertiesDto);
      this.saRequestedInfo = new SaudiArabiaRequestedInfo(jsonObject.partnerPropertiesDto);
      this.sgRequestedInfo = new SingaporeRequestedInfo(jsonObject.partnerPropertiesDto);
      this.twRequestedInfo = new TaiwanRequestedInfo(jsonObject.partnerPropertiesDto);
      this.ukRequestedInfo = new UnitedKingdomRequestedInfo(jsonObject.partnerPropertiesDto);
      this.pilotRequestedInfo = new PilotRequestedInfo(jsonObject.partnerPropertiesDto);
      this.brRequestedInfo = new BrazilRequestedInfo(jsonObject.partnerPropertiesDto);
      this.doRequestedInfo = new DominicanRepublicRequestedInfo(jsonObject.partnerPropertiesDto);
      this.aeRequestedInfo = new UnitedArabEmiratesRequestedInfo(jsonObject.partnerPropertiesDto);
    }

    if (jsonObject.hasOwnProperty('mailingAddress')) {
      this.mailing = new Address(jsonObject.mailingAddress);
    }

    if(jsonObject.hasOwnProperty('isMailingAddressResponse')){
          this.isMailingAddressResponse = jsonObject.isMailingAddressResponse;
    }

    if (jsonObject.hasOwnProperty('personalInfo')) {
      this.person = new Person(jsonObject.personalInfo);
    }

    if (jsonObject.hasOwnProperty('personUsContactDto')) {
      this.contact = new Contact(jsonObject.personUsContactDto);
    }

    if (jsonObject.hasOwnProperty('primaryCitizenshipLPRCountryIsoCode')) {
      if(jsonObject.primaryCitizenshipLPRCountryIsoCode && jsonObject.primaryCitizenshipLPRCountryIsoCode.length > 0){
        this.primaryCitizenshipLPRCountryIsoCode = jsonObject.primaryCitizenshipLPRCountryIsoCode;
      } else {
        this.primaryCitizenshipLPRCountryIsoCode = "";
      }
    }

    if (jsonObject.hasOwnProperty('primaryCitizenshipCountryIsoCode')) {
      if(jsonObject.primaryCitizenshipCountryIsoCode && jsonObject.primaryCitizenshipCountryIsoCode.length > 0){
        this.primaryCitizenshipCountryIsoCode = jsonObject.primaryCitizenshipCountryIsoCode;
      } else {
        this.primaryCitizenshipCountryIsoCode = "US";
      }
    }

    if (jsonObject.hasOwnProperty('programConvertInd')) {
      this.programConvertInd = jsonObject.programConvertInd;
    }

    if (jsonObject.hasOwnProperty('status')) {
      this.status = jsonObject.status;
    }

    if (jsonObject.hasOwnProperty('vehicles')) {
      if (jsonObject.vehicles !== null) {
        jsonObject.vehicles.forEach((vehicle: any) => {
            let tempVehicle = new Vehicle(vehicle);
          this.vehicles.push(tempVehicle);
        });
      }
    }

    this.travelHistory = new TravelHistory(jsonObject);
    if(jsonObject.hasOwnProperty('visitedCountriesResponse')){
      this.travelHistory.haveYouTraveled = jsonObject.visitedCountriesResponse;
    }

    if (jsonObject.hasOwnProperty('cardReplacementCode')) {
      this.cardReplacementCode = jsonObject.cardReplacementCode;
    }

    if (jsonObject.hasOwnProperty('tsaPreData')){
      this.tsaPreData = new TsaPreData(jsonObject.tsaPreData);
    }
  }

  protected setupNew(data: ApplicationMinimumData){

    this.primaryCitizenshipCountryIsoCode = data.primaryCountry.isoCountryCode;
    this.programCode = data.programCode;
    let citizenship = new CitizenshipDocuments();

    citizenship.country = data.primaryCountry;
    this.citizenshipDocuments.push(citizenship);

  }

}
