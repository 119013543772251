
<div id="notifyInfo" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title flex-fill" [innerHTML]="title | translate"></h4>
        <button type="button" class="ttp-btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="translateSrc | translate"></p>
      </div>
      <div class="modal-footer justify-content-center">
  	  	<div class="col-12 col-lg-4">
        	<button id="notifyBtn" type="button" class="btn d-block w-100 btn-nowrap btn-primary">{{buttonText | translate}}</button>
      	</div>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
