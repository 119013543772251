
export class ApplicationData {

  json:any;

  constructor() {
    this.json = {
      'additionalQuestions': [],
      'addressHistory': [],
      'currentAddress': {},
      'currentEmployements': [],
      'differentPrimaryResidenceExplanation': '',
      'hasLicense': false,
      'id': 0,
      'license': {},
      'mailingAddress': {},
      'marketingSurveyAnswerCode': '',
      'pastEmployements': [],
      'personalInfo': {},
      'primaryResidenceCountry': '',
      'programCode': '',
      'programConvertInd': false,
      'status': {
        'errorCode': 0,
        'errorMessageIdentifier': ''
      },
      'vehicles': [],
      'visitedCountries': [],
      'tsaPreData': {}

    };

    /* full data points */
        // {
        //   'additionalCitizenships': {},
        //   'additionalQuestions': [
        //     {
        //       'code': '',
        //       'country': '',
        //       'countryFlag': '',
        //       'details': '',
        //       'response': ''
        //     }
        //   ],
        //   'addressHistory': [
        //     {
        //       'city': '',
        //       'country': '',
        //       'endDate': '',
        //       'postalCode': '',
        //       'startDate': '',
        //       'state': '',
        //       'street1Address': '',
        //       'street2Address': ''
        //     }
        //   ],
        //   'admissibilityDocuments': {
        //     'birthCertificates': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'certificatesOfIndianStatus': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'naturalizationCertificates': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'passports': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'permanentResidentCards': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'studentPermits': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'visas': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ]
        //   },
        //   'currentAddress': {
        //     'city': '',
        //     'country': '',
        //     'endDate': '',
        //     'postalCode': '',
        //     'startDate': '',
        //     'state': '',
        //     'street1Address': '',
        //     'street2Address': ''
        //   },
        //   'currentEmployements': [
        //     {
        //       'city': '',
        //       'country': '',
        //       'employer': '',
        //       'employmentStatus': '',
        //       'endDate': '',
        //       'occupation': '',
        //       'phoneCountryCode': '',
        //       'phoneExtension': '',
        //       'phoneFormat': '',
        //       'phoneNumber': '',
        //       'postalCode': '',
        //       'startDate': '',
        //       'state': '',
        //       'street1Address': '',
        //       'street2Address': ''
        //     }
        //   ],
        //   'differentPrimaryResidenceExplanation': '',
        //   'hasLicense': false,
        //   'id': 0,
        //   'license': {
        //     'cdlInd': false,
        //     'citizenshipProofInd': false,
        //     'countryOfIssuance': '',
        //     'dateOfBirth': '',
        //     'dateOfIssuance': '',
        //     'documentNumber': '',
        //     'edlInd': false,
        //     'expiration': '',
        //     'freeTextState': '',
        //     'givenName': '',
        //     'hazmatInd': false,
        //     'issuingAuthority': '',
        //     'licenseNumber': '',
        //     'middleName': '',
        //     'stateOfIssuance': '',
        //     'suffix': '',
        //     'surName': ''
        //   },
        //   'mailingAddress': {
        //     'city': '',
        //     'country': '',
        //     'endDate': '',
        //     'postalCode': '',
        //     'startDate': '',
        //     'state': '',
        //     'street1Address': '',
        //     'street2Address': ''
        //   },
        //   'marketingSurveyAnswerCode': '',
        //   'pastEmployements': [
        //     {
        //       'city': '',
        //       'country': '',
        //       'employer': '',
        //       'employmentStatus': '',
        //       'endDate': '',
        //       'occupation': '',
        //       'phoneCountryCode': '',
        //       'phoneExtension': '',
        //       'phoneFormat': '',
        //       'phoneNumber': '',
        //       'postalCode': '',
        //       'startDate': '',
        //       'state': '',
        //       'street1Address': '',
        //       'street2Address': ''
        //     }
        //   ],
        //   'personalInfo': {
        //     'cityOfBirth': '',
        //     'countryOfBirth': '',
        //     'dateOfBirth': '',
        //     'emailAddress': '',
        //     'eyeColor': '',
        //     'firstName': '',
        //     'firstNameAliases': [
        //       ''
        //     ],
        //     'gender': '',
        //     'heightInInches': 0,
        //     'lastName': '',
        //     'lastNameAliases': [
        //       ''
        //     ],
        //     'middleName': '',
        //     'notificationLanguagePreference': '',
        //     'phoneNumbers': [
        //       {
        //         'countryCode': '',
        //         'extension': '',
        //         'format': '',
        //         'number': '',
        //         'type': ''
        //       }
        //     ],
        //     'stateOfBirth': '',
        //     'suffix': ''
        //   },
        //   'primaryCitizenship': {
        //     'birthCertificates': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'certificatesOfIndianStatus': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'citizenshipCards': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'country': '',
        //     'naturalizationCertificates': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ],
        //     'passports': [
        //       {
        //         'citizenshipProofInd': false,
        //         'countryOfIssuance': '',
        //         'dateOfBirth': '',
        //         'dateOfIssuance': '',
        //         'documentNumber': '',
        //         'expiration': '',
        //         'freeTextState': '',
        //         'givenName': '',
        //         'issuingAuthority': '',
        //         'middleName': '',
        //         'stateOfIssuance': '',
        //         'suffix': '',
        //         'surName': ''
        //       }
        //     ]
        //   },
        //   'primaryResidenceCountry': '',
        //   'programCode': '',
        //   'programConvertInd': false,
        //   'status': {
        //     'errorCode': 0,
        //     'errorMessageIdentifier': ''
        //   },
        //   'vehicles': [
        //     {
        //       'color': '',
        //       'isGovernmentIssuedPlate': false,
        //       'licenseCountryOfIssuance': '',
        //       'licensePlateNumber': '',
        //       'make': '',
        //       'model': '',
        //       'owner': {
        //         'apartmentOrSuiteNumber': '',
        //         'city': '',
        //         'companyName': '',
        //         'country': '',
        //         'dateOfBirth': '',
        //         'firstName': '',
        //         'gender': '',
        //         'lastName': '',
        //         'middleName': '',
        //         'phoneExtension': '',
        //         'phoneFormat': '',
        //         'phoneNumber': '',
        //         'postalCode': '',
        //         'state': '',
        //         'street1Address': '',
        //         'streetName': '',
        //         'streetNumber': '',
        //         'suffix': ''
        //       },
        //       'ownerType': '',
        //       'stateOfIssuance': '',
        //       'vin': '',
        //       'year': 0
        //     }
        //   ],
        //   'visitedCountries': []
        // };
  } // constuctor
}
