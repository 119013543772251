export class Phone {

  format: string;
  type: string; // W=Work, M=Mobile, H=Home
  number: string;
  extension: string;
  countryCode : string ; //international phone code ex '1'

  constructor(jsonObject: any = null) {
    if (jsonObject !== null) {
      if (jsonObject instanceof Phone) {
        this.format = jsonObject.format;
        this.type = jsonObject.type;
        this.number = jsonObject.number;
        this.extension = jsonObject.extension;
        this.countryCode = jsonObject.countryCode;
      } else {
        // this is a json object lets deserialize it
        if (jsonObject.hasOwnProperty('phoneCountryCode')) {
          this.countryCode = jsonObject.phoneCountryCode;
        } else {
          this.countryCode = '';
        }
        if (jsonObject.hasOwnProperty('phoneExtension')) {
          this.extension = jsonObject.phoneExtension;
        } else {
          this.extension = '';
        }
        if (jsonObject.hasOwnProperty('phoneFormat')) {
          this.format = jsonObject.phoneFormat;
        } else {
          this.format = '';
        }
        if (jsonObject.hasOwnProperty('phoneNumber')) {
          this.number = jsonObject.phoneNumber;
          if(this.number){
            this.number = this.number.replace(' ', ''); // remove any spaces
          }
        } else {
          this.number = '';
        }
        this.type = '';
      }
    } else {
      this.type = '';
      this.countryCode = '';
      this.extension = '';
      this.format = '';
      this.number = '';
    }
  }

}
