import { GoesDate } from './date.model';
import { Address } from './address';

export class Vehicle {
    gesId: number;
    make: string;
    model: string;
    year: number;
    color: string;
    vin: string;
    licensePlateNumber: string;
    licenseCountryOfIssuance: string;
    govtLicensePlate: string;
    stateProvinceOfIssuance: string;
    owner: string;
    ownerLastName: string;
    ownerFirstName: string;
    ownerMiddleName: string;
    ownerDateOfBirth: GoesDate = new GoesDate();
    ownerPhoneCountryCode: string;
    ownerPhoneFormat: string;
    ownerPhoneNumber: string;
    ownerPhoneExt: string;
    businessName: string;
    businessAddress: Address;
    ownerGender: string;

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof Vehicle) {
                this.gesId = jsonObject.gesId;
                this.make = jsonObject.make;
                this.model = jsonObject.model;
                this.year = jsonObject.year;
                this.color = jsonObject.color;
                this.vin = jsonObject.vin;
                this.licensePlateNumber = jsonObject.licensePlateNumber;
                this.licenseCountryOfIssuance = jsonObject.licenseCountryOfIssuance;
                this.govtLicensePlate = jsonObject.govtLicensePlate;
                this.stateProvinceOfIssuance = jsonObject.stateProvinceOfIssuance;
                this.owner = jsonObject.owner;
                this.ownerLastName = jsonObject.ownerLastName;
                this.ownerFirstName = jsonObject.ownerFirstName;
                this.ownerMiddleName = jsonObject.ownerMiddleName;
                this.ownerDateOfBirth = jsonObject.ownerDateOfBirth;
                this.ownerPhoneFormat  = jsonObject.ownerPhoneFormat;
                this.ownerPhoneCountryCode = jsonObject.ownerPhoneCountryCode;
                this.ownerPhoneNumber = jsonObject.ownerPhoneNumber;
                this.ownerPhoneExt = jsonObject.ownerPhoneExt;
                this.businessName = jsonObject.businessName;
                this.businessAddress = jsonObject.businessAddress;
                this.ownerGender = jsonObject.ownerGender;
            } else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('id')) {
                    this.gesId = jsonObject.gesId;
                }
                if (jsonObject.hasOwnProperty('color')) {
                    this.color = jsonObject.color;
                } else {
                    this.color = '';
                }
                if (jsonObject.hasOwnProperty('isGovernmentIssuedPlate')) {
                    if (typeof jsonObject.isGovernmentIssuedPlate === 'string') {
                        this.govtLicensePlate = (jsonObject.isGovernmentIssuedPlate === 'true') ? 'Y' : 'N';
                    } else if (typeof jsonObject.isGovernmentIssuedPlate === 'boolean') {
                        if (jsonObject.isGovernmentIssuedPlate) {
                            this.govtLicensePlate = 'Y';
                        } else {
                            this.govtLicensePlate = 'N';
                        }
                    } else {
                        this.govtLicensePlate = '';
                    }
                } else {
                    this.govtLicensePlate = '';
                }
                if (jsonObject.hasOwnProperty('licenseCountryOfIssuance')) {
                    this.licenseCountryOfIssuance = jsonObject.licenseCountryOfIssuance;
                } else {
                    this.licenseCountryOfIssuance = '';
                }
                if (jsonObject.hasOwnProperty('licensePlateNumber')) {
                    this.licensePlateNumber = jsonObject.licensePlateNumber;
                } else {
                    this.licensePlateNumber = '';
                }
                if (jsonObject.hasOwnProperty('make')) {
                    this.make = jsonObject.make;
                } else {
                    this.make = '';
                }
                if (jsonObject.hasOwnProperty('model')) {
                    this.model = jsonObject.model;
                } else {
                    this.model = '';
                }

                if (jsonObject.hasOwnProperty('ownerCode')) {
                    this.owner = jsonObject.ownerCode === 'Y' ? 'A' : '';
                } else {
                    this.owner = '';
                }

                if (this.owner === '' && jsonObject.hasOwnProperty('owner')) {
                    let owner = jsonObject.owner;
                    if (owner.hasOwnProperty('ownerTypeCode')) {
                        let ownerTypeCode = owner.ownerTypeCode;
                        //console.log('Owner Type Code:', owner.ownerTypeCode);
                        if (ownerTypeCode === 'I') {
                          this.owner = 'I';
                        }
                        else if (ownerTypeCode === 'C'){
                          this.owner = 'B';
                        }
                        else {
                          this.owner = ownerTypeCode;
                        }
                    }
                    this.businessAddress = new Address(owner);
                    if (owner.hasOwnProperty('companyName')) {
                        this.businessName = owner.companyName;
                    } else {
                        this.businessName = '';
                    }
                    if (owner.hasOwnProperty('dateOfBirth')) {
                        if(owner.dateOfBirth){
                            this.ownerDateOfBirth = new GoesDate(null, null, null, owner.dateOfBirth);
                        }
                    } else {
                        this.ownerDateOfBirth = new GoesDate();
                    }
                    if (owner.hasOwnProperty('firstName')) {
                        this.ownerFirstName = owner.firstName;
                    } else {
                        this.ownerFirstName = '';
                    }
                    if (owner.hasOwnProperty('gender')) {
                        this.ownerGender = owner.gender;
                    } else {
                        this.ownerGender = '';
                    }
                    if (owner.hasOwnProperty('lastName')) {
                        this.ownerLastName = owner.lastName;
                    } else {
                        this.ownerLastName = '';
                    }
                    if (owner.hasOwnProperty('middleName')) {
                        this.ownerMiddleName = owner.middleName;
                    } else {
                        this.ownerMiddleName = '';
                    }
                    if( owner.hasOwnProperty('phoneCountryCode')) {
                        this.ownerPhoneCountryCode = owner.phoneCountryCode;
                    } else {
                        this.ownerPhoneCountryCode = '';
                    }
                    if (owner.hasOwnProperty('phoneExtension')) {
                        this.ownerPhoneExt = owner.phoneExtension;
                    } else {
                        this.ownerPhoneExt = '';
                    }
                    if (owner.hasOwnProperty('phoneFormat')) {
                        this.ownerPhoneFormat = owner.phoneFormat;
                    } else {
                        this.ownerPhoneFormat = '';
                    }
                    if (owner.hasOwnProperty('phoneNumber')) {
                        this.ownerPhoneNumber = owner.phoneNumber;
                    } else {
                        this.ownerPhoneNumber = '';
                    }
                } else {
                    this.businessAddress = new Address();
                    this.businessName = '';
                    this.ownerDateOfBirth = new GoesDate();
                    this.ownerFirstName = '';
                    this.ownerGender = '';
                    this.ownerLastName = '';
                    this.ownerMiddleName = '';
                    this.ownerPhoneCountryCode = '';
                    this.ownerPhoneExt = '';
                    this.ownerPhoneFormat = '';
                    this.ownerPhoneNumber = '';
                }
                if (jsonObject.hasOwnProperty('stateOfIssuance')) {
                    this.stateProvinceOfIssuance = jsonObject.stateOfIssuance;
                } else {
                    this.stateProvinceOfIssuance = '';
                }
                if (jsonObject.hasOwnProperty('vin')) {
                    this.vin = jsonObject.vin;
                } else {
                    this.vin = '';
                }
                if (jsonObject.hasOwnProperty('year')) {
                    this.year = Number(jsonObject.year);
                } else {
                    this.year = null;
                }
            }
        } else {
            this.gesId = 0;
            this.make = '';
            this.model = '';
            this.year = null;
            this.color = '';
            this.vin = '';
            this.licensePlateNumber = '';
            this.licenseCountryOfIssuance = '';
            this.govtLicensePlate = '';
            this.stateProvinceOfIssuance = '';
            this.owner = '';
            this.ownerLastName = '';
            this.ownerFirstName = '';
            this.ownerMiddleName = '';
            this.ownerDateOfBirth = new GoesDate();
            this.ownerPhoneCountryCode = '';
            this.ownerPhoneFormat  = '';
            this.ownerPhoneNumber = '';
            this.ownerPhoneExt = '';
            this.businessName = '';
            this.businessAddress = new Address();
            this.ownerGender = '';
        }
    }
}
