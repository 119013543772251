/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const bootstrap: any;

/**
 * This class represents the toolbar component.
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'tooltip',
    templateUrl: 'tooltip.component.html',
    host: { 'class': 'ms-auto' }, // This will only affect tooltips in flex container
    // eslint-disable-next-line @angular-eslint/prefer-standalone
    standalone: false
})

export class TooltipComponent implements AfterViewChecked {

    @ViewChild('ttButton') el: ElementRef;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('tooltip-text')
    ttText: string;

    @Input() ttLabel: string;

    currentLang: string;

    constructor(private translate: TranslateService) {}

    ngAfterViewChecked() {
        if (this.currentLang !== this.translate.currentLang) {
            this.translate.get(this.ttText).subscribe((res: string) => {
                $(this.el.nativeElement).attr('data-bs-content', res);

                // With Bootstrap 5 above by itself wasn't working.
                // Don't know why the create isntance with Bootstrap wasnt working
                const mypopover =  bootstrap.Popover.getOrCreateInstance(this.el.nativeElement);
                mypopover.setContent({
                  '.popover-body': res
                });

                if (!this.ttLabel) {
                  $(this.el.nativeElement).attr('aria-label', res);
                }
            });

            if (this.ttLabel) {
                this.translate.get(this.ttLabel).subscribe((res: string) => {
                  $(this.el.nativeElement).attr('aria-label', res);
              });
            }
            this.currentLang = this.translate.currentLang;
        }
        $(this.el.nativeElement).popover();
    }
}
