
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FAQService } from '../../common/services/faq.service';
import Utils from '../../common/utils/utils';

declare var $:any;

/**
 * This class represents the lazy loaded HomeComponent.
 */
@Component({
    selector: 'help-floater',
    templateUrl: 'help-floater.component.html',
    standalone: false
})

export class HelpFloaterComponent implements OnInit {

    private pageHasFAQ : boolean = false;

    constructor(
        private faqService : FAQService
      ) {
    }

    ngOnInit() {
        this.faqService.hasFaqSubj.subscribe(res => {
            if (res) {
                this.pageHasFAQ = res;
            }
        });
    }

    showHelpFloater() {
        return this.pageHasFAQ;
    }

    scrollTo(id : string): void {
        Utils.scrollUp(id);
    }
}
