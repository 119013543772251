import { Injectable } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { Address } from '../models/address';
import { StepStatus } from '../utils/enums';

@Injectable()
export class TemplateValidationService {

    /* reusable method for updating form validations and error messages */
    validateForm (form : NgForm, formErrors : any, validationMessages : any,  submitted : boolean) : any {
        if(!form) {return;}
        const tempForm : any  = form.form;

        for(const field in formErrors) {
            //reset error messages
            // formErrors[field] = [''];
            formErrors[field] = new Array<string>();
            // const control : any = tempForm.get(field);
            let control: any = form.form.get(field);

            if(control && !control.valid) {
                const messages: any = validationMessages[field];
                for(const key in control.errors ) {
                    //only add required error messages if the form's been submitted
                    if(key === 'required') {
                        if(submitted) {
                            formErrors[field].push(messages[key]);
                        }
                    } else if(key === 'pattern' || key==='match' ||  key === 'minlength'|| key==='stateEligibiltiy') {
                    //only add blur error messages if the control's been touched
                        if(control.touched || submitted) {
                            formErrors[field].push(messages[key]);
                        }
                    } else if(control.dirty) {
                        formErrors[field].push(messages[key]);
                    }
                }
            }
        }
        return formErrors;
    }

    // manually check maximum length
    private _checkMaxLength(control : any, field : string){
        let key = "maxlength";
        let elems = document.getElementsByName(field);
        if(elems && elems.length > 0){
            let element = elems[0];
            if(element.getAttribute(key)){
                let maxlength = parseInt(element.getAttribute(key));
                if(control && control.value) {
                    if(control.value.length > maxlength){
                        control.setErrors({ "maxlength" : true });
                        control.markAsDirty();
                        control.markAsTouched();
                    } else {
                        if(control.errors && control.errors.hasOwnProperty("maxlength")){
                            let totalErrors = Object.keys(control.errors).length;
                            if(totalErrors === 1){
                                control.setErrors(null);
                            }
                            delete control.errors.maxlength;
                        }
                    }
                }
            }
        }
    }

    /* validate all controls on the various forms used in a TTP application, set error
     * messages if control is invalid.
     * return value is valid, valid but not all required fields are filled, OR invalid */
    validateForm2(form : NgForm, formErrors : any, validationMessages : any,  showAllErrors : boolean) : StepStatus {
        let result = StepStatus.Valid;
        if(!form) { return StepStatus.Valid; }
        const tempForm : any  = form.form;
        for(const field in formErrors){
            formErrors[field] = new Array<string>(); //reset error messages
            const control:any = tempForm.get(field);
            //this._checkMaxLength(control, field); // manual maxlength check
            if(control && !control.valid){
                const messages:any = validationMessages[field];
                for(const key in control.errors){
                    if((control.touched && control.dirty) || (showAllErrors && key !== 'pattern' && key !== 'minlength') || key === 'stateEligibility'){
                        // only show errors if control is dirty or showAllErrors flag is set to true
                        if(messages && messages[key]){
                            formErrors[field].push(messages[key]);
                        } else {
                            formErrors[field].push('Field does not pass ' + key + ' validation.');
                        }
                    }
                    if(key !== 'required'){
                        // set result to invalid if not checking a required validation
                        result = StepStatus.Invalid;
                    }
                    if(result != StepStatus.Invalid){
                        // checking a required validation, so set result
                        // to valid but not all required fields are filled
                        result = StepStatus.MissingRequiredData;
                    }
                }
            }
        }
        // form is valid if no errors are found or if errors are only related to missing required fields
        return result;
    }

    /* Date validations ...
     * Specific for Month, Year dates */
    validateCardDates (cardObj : Address) : any {

            let dateFormErrors = {
                'startMonth' : new Array<string>(),
                'startYear' : new Array<string>(),
                'endMonth': new Array<string>(),
                'endYear': new Array<string>()
            };

            let dateErrors = false;

            if(cardObj.startMonth && cardObj.startYear){
                    if(Number(cardObj.startYear) < 1900){
                        dateFormErrors.startYear.push('ERROR_MESSAGES.GENERAL.YEAR_MIN');
                        dateErrors = true;

                    }
                    if(Number(cardObj.startYear) == new Date().getFullYear()){
                        if(Number(cardObj.startMonth) > new Date().getMonth()+1){
                            dateFormErrors.startYear.push('ERROR_MESSAGES.GENERAL.PRE_TODAY');
                            dateErrors = true;
                        }
                    }
                    if(Number(cardObj.startYear) > new Date().getFullYear()){
                        dateFormErrors.startYear.push('ERROR_MESSAGES.GENERAL.PRE_TODAY');
                        dateErrors = true;
                    }
                }

                if(cardObj.endMonth && cardObj.endYear){
                    if(Number(cardObj.endYear) < 1900){
                        dateFormErrors.endYear.push('ERROR_MESSAGES.GENERAL.YEAR_MIN');
                         dateErrors = true;
                    }
                    if(Number(cardObj.endYear) > new Date().getFullYear()){
                        dateFormErrors.endYear.push('ERROR_MESSAGES.GENERAL.PRE_TODAY');
                        dateErrors = true;
                        
                    }
                    if(Number(cardObj.endYear) == new Date().getFullYear()){
                        if(Number(cardObj.endMonth) > new Date().getMonth()+1){
                            dateFormErrors.endYear.push('ERROR_MESSAGES.GENERAL.PRE_TODAY');
                            dateErrors = true;
                        }
                    }
                }


                if (cardObj.endMonth && cardObj.endYear && cardObj.startMonth && cardObj.startYear){
                    if(Number(cardObj.endYear) < Number(cardObj.startYear)){
                        if(cardObj.isCurrent){
                           // dateFormErrors.startYear.push('ERROR_MESSAGES.GENERAL.PRE_TODAY');
                           // dateErrors = true;
                        }
                        else{
                            dateFormErrors.startYear.push('ERROR_MESSAGES.GENERAL.START_POST_END');
                            dateErrors = true;
                        }
                    }
                    if(Number(cardObj.endYear) === Number(cardObj.startYear)){
                        if(Number(cardObj.endMonth) < Number(cardObj.startMonth)){
                            if(cardObj.isCurrent){
                               // dateFormErrors.startYear.push('ERROR_MESSAGES.GENERAL.PRE_TODAY');
                               // dateErrors = true;
                            }
                            else{
                                dateFormErrors.startYear.push('ERROR_MESSAGES.GENERAL.START_POST_END');
                                dateErrors = true;
                            }
                        }
                    }
                }

        return dateFormErrors;

    }


    /* checks any non-required errors for Application workflow */
    validateAppForm (form : NgForm, formErrors : any) : boolean {
        const tempForm : any = form.form;

        for(const field in formErrors) {
            const control : any = tempForm.get(field);
            if(control && !control.valid) {
                for(const key in control.errors ) {
                    if(key !== 'required') {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    //accepts fieldName and form to update a control as dirty and touched for pattern validation checking
    markControlAsDirtyAndTouched(fieldName : string, form : NgForm){
        let tempForm : any = form.form;
        if(form.controls){
            let control : AbstractControl = tempForm.get(fieldName);
            if(control){
                control.markAsDirty();
                control.markAsTouched();
            }
        }
    }
}
