import { EnhancedModel } from './enhanced.model';

export class Membership extends EnhancedModel {
    program : string = '';
    membershipStatus : string = '';
    expirationDate : string = '';
    hasPrintedMembershipCard : boolean = false;
    cardStatus: string = '';
    renewalDate: string = '';
    stepCompleted : number = 9;


    canRenew : boolean = false;
  	canReapply : boolean = false;
  	canAddVehicle : boolean = false;
  	canActivateCard : boolean = false;
  	canReplaceCard : boolean = false;

    canReconsideration : boolean = false;
    gesApplicationId : string = '';

    hasCard : boolean = false; // Can be Printed/Activated/Terminated

    constructor(obj:any){
        super();
        if (obj instanceof Membership) {
            this.mapClone(obj);
        }
        else if(obj instanceof Object) {
            // this must be a json object let's deserialize it
            this.mapJson(obj);
        } else {
            throw new Error("You must specify the correct parameter");
        }
    }


    public clone() {
        let cloned:Membership = new Membership(this);
        return cloned;
    }

    protected mapClone(membership:Membership) {
        this.program = membership.program;
        this.membershipStatus = membership.membershipStatus;
        this.expirationDate = membership.expirationDate;
        this.hasPrintedMembershipCard = membership.hasPrintedMembershipCard;
        this.cardStatus = membership.cardStatus;
        this.renewalDate = membership.renewalDate;
        this.stepCompleted = membership.stepCompleted;

        this.canAddVehicle = membership.canAddVehicle;
        this.canRenew = membership.canRenew;
        this.canReapply = membership.canReapply;
        this.canReplaceCard = membership.canReplaceCard;
        this.canActivateCard = membership.canActivateCard;
        this.hasCard = membership.hasCard;
        this.canReconsideration = membership.canReconsideration;
        this.gesApplicationId = membership.gesApplicationId;
    }

    protected mapJson(jsonObject: any) {
        if (jsonObject.hasOwnProperty('program')) {
            this.program = jsonObject.program;
        }
        if (jsonObject.hasOwnProperty('membershipStatus')) {
            this.membershipStatus = jsonObject.membershipStatus;
        }
        if (jsonObject.hasOwnProperty('expirationDate')) {
            this.expirationDate = jsonObject.expirationDate;
        }
        if (jsonObject.hasOwnProperty('hasPrintedMembershipCard')) {
            this.hasPrintedMembershipCard = jsonObject.hasPrintedMembershipCard;
        }
        if (jsonObject.hasOwnProperty('cardStatus')) {
            this.cardStatus = jsonObject.cardStatus;
        }
        if (jsonObject.hasOwnProperty('membershipStatus')) {
            this.membershipStatus = this.mapStatus(jsonObject.membershipStatus);
        }
        if (jsonObject.hasOwnProperty('renewalDate')) {
            this.renewalDate = jsonObject.renewalDate;
        }
        if (jsonObject.hasOwnProperty('stepCompleted')) {
            this.stepCompleted = jsonObject.stepCompleted;
        }
        if (jsonObject.hasOwnProperty('canAddVehicle')) {
            this.canAddVehicle = jsonObject.canAddVehicle;
        }
        if (jsonObject.hasOwnProperty('canRenew')) {
            this.canRenew = jsonObject.canRenew;
        }
        if (jsonObject.hasOwnProperty('canReapply')) {
            this.canReapply = jsonObject.canReapply;
        }
        if (jsonObject.hasOwnProperty('canReplaceCard')) {
            this.canReplaceCard = jsonObject.canReplaceCard;
        }
        if (jsonObject.hasOwnProperty('canActivateCard')) {
            this.canActivateCard = jsonObject.canActivateCard;
        }
        if (jsonObject.hasOwnProperty('card') && jsonObject.card) {
            this.hasCard = true;
        }
        if (jsonObject.hasOwnProperty('canReconsideration')) {
            this.canReconsideration = jsonObject.canReconsideration;
        }

        if (jsonObject.hasOwnProperty('gesApplicationId')) {
            this.gesApplicationId = jsonObject.gesApplicationId;
        }

    }

    private mapStatus(status : string) : string {
        switch(status) {
            case "DE" :
                return "AP";
        }
        return status;
    }

}
