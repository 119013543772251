import { GoesDate } from './date.model';
import { Country } from './country.model';

export class Document {
    type: string;
    citizenshipProofInd: boolean;
    admissibilityProofInd: boolean;
    lprProofInd: boolean;
    docId: number;
    countryOfIssuance: Country;
    dateOfBirth: GoesDate;
    dateOfIssuance: GoesDate;
    documentNumber: string;
    expiration: GoesDate;
    freeTextState: string;
    givenName: string;
    issuingAuthority: string;
    middleName: string;
    stateOfIssuance: string; // Birth Certificate (BC)
    suffix: string;
    surName: string;
    registryGroup: string; // Certificate of Indian Status (IS)
    class: string; // Visa (V)
    aNumber: string; // Naturalization Certificate (NC)
    internalID: string; // identifier used internally for tracking new documents that have not had any data set yet.
    abtcInd: boolean = false;
    documentKey: string; // identifier for a document from GES

    membersAreValid:boolean = false; // only for front-end

    verified: string;
    bypassAccepted: boolean; // Only for the UI. Indicates that user decided to override failed verification.
    showBypassCheckbox: boolean; // Only for the UI. Indicates that user decided to override failed verification.
    nameDOBMismatch: boolean; // Only for UI. If true verification failed due to name/dob mismatch

    validatedIndicator : string = "T"; // T: NOT VALIDATED, Y : VALIDATED, O : OVERRIDDEN

    constructor(jsonObject: any = null) {
        if (jsonObject !== null) {
            if (jsonObject instanceof Document) {
                this.aNumber = jsonObject.aNumber;
                this.admissibilityProofInd = jsonObject.admissibilityProofInd;
                this.citizenshipProofInd = jsonObject.citizenshipProofInd;
                this.class = jsonObject.class;
                this.countryOfIssuance = jsonObject.countryOfIssuance;
                this.dateOfBirth = jsonObject.dateOfBirth;
                this.dateOfIssuance = jsonObject.dateOfIssuance;
                this.docId = jsonObject.docId;
                this.documentNumber = jsonObject.documentNumber;
                this.expiration = jsonObject.expiration;
                this.freeTextState = jsonObject.freeTextState;
                this.givenName = jsonObject.givenName;
                this.issuingAuthority = jsonObject.issuingAuthority;
                this.lprProofInd = jsonObject.lprProofInd;
                this.middleName = jsonObject.middleName;
                this.registryGroup = jsonObject.registryGroup;
                this.stateOfIssuance = jsonObject.stateOfIssuance;
                this.suffix = jsonObject.suffix;
                this.surName = jsonObject.surName;
                this.type = jsonObject.type;
                this.internalID = jsonObject.internalID;
                this.membersAreValid = jsonObject.membersAreValid;
                this.abtcInd = jsonObject.abtcInd;
                this.documentKey = jsonObject.documentKey;
                this.verified = jsonObject.verified;
                this.bypassAccepted = jsonObject.bypassAccepted;
            } else {
                // this is a json object let's deserialize it
                if (jsonObject.hasOwnProperty('type')) {
                    this.type = jsonObject.type;
                } else {
                    this.type = '';
                }
                if (jsonObject.hasOwnProperty('citizenshipProofInd')) {
                    this.citizenshipProofInd = jsonObject.citizenshipProofInd;
                } else {
                    this.citizenshipProofInd = false;
                }
                if (jsonObject.hasOwnProperty('admissibilityProofInd')) {
                    this.admissibilityProofInd = jsonObject.admissibilityProofInd;
                } else {
                    this.admissibilityProofInd = false;
                }
                if (jsonObject.hasOwnProperty('lprProofInd')) {
                    this.lprProofInd = jsonObject.lprProofInd;
                } else {
                    this.lprProofInd = false;
                }
                if (jsonObject.hasOwnProperty('docId')) {
                    this.docId = Number(jsonObject.docId);
                } else {
                    this.docId = null;
                }
                this.countryOfIssuance = new Country();
                if (jsonObject.hasOwnProperty('countryOfIssuanceIsoCode')) {
                    this.countryOfIssuance.isoCountryCode = jsonObject.countryOfIssuanceIsoCode;
                }

                // NOTE DriversLicense model has GoesDate already
                if (jsonObject.hasOwnProperty('dateOfBirth')) {
                    if (jsonObject.dateOfBirth instanceof GoesDate) {
                        this.dateOfBirth = jsonObject.dateOfBirth;
                    }
                    else {
                        this.dateOfBirth = new GoesDate(null, null, null, jsonObject.dateOfBirth);
                    }
                } else {
                    this.dateOfBirth = new GoesDate();
                }

                if (jsonObject.hasOwnProperty('dateOfIssuance')) {
                    if (jsonObject.dateOfIssuance instanceof GoesDate) {
                        this.dateOfIssuance = jsonObject.dateOfIssuance;
                    }
                    else {
                        this.dateOfIssuance = new GoesDate(null, null, null, jsonObject.dateOfIssuance);
                    }
                } else {
                    this.dateOfIssuance = new GoesDate();
                }


                if (jsonObject.hasOwnProperty('documentNumber')) {
                    this.documentNumber = jsonObject.documentNumber;
                    this.internalID = jsonObject.documentNumber;
                } else {
                    this.documentNumber = '';
                    this.internalID = Math.ceil(Math.random() * 1000000000000000).toString();
                }
                if (jsonObject.hasOwnProperty('expiration')) {
                    if (jsonObject.expiration instanceof GoesDate) {
                        this.dateOfIssuance = jsonObject.expiration;
                    }
                    else {
                        this.expiration = new GoesDate(null, null, null, jsonObject.expiration);
                    }
                } else {
                    this.expiration = new GoesDate();
                }
                if (jsonObject.hasOwnProperty('freeTextState')) {
                    this.freeTextState = jsonObject.freeTextState;
                } else {
                    this.freeTextState = '';
                }
                if (jsonObject.hasOwnProperty('givenName')) {
                    this.givenName = jsonObject.givenName;
                } else {
                    this.givenName = '';
                }
                // eliminating per review feedback
                // if (jsonObject.hasOwnProperty('issuingAuthority')) {
                //     this.issuingAuthority = jsonObject.issuingAuthority;
                // } else {
                //     this.issuingAuthority = '';
                // }
                if (jsonObject.hasOwnProperty('middleName')) {
                    this.middleName = jsonObject.middleName;
                } else {
                    this.middleName = '';
                }
                if (jsonObject.hasOwnProperty('stateOfIssuance')) {
                    this.stateOfIssuance = jsonObject.stateOfIssuance;
                } else {
                    this.stateOfIssuance = '';
                }
                if (jsonObject.hasOwnProperty('suffix')) {
                    this.suffix = jsonObject.suffix;
                } else {
                    this.suffix = '';
                }
                if (jsonObject.hasOwnProperty('surName')) {
                    this.surName = jsonObject.surName;
                } else {
                    this.surName = '';
                }
                if (jsonObject.hasOwnProperty('registryGroup')) {
                    this.registryGroup = jsonObject.registryGroup;
                } else {
                    this.registryGroup = '';
                }
                if (jsonObject.hasOwnProperty('visaClass')) {
                    this.class = jsonObject.visaClass;
                } else {
                    this.class = '';
                }
                if (jsonObject.hasOwnProperty('aNumber')) {
                    this.aNumber = jsonObject.aNumber;
                } else {
                    this.aNumber = '';
                }
                if (jsonObject.hasOwnProperty('abtcInd')) {
                    this.abtcInd = jsonObject.abtcInd;
                } else {
                    this.abtcInd = false;
                }

                if (jsonObject.hasOwnProperty('documentKey')) {
                    this.documentKey = jsonObject.documentKey;
                } else {
                    this.documentKey = '';
                }

                if (jsonObject.hasOwnProperty('verifyStatusCode')) {
                    this.verified = jsonObject.verifyStatusCode;
                } else {
                    this.verified = '';
                }

                if (jsonObject.hasOwnProperty('bypassAccepted')) {
                    this.bypassAccepted = jsonObject.bypassAccepted;
                }
            }
        } else {
            this.aNumber = '';
            this.admissibilityProofInd = false;
            this.citizenshipProofInd = false;
            this.class = '';
            this.countryOfIssuance = new Country();
            this.dateOfBirth = new GoesDate();
            this.dateOfIssuance = new GoesDate();
            this.docId = null;
            this.documentNumber = '';
            this.expiration = new GoesDate();
            this.freeTextState = '';
            this.givenName = '';
            this.issuingAuthority = '';
            this.lprProofInd = false;
            this.middleName = '';
            this.registryGroup = '';
            this.stateOfIssuance = '';
            this.suffix = '';
            this.surName = '';
            this.type = '';
            this.abtcInd = false;
            this.documentKey = '';
            this.verified = '';
            this.internalID = Math.ceil(Math.random() * 1000000000000000).toString();
            this.showBypassCheckbox = false;
            this.nameDOBMismatch = false;
        }
    }

    //helper method for removing empty items from existing list
    isEmpty() : boolean {
        if(!this.surName &&
           !this.middleName &&
           !this.givenName &&
           !this.dateOfBirth.getSimpleString() &&
           !this.documentNumber &&
           !this.dateOfIssuance.getSimpleString() &&
           !this.expiration.getSimpleString()){
            return true;
        }
        return false;
    }
}
