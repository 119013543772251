import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, of } from 'rxjs';

import { ErrorHandlerService } from '../services/error-handler.service';

import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class SystemMessagesService {
    sysMessages: any = {
        en : [],
        fr : [],
        es : []
    };
    sysMessagesObservable: any;

    // tslint:disable-next-line:variable-name
    private _sysPulling = false;

    sysMessagesUpdatedSubj: Subject<boolean>;

    constructor(private http: HttpClient,
                private errorHandler: ErrorHandlerService,
                private translateService: TranslateService) {

            // Clients should subscribe to this to get any changes to the data.
            this.sysMessagesUpdatedSubj = new Subject<boolean>();

            this.initData(this.translateService.currentLang);
            // if language selection is changed, fetch relevant reference data.
            this.translateService.onLangChange.subscribe((event: any) => {
                this.onTranslationChange();
            });

      }

    /**
     * @param langKey - en, es, fr
     */
    private initData(langKey: string): Promise<any> {
        this._sysPulling = true;
        const uri: string = environment.uriEndpoint + environment.apiVersion + 'goesapp/reference/data/systemMessages?lang=' + langKey;

        const promise = new Promise<void>((resolve, reject) => {
            this.http.get(uri).pipe(
            ).toPromise()
            .catch( reject )
            .then(
                res => {
                    this.sysMessages[langKey] = res;
                    this._sysPulling = false;
                    this.sysMessagesUpdatedSubj.next(true);

                    resolve();
                }
            );
        });

        return promise;

    }

    public getSysMessages(): any {
        return of(this.sysMessages);
    }

    /*
     * Reinitialize the reference data when language changes AND
     * reference data for that language is not available
     */
    private onTranslationChange(): void {
        if ((!this.sysMessages[this.translateService.currentLang] ||
            this.sysMessages[this.translateService.currentLang].length) === 0 &&
            !this._sysPulling) {
            this.initData(this.translateService.currentLang);
        } else {
            if (!this._sysPulling) { this.sysMessagesUpdatedSubj.next(true); }
        }
    }
}
