import { EnhancedModel } from './enhanced.model';

export class Country extends EnhancedModel {

    isoCountryCode : string = '';
    officialName : string = '';
    isForCitizenship : boolean = false;
    isForBirth : boolean = false;
    isForCurrentAddress : boolean = false;
    isPrimary : boolean = false;
    geEligibility : string = null;
    abtcEligibility : string = null;
    nexusEligibility : string = null;

    constructor(obj: any = null) {
        super();
        if (obj !== null) {
            if (obj instanceof Country) {
                this.mapClone(obj);
            } else {
                this.mapJson(obj);
            }
        }
    }

    public clone():Country {
        let cloned = new Country(this);
        return cloned;
    }

    protected mapClone(obj:Country):void {
        this.isoCountryCode = obj.isoCountryCode;
        this.officialName = obj.officialName;
        this.isForCitizenship = obj.isForCitizenship;
        this.isForBirth = obj.isForBirth;
        this.isForCurrentAddress = obj.isForCurrentAddress;
        this.isPrimary = obj.isPrimary;
        this.geEligibility = obj.geEligibility;
        this.nexusEligibility = obj.nexusEligibility;
        this.abtcEligibility = obj.abtcEligibility;
    }

    protected mapJson(obj:any):void {
        // it is a json object lets deserialize it
        if (obj.hasOwnProperty('isoCountryCode')) {
            this.isoCountryCode = obj.isoCountryCode;
        }
        if (obj.hasOwnProperty('officialName')) {
            this.officialName = obj.officialName;
        }
        if (obj.hasOwnProperty('isForCitizenship')) {
            if (typeof obj.isForCitizenship === 'string') {
                this.isForCitizenship = obj.isForCitizenship === 'true' ? true : false;
            } else if (typeof obj.isForCitizenship === 'boolean') {
                this.isForCitizenship = obj.isForCitizenship;
            }
        }
        if (obj.hasOwnProperty('isForBirth')) {
            if (typeof obj.isForBirth === 'string') {
                this.isForBirth = obj.isForBirth === 'true' ? true : false;
            } else if (typeof obj.isForBirth === 'boolean') {
                this.isForBirth = obj.isForBirth;
            }
        }
        if (obj.hasOwnProperty('isForCurrentAddress')) {
            if (typeof obj.isForCurrentAddress === 'string') {
                this.isForCurrentAddress = obj.isForCurrentAddress === 'true' ? true : false;
            } else if (typeof obj.isForCurrentAddress === 'boolean') {
                this.isForCurrentAddress = obj.isForCurrentAddress;
            }
        }
        if (obj.hasOwnProperty('geEligibility')) {
            this.geEligibility = obj.geEligibility;
        }
        if (obj.hasOwnProperty('nexusEligibility')) {
            this.nexusEligibility = obj.nexusEligibility;
        }
        if (obj.hasOwnProperty('abtcEligibility')) {
            this.abtcEligibility = obj.abtcEligibility;
        }

    }

}
